import React,{useEffect} from "react";
import DatePicker from "react-datepicker";
import Switch from '@mui/material/Switch';
import { useDispatch } from "react-redux";
import { setBottomPage } from "../../../App/activeBottomPage";
import TripOriginOutlinedIcon from '@mui/icons-material/TripOriginOutlined';
import Select from "react-select"; // 引入 react-select

import "react-datepicker/dist/react-datepicker.css";


import "./AddLifeEvent.css"
import "../BottomPage.css"
import apiTools from "../../../apiTools";

function AddLifeEventPage(props){
    const dispatch = useDispatch();
    const [decodeJWT, setDecodeJWT] = React.useState({});
    const [startDate, setStartDate] = React.useState();
    const [endDate, setEndDate] = React.useState();
    const [type, setType] = React.useState();
    const [isShare, setIsShare] = React.useState(true);
    const [studentList, setStudentList] = React.useState([]);
    const [selectStudent, setSelectStudent] = React.useState();

    const name = React.useRef(null);
    const content = React.useRef(null);

    const lifeEventName = [
        {"name":"考試","color":"#FCE3CB"},
        {"name":"非規律進食","color":"FFEFBF"},
        {"name":"非規律睡眠","color":"#C4DC9C"},
        {"name":"生理不適","color":"#ADCDA5"},
        {"name":"心理壓力","color":"#A9E4EF"},
        {"name":"天氣","color":"#B9D2EE"},
        {"name":"生理期","color":"#C8BFED"},
        {"name":"非規律練習","color":"#BFB6AC"},
        {"name":"其他","color":"#979797"}
    ];

    const switchHandleChange = (event) => {
        setIsShare(event.target.checked);
    };

    const setInit = () =>{
        dispatch(setBottomPage(""));
    }

    async function lifeHandleSubmit(){
        try{
            if (startDate === null || endDate === null || name.current.value === "" ){
                alert("請確定格式");
                return false;
            }
            const moment = require('moment');
            var dic = {
                "user_id":decodeJWT['identity'] === 'coach' ? selectStudent :decodeJWT["id"],
                "start_date":moment(startDate).format("YYYY-MM-DD"),
                "end_date":moment(endDate).format("YYYY-MM-DD"),
                "type": type,
                "name": name.current.value,
                "content":content.current.value,
                "isShare": isShare
            };
            var response = await apiTools.postData("/event/addLiveEvent",dic);
            if (! response){
                alert("請確定格式");
            }
            props.setShouldRender(false);
            setInit();
        } catch(e){
            alert("請確定格式");
            console.log(e);
        }
       
    };

    function handleSelectStudent(event){
        const studentID = event.value;
        setSelectStudent(studentID);
    }

    const handleChange = (event) => {
        setType(lifeEventName[event.value]["name"]);
    };
    
    useEffect(()=>{
        const getData = async () =>{
            var dic = {"id": decodeJWT["id"]}
            const response = await (await apiTools.getResponseByGet("/user/getFriendById",dic)).json();
            setStudentList(response.map((item)=>{
                return({
                    value: item.student_id,
                    label:(
                        <div>{item.name}</div>
                    )
                });
            }));
        }
        getData();    
    },[decodeJWT]);

    useEffect(() =>{
        apiTools.decodeJWT().then((value)=>{
            setDecodeJWT(value);
        });
    },[]);

    return (
        <>
            <div className="bottomSheetTamplate">
                <h1 style={{textAlign:"center"}}>新增生活事件</h1>
                
                <div className="date ContentRow">
                    <div className="Left">
                        <div className="LeftRight Row">日期<div className="red">*</div></div>
                    </div>
                    <div className="Right datePicker">
                        <DatePicker  selected={startDate} onChange={(date) => setStartDate(date)} dateFormat={"yyyy-MM-dd"} />
                        <div style={{width : "50px",textAlign:"center"}}> - </div>
                        <DatePicker  selected={endDate} onChange={(date) => setEndDate(date)} dateFormat={"yyyy-MM-dd"}/>
                    </div>
                </div>

                {decodeJWT['identity'] === 'coach'  && 
                    <div className="ContentRow">
                        <div className="Left Row">成員<div className="red">*</div></div>
                        <div className="Right">
                            <Select 
                                className="RightSelect"
                                options={studentList}
                                onChange={(e) => {handleSelectStudent(e)}}
                            />
                    </div>
                </div>
                } 
                
                <div className="classification ContentRow">
                    <div className="Left">
                        <div className="LeftRight Row">分類 <div className="red">*</div></div>
                    </div>
                    <div className="Right">
                        <Select 
                            onChange={handleChange}
                            className="RightSelect"
                            options={lifeEventName.map((event, index) => ({
                                value: index,
                                label: (
                                    <div className="classificationSelect"> 
                                        <TripOriginOutlinedIcon sx={{color:event.color}} /> {event.name}
                                    </div>
                                ),
                            }))}
                        />
                    </div>
                </div>
                
                <div className="LifeEventName ContentRow">
                    <div className="Left">
                        <div className="LeftRight Row" id="Name">名稱<div className="red">*</div></div>
                    </div>
                    <div className="Right"><input className="BottomInput" ref={name}  ></input></div>
                </div>
                <div className="isPrivate ContentRow">
                    <div className="Left">
                        <div className="LeftRight">隱私</div>
                    </div>
                    <Switch onChange={switchHandleChange} size="large" sx={{color:"#002DE3"}}  checked={isShare} />
                </div>
                <div className="LifeEventContent ContentRow">
                    <div className="Left">
                        <div className="LeftRight" >內容</div>
                    </div>
                </div>
                <div className="LifeEventSubmit" style={{padding: '10px 7% 15px 14%',textAlign:"center"}}>
                    <div>
                        <textarea ref={content} className="bottomTextArea"name="memo" rows="15" />
                    </div>
                </div >
                <div className="buttonSubmitArea ContentRow"><button className="buttonSubmit" type="submit" onClick={lifeHandleSubmit} >儲存</button></div>
            </div>
        </>
    );


}

export default AddLifeEventPage