import React, { useEffect } from "react";

import Switch from '@mui/material/Switch';

import profile from '../../../../../images/user.png';

import "./SwitchGroupConnect.css";
import apiTools from "../../../../../apiTools";

function SwitchGroupConnect(props){

    const data = props.data;
    const src = data.photo === "" ? profile : data.photo;
    const [isGroup, setIsGroup] = React.useState(props.isGroup);


    async function handleChange(value){
        setIsGroup(value);
        var dic = {
            "group_id": props.groupData.id,
            "student_id": [data.student_id]
        }
        console.log(JSON.stringify(dic));
        if (value){
            var response = await apiTools.postData("/user/addStudentsToGroup",dic);
        }else{
            var response = await apiTools.getResponseByDelete("/user/removeStudentFromGroup",dic);
        }
        props.handleReload();
        
    }

    return(
        <>
            <div className="StudentRow ConnectCard">
                <div>
                    <img alt={"大頭照"} style={{borderRadius:"50%",width:"80px", height : "80px"}} src = {src}/>
                </div>
                <div>
                    <div>{data.name}</div>
                    <div>{data.account}</div>
                    <div>代表單位 XXXX</div>
                </div>
                <Switch onChange={(e) => handleChange(e.target.checked)} checked={isGroup}/>
            </div>
        </>
    );
}

export default SwitchGroupConnect;