// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ConnectCardLeft{
    padding-left: 10px;
    width: 60%;
}

.ConnectCardRight{
    width: 30%;
}`, "",{"version":3,"sources":["webpack://./src/MainPage/components/ConnectGroupCard/ConnectGroupCard.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,UAAU;AACd;;AAEA;IACI,UAAU;AACd","sourcesContent":[".ConnectCardLeft{\r\n    padding-left: 10px;\r\n    width: 60%;\r\n}\r\n\r\n.ConnectCardRight{\r\n    width: 30%;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
