import React, { useEffect } from "react";

import DetailTrainBlockScore from "./DetailTrainBlockSocre";

function DetailTrainBlock(props){
    const data = props.data;

    function BlockhandleChange(value){
        const updata = {}
        updata[data.id] = value;
        props.handleChange(data.id, updata );
    }

    return(
        <>
            {data.training_block.map((item, index) => (<DetailTrainBlockScore data={item} handleChange={BlockhandleChange}/>))}
        </>
    );
}

export default DetailTrainBlock;
