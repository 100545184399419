// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.setting{
   
}

.settingNav{
    display: flex;
    flex-direction: column;
}

.settingNav>button{
    background-color: transparent;
    border: none;
    margin-top: 7px;
}

.settingNavContent{
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 25px;
    
}

.settingNavContentIcon{
    margin-right: 13px;
    display: flex;
    flex-direction: row;
    align-items: center;
}


.settingProfile{
    font-family: "Open Sans" ;
}`, "",{"version":3,"sources":["webpack://./src/MainPage/SubPage/SettingPage/SettingPage.css"],"names":[],"mappings":"AAAA;;AAEA;;AAEA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,6BAA6B;IAC7B,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,eAAe;;AAEnB;;AAEA;IACI,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,mBAAmB;AACvB;;;AAGA;IACI,yBAAyB;AAC7B","sourcesContent":[".setting{\r\n   \r\n}\r\n\r\n.settingNav{\r\n    display: flex;\r\n    flex-direction: column;\r\n}\r\n\r\n.settingNav>button{\r\n    background-color: transparent;\r\n    border: none;\r\n    margin-top: 7px;\r\n}\r\n\r\n.settingNavContent{\r\n    display: flex;\r\n    flex-direction: row;\r\n    align-items: center;\r\n    font-size: 25px;\r\n    \r\n}\r\n\r\n.settingNavContentIcon{\r\n    margin-right: 13px;\r\n    display: flex;\r\n    flex-direction: row;\r\n    align-items: center;\r\n}\r\n\r\n\r\n.settingProfile{\r\n    font-family: \"Open Sans\" ;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
