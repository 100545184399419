import React from "react";
import { useDispatch } from "react-redux";
import { setSubPage } from "../../../App/activeSubPage";
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import { LineChart } from '@mui/x-charts/LineChart';

import profile from '../../../images/user.png';

import "./DetailContestPage.css"

function DetailContestPage(props){
    const dispatch = useDispatch();

    function handleReturnBotton(){
        dispatch(setSubPage("contest"));
    }

    return(
        <>
            <header className="Row">
                <div className="TrainingDetailReturnButton" onClick={handleReturnBotton}><ChevronLeftRoundedIcon/></div>
                <div>我的成績</div>
            </header>
            <div className="DetailContestContent">
                <div className="Content">
                    <div className="DetailContestCard">
                        <div className="DetailContestProfileImg">
                            <img src={profile} alt="" srcset="" />
                        </div>
                        <div>
                            <div>選手A</div>
                            <div>專長/蝶式、自由式</div>
                            <div>現代表單位/ Cali Condors</div>
                        </div>
                    </div>
                </div>
                <div className="Content BestScore">
                    <div className="DetailContestCard  CardShadow SpaceRound" >
                        <div>最佳成績</div>
                        <div>XXXXXXXX</div>
                    </div>
                </div>

                
                <div >成績成長曲線</div>
                <div style={{width:"300px"}}>
                    <LineChart
                        xAxis={[{ data: [1, 2, 3, 5, 8, 10] }]}
                        series={[
                            {
                            data: [2, 5.5, 2, 8.5, 1.5, 5],
                            },
                        ]}
                        width={200}
                        height={300}
                    />
                </div>
                <div>

                </div>
                <div>

                </div>
            </div>
            <footer>
                <div className="ContestButton">
                    <button onClick={()=>{props.BottomPageSwitch("addRaceEvent");}} className="buttonSubmit">登記新的成績</button>
                </div>
            </footer>
        </>
    );
}

export default DetailContestPage;