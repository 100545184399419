// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bottomSheetTamplate .react-datepicker-wrapper{
    width: 100%;
}

.LeftRight{
    text-align: left;
    position:relative;
}

.react-datepicker__input-container input{
    padding-left: 5px;
}    

.css-cveggr-MuiListItemIcon-root{
    min-width: 30px !important;
}

.TrainingButton{
    width: 100% !important;
}
.TrainingListTitle{
    display: flex;
    flex-direction:row;
    justify-content: space-between;
    align-items: center !important;
    width: 100%;
    font-size: 20px;
    min-height: 30px;
}

.TrainingLeft{
    display: flex;
    flex-direction: row;
    justify-content: left;
}`, "",{"version":3,"sources":["webpack://./src/MainPage/SubPage/AddTimeTablePage/AddTimeTable.css"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf;;AAEA;IACI,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,0BAA0B;AAC9B;;AAEA;IACI,sBAAsB;AAC1B;AACA;IACI,aAAa;IACb,kBAAkB;IAClB,8BAA8B;IAC9B,8BAA8B;IAC9B,WAAW;IACX,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,qBAAqB;AACzB","sourcesContent":[".bottomSheetTamplate .react-datepicker-wrapper{\r\n    width: 100%;\r\n}\r\n\r\n.LeftRight{\r\n    text-align: left;\r\n    position:relative;\r\n}\r\n\r\n.react-datepicker__input-container input{\r\n    padding-left: 5px;\r\n}    \r\n\r\n.css-cveggr-MuiListItemIcon-root{\r\n    min-width: 30px !important;\r\n}\r\n\r\n.TrainingButton{\r\n    width: 100% !important;\r\n}\r\n.TrainingListTitle{\r\n    display: flex;\r\n    flex-direction:row;\r\n    justify-content: space-between;\r\n    align-items: center !important;\r\n    width: 100%;\r\n    font-size: 20px;\r\n    min-height: 30px;\r\n}\r\n\r\n.TrainingLeft{\r\n    display: flex;\r\n    flex-direction: row;\r\n    justify-content: left;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
