
import { StyleSheet, Font } from '@react-pdf/renderer';
// Register the font
Font.register({
    family: 'kaiu',
    src: `${process.env.PUBLIC_URL}/fonts/kaiu.ttf`, // 確保字體路徑正確
  });
// 設置樣式
const styles = StyleSheet.create({
    page: {
      fontFamily: 'kaiu',
      padding: 20,
      flexWrap: 'wrap'
    },
    section: {
      margin: 10,
      padding: 5,
      border: '1px solid black',
    },
    header: {
      fontSize: 12,
      marginBottom: 10,
    },
    right:{
        textAlign:'right',
    },
    left:{
        textAlign: 'left',
    },
    bold:{
        fontWeight: 600,
    },
    table: {
      display: 'table',
      width: 'auto',
      margin: 10,
    },
    tableRow: {
      flexDirection: 'row',
    },
    tableCol: {
      width: '25%',
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: 'black',
    },
    tableCell: {
      margin: 0,
      fontSize: 10,
    },
    title: {
      fontSize: 14,
      marginBottom: 10,
      textAlign: 'center',
    },
    subtitle: {
      fontSize: 12,
      margin: 5,
    },
    text: {
      fontSize: 10,
      margin: 5,
    },
    Row:{
        flexDirection: 'row',
    },  
    footer: {
        position: 'absolute',
        bottom: 30,
        left: 20,
        right: 20,
        textAlign: 'left',
        fontSize: 11,
        color: 'black',
      },
    footerText: {
      marginBottom: 4,
    },
    column:{
      flexDirection: 'column',
    },
    small:{
      fontSize:12,
    },
    underline:{
      textDecoration: 'underline',
    },
    wrap: {
      flexWrap: 'wrap',
      width: '100%',
    },
    svg: {
      width: '100%',
      height: 300,
    },
    axis: {
      stroke: '#000',
      strokeWidth: 1,
    },
    bar: {
      fill: '#4682b4',
    },
    chartContainer: {
      marginBottom: 20,
    },
    chartImage: {
      width: '100%',
      height: 'auto',
    },
  });

export default styles;