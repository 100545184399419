import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setBottomPage} from "../../../App/activeBottomPage";
import "./Content.css";
import apiTools from "../../../apiTools";
import ClearIcon from '@mui/icons-material/Clear';
import { urPK } from "@mui/x-data-grid-pro";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

const RemoveArea = ({ setShouldRender, id, type, given_id, decodeJWT }) => {
    const [open, setOpen] = React.useState(false);

    const handleOpen = (e) => {
        e.stopPropagation();
        setOpen(true);
    };

    const handleClose = (e) => {
        e.stopPropagation();
        setOpen(false);
    };

    const removeDaily = async (e, id, type) => {
        e.stopPropagation();
        let url = "";
        switch (type) {
            case "訓練":
                url = "/event/deleteTrainingScheduleById";
                break;
            case "比賽":
                url = "/event/deleteRaceEventById";
                break;
            default:
                url = "/event/deleteLiveEventById";
                break;
        }
        const dic = { "id": id };
        const response = await apiTools.getResponseByDelete(url, dic);
        setShouldRender(false);
        setOpen(false);
    };

    const isCanRemove = () => {
        if (type === "訓練") {
            if (given_id !== decodeJWT["id"]) {
                return false;
            }
        }
        return true;
    };

    return (
        <>
            {isCanRemove() && (
                <>
                    <div className="EventClear" onClick={(e)=>handleOpen(e)}>
                        <ClearIcon sx={{ fontSize: "30px" }} />
                    </div>
                    <Dialog open={open} onClose={(e) => handleClose(e)}>
                        <DialogTitle>確認</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                確定要刪除嗎？
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={(e) => removeDaily(e, id, type)}>刪除</Button>
                            <Button onClick={(e) => handleClose(e)}>取消</Button>
                        </DialogActions>
                    </Dialog>
                </>
            )}
        </>
    );
};

function Content(props){
    const dispatch = useDispatch();
    const setDailyID = (ID) => props.setDailyID(ID);
    const moment = require('moment');
    const [responseData, setResponseData] = useState(null);
    const [decodeJWT, setDecodeJWT] = React.useState({});

    useEffect(() =>{
        apiTools.decodeJWT().then((value)=>{
            setDecodeJWT(value);
        });
    },[]);

    
    const isCoach = decodeJWT['identity'] === 'coach';

    const colors ={
        "訓練":"#D9D9D9",
        "比賽":"#FBDDD1",
        "考試":"#FCE3CB",
        "非規律進食":"#FFEFBF",
        "非規律睡眠":"#C4DC9C",
        "生理不適":"#89C879",
        "心理壓力":"#A9E4EF",
        "天氣":"#B9D2EE",
        "生理期":"#C8BFED",
        "非規律練習":"#ACA399"}

    

    function templateContent (id,type,start,end,name, given_id="") {
         start = moment(start).format("YYYY/MM/DD");
         end = moment(end).format("YYYY/MM/DD");

         var bottom = 'DetailLivePage';
         if (type === "訓練"){
            bottom = 'DetailTrainPage';
         }else if (type === "比賽"){
            bottom = 'DetailRacePage';
         }


        return(
            <div key={id} className="calendarContent" onClick={() => { dispatch(setBottomPage(bottom)); setDailyID(id); }}>
                <div className="leftEventColor" style={{ background: colors[type] }} />
                <div className="rightEvent">
                    <div className="EventContent">
                        {type === "訓練" && <div className="EventTime">{`${start}`}</div>}
                        {type !== "訓練" && <div className="EventTime">{`${start}-${end}`}</div>}
                        <div className="EventName">{name}</div>
                    </div>
                    <RemoveArea setShouldRender={props.setShouldRender} id={id} type={type} given_id={given_id} decodeJWT={decodeJWT} />
                </div>
            </div>
        );        
    }

    useEffect(()=>{
        const fetchData = async() =>{
            try{
                var date = moment(props.selectedDate).format("YYYY-MM-DD");
                var dic = {
                    "date":date,
                    "student_id":isCoach ? props.selecetedStudent : decodeJWT["id"]
                };
                const response = await (await apiTools.getResponseByGet("/event/getDateEvent",dic)).json();

                const trainEventContent = response.train_list.map(item =>
                    templateContent(item.id,"訓練",item.date,item.date,item.schedule_name, item.given_id)
                );
                const raceEventContent = response.race_event.map(item =>
                    templateContent(item.id,"比賽", item.start_date, item.end_date, item.name)
                );
                const liveEventContent = response.live_event.map(item =>
                    templateContent(item.id,item.type, item.start_date, item.end_date, item.name)
                );
                setResponseData( [...trainEventContent,...raceEventContent,...liveEventContent]);
            }catch (error) {
                console.error('Error fetching data:', error);
                // 处理错误，可以更新组件状态等操作
            }
        };
        if (props.selectedDate !== null){fetchData()};
    }, [props.selectedDate, props.selecetedStudent,decodeJWT]);


    return(
        <>
            <div className="calendarContentList">
                {responseData}
            </div>
        </>
    );
}

export default Content