import React, { useEffect } from "react";
import ClearIcon from '@mui/icons-material/Clear';

import "./CourseBlock.css"
function CourseBlock(props) {
    const [type, setType] = React.useState(props.data.train_type);
    const [group, setGroup] = React.useState(props.data.times);
    const [subBlock, setSubBlock] = React.useState(props.data.subBlock);
    const [heartRate, setHeartRate] = React.useState(props.data.heart_rate);
    const template = {
                    "swimming_style":"FR",
                    "action":"",
                    "iteration":"",
                    "distance":"",
                    "time_minute":"0",
                    "time_second":"0",
                    "goal_minute":"0",
                    "goal_second":"0",
                    "equipment":[],
                    "comment":""};

    const optionByRange = () => {
        var result = []
        for(let i = 0; i<60; i++){
            const option = () =>{
                return(
                    <>
                        <option value={i}>{i}</option>
                    </>
                )
            }
            result = [...result, option()];
        }
        return result;
    }        
        
    // 使用数组的 map 方法进行循环渲染
    const courseBlocks = Array.from(subBlock, (_, index) => (CourseBlock(index)));


    const handleInputChange = (e, index) => {
        const { id, value } = e.target;
        setSubBlock((prevSubBlock) => {
          // 使用 spread 運算符複製現有的 subBlock[index]
          const updatedSubBlock = { ...prevSubBlock[index], [id]: value };
          // 使用 slice 創建一個新的 subBlock，替換更新後的 subBlock[index]
          const newSubBlock = [...prevSubBlock.slice(0, index), updatedSubBlock, ...prevSubBlock.slice(index + 1)];
          return newSubBlock;
        });
      };


    const handleEquipmentChange = (e, index, itemIndex) => {
        const { value } = e.target;
        setSubBlock((prevSubBlock) => {
          // 使用 spread 運算符複製現有的 subBlock[index]

          var updatedSubBlock = { ...prevSubBlock[index]};
          var updatedEquipment = [...updatedSubBlock.equipment];
          updatedEquipment[itemIndex] = value;
          updatedSubBlock.equipment = updatedEquipment;
          // 使用 slice 創建一個新的 subBlock，替換更新後的 subBlock[index]
          const newSubBlock = [...prevSubBlock.slice(0, index), updatedSubBlock, ...prevSubBlock.slice(index + 1)];
          return newSubBlock;
        });
    };

    useEffect(()=>{
        props.data.subBlock = subBlock;
        const createTitle = () => {
            const title = props.data.subBlock.map((item) => {
                if (item.iteration !== "" && item.distance !== "" && item.swimming_style !== "" && item.time_minute !== "" && item.time_second !== "" && item.equipment !== ""){
                    return `${item.iteration}*${item.distance} m ${item.swimming_style} ${item.action} ${item.time_minute}'${item.time_second} ${item.equipment.map((item) => {return `${item}`})}`.replace("null","");
                }
                return "";
            });
            var allTitle = title.join(" + ");
            if (props.data.times === "" ){
                return "";
            }
            if( props.data.times !== "1"){
               allTitle = `${props.data.times}*(${allTitle})`;
            }

            return allTitle;
        };
        props.data.times = group;
        props.data.train_type = type;
        props.data.heart_rate = heartRate;
        const title = createTitle();
        props.data.title = title === ""? " " : title;
        props.setTrainingColor(props.trainingID, type, heartRate);
        props.setData(props.trainingID,props.data)
    },[group,type,subBlock, heartRate]);

    function addCourseBlock() {
        setSubBlock((prev) => [...prev,template]);
    }

    function addEquipment(index) {
        setSubBlock((prev) => {
            var updatedSubBlock = { ...prev[index]};
            var updatedEquipment = [...updatedSubBlock.equipment];
            updatedEquipment.push("呼吸管");
            updatedSubBlock.equipment = updatedEquipment;
            const newSubBlock = [...prev.slice(0, index), updatedSubBlock ,...prev.slice(index + 1)];
            return newSubBlock;
        });
    }

    function removeBlockID(index) {
        setSubBlock((prev) => {
            const newDate = prev.filter((_,i) => {
                return i !== index;
            });
            return newDate;
        });
    }

    function removeEquipment(index, equipmentIndex) {
        setSubBlock((prev) => {
            const updatedSubBlock = { ...prev[index] };
            // 使用 splice 直接从 equipment 数组中删除元素
            updatedSubBlock.equipment.splice(equipmentIndex, 1);
            // 使用 slice 创建一个新的 subBlock，替换更新后的 subBlock[index]
            const newSubBlock = [...prev.slice(0, index), updatedSubBlock, ...prev.slice(index + 1)];
            return newSubBlock;
        });
    }

    function CourseBlock(index) {
        return (
            <React.Fragment key={index}>
                <div className="addCourse" key={index}>
                    <div className="courseContent">
                        <div className="blockTitle">泳姿</div>
                        <div className="blockInput" >
                            <select value={subBlock[index].swimming_style} onChange={(e) => handleInputChange(e, index)} id="swimming_style">
                                <option value={"FR"}>FR</option>
                                <option value={"Fly"}>Fly</option>
                                <option value={"Br"}>Br</option>
                                <option value={"Bk"}>Bk</option>
                                <option value={"IM"}>IM</option>
                                <option value={"K"}>K</option>
                                <option value={"P"}>P</option>
                                <option value={"CH"}>CH</option>
                            </select>
                        </div>
                        <div className="ListIcon" onClick={()=>removeBlockID(index)}><ClearIcon/></div>
                    </div>
                    {type === "技術動作" &&
                        <>
                            <div className="courseContent">
                                <div className="blockTitle">動作</div>
                                <div className="blockInput" >
                                <select onChange={(e) => handleInputChange(e, index)} value={subBlock[index].action} id="action">
                                    <option value="">請選擇...</option>
                                    <option value={"3+5+7+9"}>3+5+7+9</option>
                                    <option value={"ALLOUT"}>ALLOUT</option>
                                    <option value={"B"}>B</option>
                                    <option value={"BESTAVG"}>BESTAVG</option>
                                    <option value={"BRTHL"}>BRTHL</option>
                                    <option value={"BRTHR"}>BRTHR</option>
                                    <option value={"CATCHUP"}>CATCHUP</option>
                                    <option value={"FASTTURN"}>FASTTURN</option>
                                    <option value={"FIST"}>FIST</option>
                                    <option value={"K4POSITIONS"}>K4POSITIONS</option>
                                    <option value={"PAUSE DRILL"}>PAUSE DRILL</option>
                                    <option value={"SINGLEARM"}>SINGLEARM</option>
                                    <option value={"SMOOTH"}>SMOOTH</option>
                                    <option value={"STRONG SC"}>STRONG SC</option>
                                </select>
                                    
                                </div>
                            </div>
                        </>}
                    <div className="courseContent">
                        <div className="blockTitle">趟數</div>
                        <div className="blockInput">
                            <input type="number" id="iteration" value={subBlock[index].iteration} onChange={(e) => handleInputChange(e, index)}></input>
                        </div>
                        <div className="blockTitle">*距離</div>
                        <div className="blockInput">
                            <input type="number" id="distance" value={subBlock[index].distance} onChange={(e) => handleInputChange(e, index)}></input>
                        </div>
                    </div>
                    <div className="courseContent">
                        <div className="blockTitle">時間</div>
                        <div className="blockInput">
                            <select id="time_minute" value={subBlock[index].time_minute} onChange={(e) => handleInputChange(e, index)}>
                                {optionByRange()}
                            </select>
                            {/* <input type="number" id="time_minute" value={subBlock[index].time_minute} onChange={(e) => handleInputChange(e, index)}></input> */}
                        </div>
                        <div className="blockTitle">m </div>
                        <div className="blockInput">
                            <select id="time_second" value={subBlock[index].time_second} onChange={(e) => handleInputChange(e, index)}>
                                {optionByRange()}
                            </select>
                            {/* <input type="number" id="time_second" value={subBlock[index].time_second} onChange={(e) => handleInputChange(e, index)}></input> */}
                        </div>
                        <div className="blockTitle">s</div>
                    </div>
                    <div className="courseContent">
                    <div className="blockTitle">目標時間</div>
                        <div className="blockInput">
                            <select id="goal_minute" value={subBlock[index].goal_minute} onChange={(e) =>handleInputChange(e, index)}>
                                {optionByRange()}
                            </select>
                        </div>
                        <div className="blockTitle">m </div>
                        <div className="blockInput">
                            <select id="goal_second" value={subBlock[index].goal_second} onChange={(e) => handleInputChange(e, index)}>
                                {optionByRange()}
                            </select>
                        </div>
                        <div className="blockTitle">s </div>
                </div>
                    {
                        subBlock[index].equipment.map((item, itemIndex) => {
                            return(
                                <>
                                    <div className="courseContent">
                                        <div className="blockTitle">器材{itemIndex+1}</div>
                                        <div className="blockInput">
                                            <select onChange={(e) => handleEquipmentChange(e, index, itemIndex)} id="equipment" value={subBlock[index].equipment[itemIndex]}>
                                                <option value={"呼吸管"}>呼吸管</option>
                                                <option value={"划手板"}>划手板</option>
                                                <option value={"蛙鞋"}>蛙鞋</option>
                                                <option value={"腳圈"}>腳圈</option>
                                                <option value={"浮球"}>浮球</option>
                                                <option value={"浮板"}>浮板</option>
                                            </select>
                                        </div>
                                        <div className="ListIcon"  onClick={()=>removeEquipment(index,itemIndex)}><ClearIcon/></div>
                                    </div>
                                </>
                            );

                        })
                    }
                    <button onClick={() => {addEquipment(index)}} className="addBlock">新增器材</button>

                    <div className="courseContent">
                        <div className="blockTitle">備註</div>
                        <div className="blockInput">
                            <input type="text" id="comment" value={subBlock[index].comment} onChange={(e) => handleInputChange(e, index)}></input>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    return (
        <>
            <div className="courseBlock" id={props.trainingID}>
                <div className="courseContent">
                    <div className="blockTitle">運動強度</div>
                    <div className="blockInput">
                        <select onChange={(e)=>setHeartRate(e.target.value)} id="type" value={heartRate}>
                            <option value={"最高強度"}>最高強度</option>
                            <option value={"高強度"}>高強度</option>
                            <option value={"中等強度"}>中等強度</option>
                            <option value={"低等強度"}>低等強度</option>
                            <option value={"最低強度"}>最低強度</option>
                        </select>
                    </div>
                </div>
                <div className="courseContent">
                    <div className="blockTitle">組數</div>
                    <div className="blockInput">
                        <input type="number" id="group" value={group} onChange={(e) => setGroup(e.target.value)}/>
                    </div>
                </div>
                <div className="courseContent">
                    <div className="blockTitle">類型</div>
                    <div className="blockInput">
                        <select onChange={(e)=>setType(e.target.value)} id="type" value={type}>
                            <option value={"主課程"}>主課程</option>
                            <option value={"熱身"}>熱身</option>
                            <option value={"緩和"}>緩和</option>
                            <option value={"技術動作"}>技術動作</option>
                            <option value={"測驗"}>測驗</option>
                        </select>
                    </div>
                </div>
                {courseBlocks}
            </div>

            <div style={{ width: "100%", marginTop: "10px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <button onClick={addCourseBlock} className="addBlock">新增分段</button>
            </div>
        </>
    );
}

export default CourseBlock