// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ConnectButton{
    display: flex;
    justify-self: center;
    align-items: center;
    margin-left: 10px;
}

.ConnectButton>button{
    background-color: white;
    border: solid #002DE3 1px;
    border-radius: 20px;
    font-size: 20px;
    width: -moz-fit-content;
    width: fit-content;
    padding: 7px  12px;
}

.ConnectCard{
    margin-top: 15px;
    display: flex;
    justify-content: space-around;
    background-color: #ECECEC ;
    padding-top: 7px;
    padding-bottom: 7px;
    border-radius: 20px;
}`, "",{"version":3,"sources":["webpack://./src/MainPage/components/ConnectCard/ConnectCard.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,oBAAoB;IACpB,mBAAmB;IACnB,iBAAiB;AACrB;;AAEA;IACI,uBAAuB;IACvB,yBAAyB;IACzB,mBAAmB;IACnB,eAAe;IACf,uBAAkB;IAAlB,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;IAChB,aAAa;IACb,6BAA6B;IAC7B,0BAA0B;IAC1B,gBAAgB;IAChB,mBAAmB;IACnB,mBAAmB;AACvB","sourcesContent":[".ConnectButton{\r\n    display: flex;\r\n    justify-self: center;\r\n    align-items: center;\r\n    margin-left: 10px;\r\n}\r\n\r\n.ConnectButton>button{\r\n    background-color: white;\r\n    border: solid #002DE3 1px;\r\n    border-radius: 20px;\r\n    font-size: 20px;\r\n    width: fit-content;\r\n    padding: 7px  12px;\r\n}\r\n\r\n.ConnectCard{\r\n    margin-top: 15px;\r\n    display: flex;\r\n    justify-content: space-around;\r\n    background-color: #ECECEC ;\r\n    padding-top: 7px;\r\n    padding-bottom: 7px;\r\n    border-radius: 20px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
