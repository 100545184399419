import React from "react";
import { useNavigate } from 'react-router-dom';
import LoginImage from '../images/LoginImage.png';
import apiTools from "../apiTools";

import './LoginPage.css';

function LoginAccountPage(){
    let  history = useNavigate();

    const [account,setAccounte] = React.useState("");
    const [password,setPassword] = React.useState("");

    async function login(){
        var json = {
        "account": account,
        "password": password,
        };
        const response = await apiTools.login(JSON.stringify(json));
        if (response === 200){
            history('/main');
        }else{
            alert("帳號不存在或是密碼錯誤");
        }
    }
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
          e.preventDefault();
          login();
        }
      };
    return(
        <>
            <div className="Login">
                <div className="LoginContent">
                    <div className="LoginContent LoginImage"><img src={LoginImage} alt="只是圖片"/></div>
                    <div className="LoginContent text">紀錄你的游泳紀錄</div>
                </div>
                <div className="LoginConetent LoginButton">
                    <div className= "SignUpInput"><input placeholder="帳號Account" onChange={(event)=>{setAccounte(event.target.value)}}></input></div>
                    <div className= "SignUpInput"><input type="password" placeholder="密碼Password" onChange={(event) =>{setPassword(event.target.value)}} onKeyDown={handleKeyDown}></input></div>
                    <div className="LoginContent signIn"><button onClick={login}>登入</button></div>
                </div> 
            </div>
        </>
    );
}

export default LoginAccountPage;