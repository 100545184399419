import React, { useEffect, useState } from "react";

import CalendarNav from "../../components/CalendarNav/CalendarNav"; 
import Timeline from "../../components/TimelineEvent/TimelineEvent";
import GeneratingPDF from "../../components/GeneratingPDF/GeneratingPDF";
import DatePicker from "react-datepicker";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

import { PDFDownloadLink } from '@react-pdf/renderer';

import apiTools from "../../../apiTools";

import "./SurveyPage.css";
import moment from "moment";

const template = [
    {
        "id": "",
        "title": "晨間睡眠日誌",
        "description": "追蹤每日睡眠品質",
        "score": "",
        "type": "Sleep_Log_Morning"
    },
    {
        "id": "",
        "title": "晚間睡眠日誌",
        "description": "追蹤每日睡眠品質",
        "score": "",
        "type": "Sleep_Log_Night"
    },
    {
        "id": "",
        "title": "STOP-Bang問卷",
        "description": "阻塞型睡眠呼吸中止風險評估",
        "score": "",
        "type": "STOP_Bang"
    },
    {
        "id": "",
        "title": "匹茲堡睡眠品質評量表",
        "description": "睡眠品質的評估檢測",
        "score": "",
        "type": "PSQI"
    },
    {
        "id": "",
        "title": "壓力知覺量表",
        "description": "評價個人生活情境的壓力程度",
        "score": "",
        "type": "PSS"
    }
];

function SurveyPage() {
    const [decodeJWT, setDecodeJWT] = useState({});
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [events, setEvents] = useState(template);
    const [personalFile, setPersonalFile] = useState({});
    const [startDate, setStartDate] = useState(moment(new Date()).subtract(1, 'years').toDate());
    const [endDate, setEndDate] = useState(new Date());

    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const handleReport = async ( startDate, endDate, date) => {
        const dic = {
            "user_id": decodeJWT["id"],
            "startDate": moment(startDate).format("YYYY-MM-DD"),
            "endDate": moment(endDate).format("YYYY-MM-DD"),
            "date": moment(date).format("YYYY-MM-DD"),
        }
        try {
            // 调用通用的 GET 请求函数
            const response = await apiTools.getResponseByGet("survey/getReport", dic);
        
            if (response.ok) {
              const blob = await response.blob();
              const downloadUrl = window.URL.createObjectURL(blob);
              const a = document.createElement('a');
              a.href = downloadUrl;
              a.download = `sleppAnaylza_${moment(date).format("YYYYMMDD")}.pdf`; // 设置下载的文件名
              document.body.appendChild(a);
              a.click();
              a.remove(); // 移除链接元素
              window.URL.revokeObjectURL(downloadUrl); // 释放 URL 对象
            } else {
              throw new Error('Failed to fetch the report');
            }
          } catch (error) {
            console.error('Error downloading the report:', error);
          }
     }

    const typeDict = {
        'Sleep_Log_Morning': {
            id: "",
            title: '睡眠日誌(晨間填寫)',
            description: '追蹤昨晚睡眠狀況',
            score: "",
            Date: "",
        },
        'Sleep_Log_Night': {
            id: "",
            title: '睡眠日誌(晚間填寫)',
            description: '追蹤今日白天狀況',
            score: "",
            Date: "",
        },
        'STOP_Bang': {
            id: "",
            title: 'STOP-Bang問卷',
            description: '阻塞型睡眠呼吸中止風險評估',
            score: "",
            Date: "",
        },
        "PSQI": {
            id: "",
            title: '匹茲堡睡眠品質評量表',
            description: '過去一個月睡眠品質檢測',
            score: "",
            Date: "",
        },
        "PSS": {
            id: "",
            title: '壓力知覺量表',
            description: '過去一個月個人生活情境的壓力程度評估',
            score: "",
            Date: "",
        }
    };

    const handleDateClick = (args) => {
        setSelectedDate(args);
    };

    useEffect(() => {
        const getData = async () => {
            const dic = {
                "user_id": decodeJWT["id"],
                "date": moment(selectedDate).format("YYYY-MM-DD")
            };
            const response = await (await apiTools.getResponseByGet("/survey/getSurveyByUser", dic)).json();
            const newTypeDict = { ...typeDict };
            response.forEach(item => {
                newTypeDict[item.survey_type].score = item.result;
                newTypeDict[item.survey_type].id = item.id;
                newTypeDict[item.survey_type].Date = item.fill_date;
            });
            setEvents(() => {
                const typeArray = Object.entries(newTypeDict).map(([key, value]) => ({
                    id: value.id,
                    title: value.title,
                    description: value.description,
                    score: value.score,
                    Date: value.Date !== "" ? value.Date : moment(selectedDate).format("YYYY.MM.DD"),
                    type: key,
                }));
                return typeArray;
            });
        };
        getData();
    }, [decodeJWT, selectedDate]);

    useEffect(() => {
        const getData = async () => {
            const response = await apiTools.getResponseByGet("user/getUserProfile", { "id": decodeJWT["id"] });
            if (response.ok) {
                const data = await response.json();
                setPersonalFile(data);
            } else {
                console.error("Error fetching profile:", response.statusText);
            }
        };
        // getData();
    }, [decodeJWT]);

    useEffect(() => {
        apiTools.decodeJWT().then((value) => {
            setDecodeJWT(value);
        });
    }, []);

    return (
        <>
            <CalendarNav 
                dateClick={handleDateClick}
                select={new Date()}
            />
            <Timeline events={events} date={selectedDate} />
            <div className="Center">
                <button className="buttonSubmit" onClick={() => setOpen(true)}>產生報告</button>
            </div>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>產生報告</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <div className="dialogContent">
                            <div className="Row">
                                開始日期：
                                <DatePicker className="datepicker" selected={startDate} onChange={(date) => setStartDate(date)} dateFormat={"yyyy-MM-dd"} />
                            </div>                            
                            <div className="Row">
                                結束日期：
                                <DatePicker className="datepicker" selected={endDate} onChange={(date) => setEndDate(date)} dateFormat={"yyyy-MM-dd"} />
                            </div>
                        </div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>取消</Button>
                    <Button onClick={() => handleReport(startDate, endDate, selectedDate)}>報告匯出</Button>
                    {/* <PDFDownloadLink 
                        document={<GeneratingPDF data={events} user_id={decodeJWT["id"]} date={selectedDate} profile={personalFile} startDate={startDate} endDate={endDate}/>} 
                        fileName={`sleppAnaylza_${moment(selectedDate).format("YYYYMMDD")}.pdf`}
                        className="generateingPDF"
                    >
                        {({ blob, url, loading, error }) => {
                            if (loading) return '正在生成報告...';
                            if (error) {
                                return '報告匯出失敗';
                            }
                            return '報告匯出';
                        }}
                    </PDFDownloadLink> */}
                </DialogActions>
            </Dialog>
        </>
    );
}

export default SurveyPage;
