import { jwtDecode } from "jwt-decode";
import { Preferences } from '@capacitor/preferences';

const apiTools = {
    baseURL: "http://140.116.206.178:5000/",
    headers:{"Content-Type": "application/json",
                "Accept": "application/json",
                },

    signup: function(body){
        let request = new Request( this.baseURL + 'signup',{
            method: 'POST',
            headers: this.headers,
            body:body,
        });
        // Returning the fetch promise
        return fetch(request)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error('There was a problem with the fetch operation:', error);
                // handle the error
                throw error;
            });
    },
    
    login: async function(body){
      try{
        let request = new Request( this.baseURL + 'login',{
            method: 'POST',
          headers: this.headers,
          body:body,
        });
        // Returning the fetch promisesrc/apiTools.js
        let response = await fetch(request);
        const status = response.status;
        if (status === 200) {
          try {
            const responseData = await response.json();
            await Preferences.set({key: 'JWT', value: responseData.jwt});
            await Preferences.set({key: "photo", value: responseData.photo});
          } catch (error) {
            console.error("Failed to parse JSON:", error);
            // 在這裡處理解析 JSON 失敗的情況
          }
        }
        return status;

      }catch(errors){
        throw errors;
      }
        
    },

    getJWT: async function(){
      const {value} = await Preferences.get({key: "JWT"});
      return value;
    },

    resetJWT: async function(){
      await Preferences.set({key:"JWT",value:""});
    },

    getPhoto: async function (){
      const { value } = await Preferences.get({key: "photo"});
      return value;
    },

    decodeJWT: async function(){
      const jwt = await this.getJWT();
      const decoded = jwtDecode(jwt);
      return decoded;
    },

    checkJWT: async function(){
      const jwt = await this.getJWT();
      console.log(jwt);
      return (
        jwt !== "" && 
        jwt !== undefined && 
        (await (await apiTools.getResponseByGet("/jwt",{})).json()).jwtresults === 1 
        );
    },

    getResponseByGet: async function (url, dic) {
        try {
          const jwt = await this.getJWT();
          url = url + "?";
          for (const [key, value] of Object.entries(dic)) {
            url = url + key + "=" + value + "&";
          }
          console.log(url);
          this.headers["authorization"] = jwt;
          let request = new Request(this.baseURL + url, {
            method: 'GET',
            headers: this.headers,
          });
    
          const response = await fetch(request);
          return response;
        } catch (error) {
          console.error('There was a problem with the fetch operation:', error);
          throw error;
        }
    },

    /** 
    *  將字典資料利用PUT的方式傳送出去
    *  @param {}
    */

    getResponseByPut: async function(url, dic,id){
      try{
        const jwt = await this.getJWT();
        var json = JSON.stringify(dic);
        this.headers["authorization"] = jwt;
        console.log(this.baseURL + url + "?id="+id);
        let request = new Request(this.baseURL + url + "?id="+id,{
         method:"PUT",
         headers: this.headers, 
         body: json,
        });
        const response = await fetch(request);
        return response;
      }catch(error){
        console.error("There was a problem with the fetch operation:",error);
        throw error;
      }
    },

    postData: async function(url,dic){
      try{
        const jwt = await this.getJWT();
        var json = JSON.stringify(dic);
        this.headers["authorization"] = jwt;
        let request = new Request(this.baseURL + url, {
          method: 'POST',
          headers: this.headers,
          body:json,
        });
        console.log(url);
        const response = await fetch(request);
        return response.ok;

      }catch(error){
        console.error('There was a problem with the fetch operation:', error);
        throw error;
      }
      


    },
    
    getResponseByDelete: async function (url,dic){
      try {
        const jwt = await this.getJWT();
        url = url + "?";
        for (const [key, value] of Object.entries(dic)) {
          url = url + key + "=" + value + "&";
        }
        console.log(url);
        this.headers["authorization"] = jwt;
        let request = new Request(this.baseURL + url, {
          method: 'DELETE',
          headers: this.headers,
        });
  
        const response = await fetch(request);
        return response;
      } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
        throw error;
      }
    }
};
export default apiTools;