import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    bottomPage : '',
    open: false,
}

const activeBottomPage = createSlice({
    name: "activeBottomPage",
    initialState,
    reducers:{
        setBottomPage: (state, action) => {
            state.bottomPage = action.payload;
        },
        setBottomSheetOpen: (state, action) => {
            state.open = action.payload;
        },    
    }
});

export const { setBottomPage, setBottomSheetOpen} = activeBottomPage.actions;
export default activeBottomPage.reducer;
