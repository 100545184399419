// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.calendarContentList{
    display: flex;
    flex-direction: column;
    margin-top: 5px;
}


.calendarContent button{
    width: 100%;
    text-align: left;
    background-color: transparent;
    border: none;
}

.calendarContent{
    display: flex;
    flex-direction: row;
    margin: 10px;
}

.leftEventColor{
    padding:3px ;
    margin-right: 10px;
    background-color: #FBD1D1;
    box-Shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    border-Radius: 30px;
}

.EventContent{
     
    flex-Direction: column;
    justify-Content: flex-start;
    align-Items: flex-start;
    display: inline-flex;
}

.rightEvent{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 90%;
    box-Shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-Radius: 10px;
    padding: 16px 18px 18px 18px;
    background: #FAFAFA;
}

.EventClear{
    display: flex;
    align-items: center;
}


.EventTime{
    color:#979797;
}

.EventName{
    color: #222B45;
}`, "",{"version":3,"sources":["webpack://./src/MainPage/SubPage/CalendarPage/Content.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,eAAe;AACnB;;;AAGA;IACI,WAAW;IACX,gBAAgB;IAChB,6BAA6B;IAC7B,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,kBAAkB;IAClB,yBAAyB;IACzB,2CAA2C;IAC3C,mBAAmB;AACvB;;AAEA;;IAEI,sBAAsB;IACtB,2BAA2B;IAC3B,uBAAuB;IACvB,oBAAoB;AACxB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,UAAU;IACV,2CAA2C;IAC3C,mBAAmB;IACnB,4BAA4B;IAC5B,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;;AAGA;IACI,aAAa;AACjB;;AAEA;IACI,cAAc;AAClB","sourcesContent":[".calendarContentList{\r\n    display: flex;\r\n    flex-direction: column;\r\n    margin-top: 5px;\r\n}\r\n\r\n\r\n.calendarContent button{\r\n    width: 100%;\r\n    text-align: left;\r\n    background-color: transparent;\r\n    border: none;\r\n}\r\n\r\n.calendarContent{\r\n    display: flex;\r\n    flex-direction: row;\r\n    margin: 10px;\r\n}\r\n\r\n.leftEventColor{\r\n    padding:3px ;\r\n    margin-right: 10px;\r\n    background-color: #FBD1D1;\r\n    box-Shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);\r\n    border-Radius: 30px;\r\n}\r\n\r\n.EventContent{\r\n     \r\n    flex-Direction: column;\r\n    justify-Content: flex-start;\r\n    align-Items: flex-start;\r\n    display: inline-flex;\r\n}\r\n\r\n.rightEvent{\r\n    display: flex;\r\n    flex-direction: row;\r\n    justify-content: space-between;\r\n    width: 90%;\r\n    box-Shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);\r\n    border-Radius: 10px;\r\n    padding: 16px 18px 18px 18px;\r\n    background: #FAFAFA;\r\n}\r\n\r\n.EventClear{\r\n    display: flex;\r\n    align-items: center;\r\n}\r\n\r\n\r\n.EventTime{\r\n    color:#979797;\r\n}\r\n\r\n.EventName{\r\n    color: #222B45;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
