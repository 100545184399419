import React, {useEffect} from "react";

import SwitchGroupConnect from "./SwitchGroupConnect/SwitchGroupConnect";

import "./GroupDetail.css";

import apiTools from '../../../../apiTools';


function GroupDetail(props){

    const [decodeJWT, setDecodeJWT] = React.useState({});
    const [groupName, setGroupName] = React.useState(props.data.name);
    const [studentList, setStudentList] = React.useState();
    const data = props.data;
    const reload = props.reload;

    useEffect(()=>{
        const getData = async () =>{
            var dic = {"id":decodeJWT["id"]};
            var response = await (await apiTools.getResponseByGet("/user/getFriendById",dic)).json();

            setStudentList(response.map((item) =>{

                return(
                    <>
                        <SwitchGroupConnect data={item} groupData={data} isGroup={data.student_id.includes(item.student_id)} handleReload={props.handleReload}/>
                    </>
                );
            }));
        }
        getData();
    },[decodeJWT,reload]);

    useEffect(() =>{
        apiTools.decodeJWT().then((value)=>{
            setDecodeJWT(value);
        });
    },[]);


    return(
        <>
            <div>
                <div className="Row GroupTitleRow">
                    <div className="GroupTitle">{groupName}</div>
                    <div className="GroupButton">
                        <button>移除群組</button>
                    </div>
                </div>
                <div>
                    {studentList}
                </div>
            </div>
        </>
    );
}

export default GroupDetail;