// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ContestButton{
    display:flex;
    justify-content: center;
}

.Content{
    width: 100% ;

}

.DetailContestCard{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
}

.DetailContestProfileImg{
    margin-right: 10px;
}

.DetailContestProfileImg>img{
    height: 50px;
    width: 50px;
}

.CardShadow{
/* 無特殊性質底色淺 */
    box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 10px 20px;
}

.BestScore{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.SpaceRound{
    width: 70%;
    justify-content: space-around;
}`, "",{"version":3,"sources":["webpack://./src/MainPage/SubPage/DetailContestPage/DetailContestPage.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,uBAAuB;AAC3B;;AAEA;IACI,YAAY;;AAEhB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,WAAW;AACf;;AAEA;AACA,aAAa;IACT,2CAA2C;IAC3C,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,UAAU;IACV,6BAA6B;AACjC","sourcesContent":[".ContestButton{\r\n    display:flex;\r\n    justify-content: center;\r\n}\r\n\r\n.Content{\r\n    width: 100% ;\r\n\r\n}\r\n\r\n.DetailContestCard{\r\n    display: flex;\r\n    flex-direction: row;\r\n    justify-content: center;\r\n    align-items: center;\r\n    margin-bottom: 15px;\r\n}\r\n\r\n.DetailContestProfileImg{\r\n    margin-right: 10px;\r\n}\r\n\r\n.DetailContestProfileImg>img{\r\n    height: 50px;\r\n    width: 50px;\r\n}\r\n\r\n.CardShadow{\r\n/* 無特殊性質底色淺 */\r\n    box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);\r\n    border-radius: 10px;\r\n    padding: 10px 20px;\r\n}\r\n\r\n.BestScore{\r\n    display: flex;\r\n    flex-direction: column;\r\n    justify-content: center;\r\n    align-items: center;\r\n}\r\n\r\n.SpaceRound{\r\n    width: 70%;\r\n    justify-content: space-around;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
