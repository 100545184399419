// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BookMarkTwoToneFill{
    color: yellow;
}

.BookmarkTwoToneIcon{
    color: gainsboro;
}

.TrainingDetailTitle{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.TrainingDetailReturnButton{
    display: flex;
    justify-content: center;
    align-items: center;
}

.TrainingDetailReturnImg{
    height: 30px;
}

.TrainingDetailLeft{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.TrainingDetailRight{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.Owner{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
    color: #979797;
}

.Owner>img{
    height: 15px;
    width: 15px;
    margin-right: 5px;
}

.trainingButton{
    border-radius: 15px !important;
    margin: 5px 0px !important;
    padding: 0px 5px;
    width: -moz-fit-content;
    width: fit-content;
}`, "",{"version":3,"sources":["webpack://./src/MainPage/SubPage/TrainingDetailPage/TrainingDetail.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;AACvB;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,mBAAmB;IACnB,kBAAkB;IAClB,cAAc;AAClB;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,iBAAiB;AACrB;;AAEA;IACI,8BAA8B;IAC9B,0BAA0B;IAC1B,gBAAgB;IAChB,uBAAkB;IAAlB,kBAAkB;AACtB","sourcesContent":[".BookMarkTwoToneFill{\r\n    color: yellow;\r\n}\r\n\r\n.BookmarkTwoToneIcon{\r\n    color: gainsboro;\r\n}\r\n\r\n.TrainingDetailTitle{\r\n    display: flex;\r\n    flex-direction: row;\r\n    justify-content: space-between;\r\n    align-items: center;\r\n}\r\n\r\n.TrainingDetailReturnButton{\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n}\r\n\r\n.TrainingDetailReturnImg{\r\n    height: 30px;\r\n}\r\n\r\n.TrainingDetailLeft{\r\n    display: flex;\r\n    flex-direction: row;\r\n    justify-content: center;\r\n    align-items: center;\r\n}\r\n\r\n.TrainingDetailRight{\r\n    display: flex;\r\n    flex-direction: row;\r\n    align-items: center;\r\n}\r\n.Owner{\r\n    display: flex;\r\n    flex-direction: row;\r\n    justify-content: center;\r\n    align-items: center;\r\n    margin-right: 15px;\r\n    color: #979797;\r\n}\r\n\r\n.Owner>img{\r\n    height: 15px;\r\n    width: 15px;\r\n    margin-right: 5px;\r\n}\r\n\r\n.trainingButton{\r\n    border-radius: 15px !important;\r\n    margin: 5px 0px !important;\r\n    padding: 0px 5px;\r\n    width: fit-content;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
