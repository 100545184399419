import React from 'react'

import returnicon from '../../../images/return.svg'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import './Analytics.css'

import TwoCircularProgress from '../../components/TwoCircularProgress/TwoCircularProgress'

function AnalyticsPage(){

    return (
        <>
            <div className='AnalyticsContent'>
                <div className='AnalyticsTitleArea'>
                    <div><button className='returnButton'><img src={returnicon} alt="return" /></button></div>
                    <div><button className='returnButton'><ArrowBackIosIcon/></button></div>
                    <div>2023-08-15</div>
                    <div><button className='returnButton'><ArrowForwardIosIcon/></button></div>
                    <div><button className='returnButton'><MoreVertRoundedIcon/></button></div>
                </div>
                <div className='AnalyticsTwoCircularProgress'>
                    <TwoCircularProgress></TwoCircularProgress>
                </div>
                <div>影片</div>
                <div>
                    <video></video>
                </div>
                <div>分段</div>
                <div>
                    <img></img>
                </div>
                <div>功率圖</div>
                <div>
                    <img></img>
                </div>
                <div>心率圖</div>
                <div>
                    <img></img>
                </div>
            </div>
        </> 

    )
}

export default AnalyticsPage