import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    subPage : 'calendar',
}

const activeSubPage = createSlice({
    name: "activeSubPage",
    initialState,
    reducers:{
        setSubPage: (state, action) => {
            state.subPage = action.payload;
        }     
    }
});

export const { setSubPage } = activeSubPage.actions;
export default activeSubPage.reducer;
