// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 6001; /* Ensure it's above other content */
}

.tooltip {
    background-color: #fff;
    border: 1px solid #ddd;
    padding: 20px;
    margin: 0 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    max-width: 90%;
    text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/MainPage/SubPage/DetailTrainPage/DetailTrainPage.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,8BAA8B,EAAE,sCAAsC;IACtE,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa,EAAE,oCAAoC;AACvD;;AAEA;IACI,sBAAsB;IACtB,sBAAsB;IACtB,aAAa;IACb,cAAc;IACd,wCAAwC;IACxC,mBAAmB;IACnB,cAAc;IACd,kBAAkB;AACtB","sourcesContent":[".overlay {\r\n    position: fixed;\r\n    top: 0;\r\n    left: 0;\r\n    width: 100%;\r\n    height: 100%;\r\n    background: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    z-index: 6001; /* Ensure it's above other content */\r\n}\r\n\r\n.tooltip {\r\n    background-color: #fff;\r\n    border: 1px solid #ddd;\r\n    padding: 20px;\r\n    margin: 0 10px;\r\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\r\n    border-radius: 20px;\r\n    max-width: 90%;\r\n    text-align: center;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
