// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Login{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 100vh;
    font-family: "ABeeZee";
}

.LoginImage{
    margin-top: 10%;
}

.LoginContent{
    text-align: center;
}

.LoginButton{
    width: 100%;
}

.LoginContent>button{
    margin: 5px !important;
    background-Color:#002DE3;
    border-radius:  30px;
    color: #FFFFFF;
    width: 90%;
    font-size: 20px;
    height: 60px;
    border: none;
}

.terms>button{
    background-color: transparent !important;
    color: black;
    font-size: 15px;
}

.text{
    font-size: 20px;
    font-weight: bold;
}
`, "",{"version":3,"sources":["webpack://./src/LoginPage/LoginPage.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,6BAA6B;IAC7B,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,sBAAsB;IACtB,wBAAwB;IACxB,oBAAoB;IACpB,cAAc;IACd,UAAU;IACV,eAAe;IACf,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI,wCAAwC;IACxC,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,iBAAiB;AACrB","sourcesContent":[".Login{\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    justify-content: space-around;\r\n    height: 100vh;\r\n    font-family: \"ABeeZee\";\r\n}\r\n\r\n.LoginImage{\r\n    margin-top: 10%;\r\n}\r\n\r\n.LoginContent{\r\n    text-align: center;\r\n}\r\n\r\n.LoginButton{\r\n    width: 100%;\r\n}\r\n\r\n.LoginContent>button{\r\n    margin: 5px !important;\r\n    background-Color:#002DE3;\r\n    border-radius:  30px;\r\n    color: #FFFFFF;\r\n    width: 90%;\r\n    font-size: 20px;\r\n    height: 60px;\r\n    border: none;\r\n}\r\n\r\n.terms>button{\r\n    background-color: transparent !important;\r\n    color: black;\r\n    font-size: 15px;\r\n}\r\n\r\n.text{\r\n    font-size: 20px;\r\n    font-weight: bold;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
