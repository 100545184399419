import React,{useEffect} from "react";
import { useDispatch } from 'react-redux';
import { setBottomPage } from "../../../App/activeBottomPage";
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import DatePicker from "react-datepicker";
import ClearIcon from '@mui/icons-material/Clear';
import TripOriginOutlinedIcon from '@mui/icons-material/TripOriginOutlined';
import Select from "react-select"; // 引入 react-select
import CourseBlock from "../../components/CourseBlock/CourseBlock";
import {ReactComponent as MIcon} from "../../../images/CourseIcon/M.svg";
import {ReactComponent as WIcon} from "../../../images/CourseIcon/W.svg";
import {ReactComponent as CIcon} from "../../../images/CourseIcon/C.svg";
import {ReactComponent as DIcon} from "../../../images/CourseIcon/D.svg";
import {ReactComponent as TIcon} from "../../../images/CourseIcon/T.svg";
import ReactDOM from 'react-dom';

import "react-datepicker/dist/react-datepicker.css";

import "./AddTimeTable.css"
import "../BottomPage.css"

import apiTools from '../../../apiTools';

const template = {
    "swimming_style":"FR",
    "action":"",
    "iteration":"",
    "distance":"",
    "time_minute":"0",
    "time_second":"0",
    "goal_minute":"0",
    "goal_second":"0",
    "equipment":[],
    "comment":""
};

const templateData = {
    "id": "",
    "title":"",
    "times":"",
    "train_type":"主課程",
    "heart_rate": "最低強度",
    "subBlock":[template]
};

const backgroundColor = "#ececec";

const tableTimeRateColor = {
    "最低強度":"#909098",
    "低強度":"#00a1d7",
    "中等強度":"#01a860",
    "高強度":"#f8a018",
    "最高強度":"#ef373f",
};

const tableTimeIcon = (type, color) => {
    switch (type) {
        case "主課程":
            return <MIcon fill={color} />;
        case "熱身":
            return <WIcon fill={color} />;
        case "緩和":
            return <CIcon fill={color} />;
        case "技術動作":
            return <DIcon fill={color} />;
        case "測驗":
            return <TIcon fill={color} />;
        default:
            return null; // 或者返回一個默認的圖標組件，或者什麼也不返回
    }
};


function AddTimeTablePage(props){

    const dispatch = useDispatch();

    
    const [decodeJWT, setDecodeJWT] = React.useState({});

    const [startDate, setStartDate] = React.useState(null);
    const [name, setName] = React.useState("");

    const [selectTrainingID, setSelectTrainingID] = React.useState(0);

    const [currentListSubPage, setCurrentListSubPage] = React.useState(null);
    const [preListSubPage, setPreListSubPage] = React.useState(null);

    const [selectTraining, setSelectTraining] = React.useState([]);

    const [removeBlockID, setRemoveBlockID] = React.useState(null);

    const [updata, setUpdata] = React.useState(false);

    const [templateName, setTemplateName] = React.useState([]);

    const [studentList, setStudentList] = React.useState([]);
    const [selectStudent, setSelectStudent] = React.useState(null);

    const [isPublic, setIsPublic] = React.useState(false);

    const selectedData = React.useRef({});

    const blockRef = React.useRef({});

    const student_id = decodeJWT.id;


    const onDragEnd = (result) => {
        const { source, destination } = result;
        if (!destination) {
            return;
        }
        
        const newItems = [...selectTraining];
        const [removed] = newItems.splice(source.index, 1);
        newItems.splice(destination.index, 0, removed);
        updataItemIndex(newItems);
    };
    const setInit = () =>{
        dispatch(setBottomPage(""));
    }
    function updataItemIndex(newItems){
        const updatedItems = newItems.map((item, index) => ({
            ...item,
            props: {
                ...item.props,
                children: {
                    ...item.props.children,
                    props: {
                        ...item.props.children.props,
                        index: index
                    }
                }
            }
        }));
        setSelectTraining(updatedItems);

    };

    async function addTimeTable(){
        try{
            if (decodeJWT['identity'] === 'coach' && selectStudent === null){
                return false;
            }
            if (startDate === null || name === ""){
                return false;
            }
            var moment = require('moment');

            let training = [];
            for (var key in selectedData.current){
                const TrainingDic = {
                    "times": 1,
                    "train_type":selectedData.current[key].train_type,
                    "training_block":selectedData.current[key].subBlock,
                    "heart_rate": selectedData.current[key].heart_rate,
                }
                console.log(selectedData.current[key])
                for(var i = 0; i < selectedData.current[key].times;i++ ){
                    training = [...training,TrainingDic];
                }
            }
            let dic = {
                "date" : moment(startDate).format("YYYY-MM-DD"),
                "schedule_name": name,
                "training": training
            };
            if (decodeJWT['identity'] === 'coach' && selectStudent.group ){
                dic["group_id"] = selectStudent.value;
                var response = await apiTools.postData("/event/addGroupTrainingSchedule",dic);
                return response;

            }else{
                dic["student_id"] = decodeJWT['identity'] === 'coach' ? selectStudent.value :student_id;
                var response = await apiTools.postData("/event/addTrainingSchedule",dic);
                return response;
            }    
        } catch{
            return false;
        }
    
        
    };

    async function addTemplate(){
        if (name === ""){
            return false;
        }
        try{
            let training = [];
        
            for (var key in selectedData.current){
                const dic = {
                    "times": selectedData.current[key].times,
                    "train_type":selectedData.current[key].train_type,
                    "training_block":selectedData.current[key].subBlock,
                    "heart_rate": selectedData.current[key].heart_rate,
                }
                training = [...training,dic];
            }
    
            let dic = {
                "creater_id": student_id,
                "name": name,
                "training": training,
                "isPublic": isPublic,
            };
            console.log(dic);
            var response = await apiTools.postData("event/addTrainTemplate",dic);
            return response;
        } catch{
            return false;
        }
        

    };


    async function handleSubmit(){
        if (! await addTimeTable()){
            alert("請確定格式");
        } else {
            props.setShouldRender(false);
            setInit();
        };
    };

    async function handleSubmitAndStoreTemplate(){
        var next = await addTimeTable();
        if (! next){
            alert("請確定格式");
            return;
        };
        next = await addTemplate();
        if (! next){
            alert("請確定格式");
            return;
        };
        if (next){
            props.setShouldRender(false);
            setInit();
        }
    };

    function handleClickListButton(args){
        setCurrentListSubPage((currentListSubPage) => {
            // 在这里可以执行一些额外的处理
            setPreListSubPage(currentListSubPage);
            // 返回新的状态值
            return args === currentListSubPage ? null : args;
          });
    };

    function changeTrainingColor(ID, type, heartRatem){
        const IconElement = blockRef.current[ID].icon.current;
        ReactDOM.render(tableTimeIcon(type, tableTimeRateColor[heartRatem]), IconElement);
    };

    function setSelectedData(ID, Data){
        selectedData.current[ID] = Data;
        if (Data.title !== ""){
            blockRef.current[ID].title.current.innerText = Data.title;
        }

    };

    function handleSelectChange(event){
        async function fetchData(id){
            var response = await ( await apiTools.getResponseByGet("/event/getTrainTemplateById",{"id":id})).json();
            var item = response.template;
            var current = selectTrainingID;
            selectedData.current = {};
            setName(item.name);
            const event = item.training.map( (block, index) => {
                const data = {
                    "title": "",
                    "times": block.times,
                    "train_type": block.train_type,
                    "heart_rate": block.heart_rate || "最低強度",
                    "subBlock": block.training_block
                };
                return  addSelectEvent(data,current+index);
            });
            // 這邊看對方要直接修改成模板的樣子，還是直接增加
            setSelectTraining(event);
        }
        fetchData(event.value)
    };

    function handleSelectStudent(event){
        setSelectStudent(event);
    };

    function addSelectEvent(Data,ID){
        const blockID = "selected" + ID;
            
        blockRef.current[blockID] = {
            "block":React.createRef(),
            "title":React.createRef(),
            "icon":React.createRef(),                
            "IsOpen":React.createRef()
        };
            
        selectedData.current[blockID] = Data;
        const event = (
            <React.Fragment key={blockID}>
                <Draggable draggableId={blockID} index={selectTraining.length} >
                    {(provided) =>(
                        <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                        >
                            <div ref={blockRef.current[blockID].block} className="ListButton TrainingButton" style={{backgroundColor:backgroundColor}} >
                                <div  className="TrainingListTitle" onClick={() => handleClickListButton(blockID)}>
                                <div className="TrainingLeft">
                                    <div className="ListIcon" ref={blockRef.current[blockID].icon}>{tableTimeIcon[selectedData.current[blockID].train_type,tableTimeRateColor[selectedData.current[blockID].heart_rate]]}</div>                
                                    <div>
                                        <div ref={blockRef.current[blockID].title}>{selectedData.current[blockID].title}</div> {/* 趟數*距離 泳姿 器材 */}
                                    </div>
                                    <div className="ListIcon" onClick={()=>setRemoveBlockID(blockID)}><ClearIcon/></div>
                                </div>
                                </div>
                                <div ref={blockRef.current[blockID].IsOpen} className="ListClose">
                                    <CourseBlock trainingID = {blockID} setTrainingColor={changeTrainingColor}  data={ selectedData.current[blockID]} setData={setSelectedData}/>
                                </div>
                            </div>      
                        </div>
                    )}
                </Draggable>            
            </React.Fragment>
            );

        setSelectTrainingID((selectTrainingID) => (selectTrainingID + 1));
        return(event);
    };

    function addEvent(){
        setSelectTraining((prev)=>[...prev,addSelectEvent({...templateData},selectTrainingID)]) ;
    }

    useEffect(() => {
        const listItem = blockRef.current[currentListSubPage];
        if (listItem) {
            if (listItem.IsOpen && listItem.IsOpen.current) {
                listItem.IsOpen.current.className = "ListOpen";
              }
        }
    },[currentListSubPage]);

    useEffect(() => {
        const listItem = blockRef.current[preListSubPage];
        if (listItem) {
            if (listItem.IsOpen && listItem.IsOpen.current) {
                listItem.IsOpen.current.className = "ListClose";
              }
        }
    },[preListSubPage]);

    useEffect(() => {
        if (removeBlockID !== null) {
            setSelectTraining((selectTraining) => selectTraining.filter((event) => event.key !== removeBlockID));
            var filteredKeys = Object.keys(selectedData.current).filter(key => key !== removeBlockID);
            var newData = Object.fromEntries(filteredKeys.map(key => [key, selectedData.current[key]]));
            selectedData.current = newData;
            setUpdata(true);
        }
    }, [removeBlockID]);

    useEffect(() => {
        if (updata){
            updataItemIndex(selectTraining);
            setUpdata(false);
        }
    },[selectTraining]);

    useEffect(() => {
        const fetchData = async() => {
          var response = await apiTools.getResponseByGet("/event/getTrainTemplateByUserId",{"user_id":student_id});
          var responseData = await response.json();
          
          var temp = responseData.map(data => {
            return {
                "id": data.train_template_id,
                "name": data.name
            };
          });
          setTemplateName(temp);
        }
        
        fetchData(); // 调用异步函数
    },[decodeJWT]);

    useEffect(()=>{
        const getData = async () =>{
            var dic = {"id": decodeJWT["id"]}
            const response = await (await apiTools.getResponseByGet("/user/getFriendById",dic)).json();
            const student = response.map((item)=>{
                return({
                    value: item.student_id,
                    label: <div>{item.name}</div>,
                    group: false,
                    });
                });
            dic = {"coach_id":decodeJWT["id"]}
            const groupresponse = await (await apiTools.getResponseByGet("/user/getGroupByCoachId",dic)).json();
            const group = groupresponse.map((item)=>{
                return({
                    value: item.id,
                    label: <div>{item.name}</div>,
                    group: true,
                    });
                });
            setStudentList(
                [
                    {
                        label: "group",
                        options: group,
                    },
                    {
                        label: "student",
                        options: student,
                    }
                ]
            );
        }
        if (decodeJWT['identity'] === 'coach'){
            getData();    
        }
    },[decodeJWT]);

    useEffect(() =>{
        apiTools.decodeJWT().then((value)=>{
            setDecodeJWT(value);
        });
    },[]);

    return (
        <>
            <div className="bottomSheetTamplate">
                <h1 style={{textAlign:"center"}}>新增課表</h1>
                <div className="ContentRow">
                    <div className="Left Row">日期<div className="red">*</div></div>
                    <div className="Right">
                        <DatePicker className="datepicker" selected={startDate} onChange={(date) => setStartDate(date)} dateFormat={"yyyy-MM-dd"} />
                    </div>
                </div>
                {decodeJWT['identity'] === 'coach'  && 
                    <div className="ContentRow">
                        <div className="Left Row">成員<div className="red">*</div></div>
                        <div className="Right">
                            <Select 
                                className="RightSelect"
                                options={studentList}
                                onChange={(e) => {handleSelectStudent(e)}}
                            />
                    </div>
                </div>
                } 

                <div className="ContentRow">
                    <div className="Left">現有菜單</div>
                    <div className="Right">
                        <Select 
                            onChange={handleSelectChange}
                            className="RightSelect"
                            options={templateName.map((event,index)=>({
                                value: event.id,
                                label: event.name      
                            }),
                            )}
                        />
                    </div>
                </div>
                
                <div className="ContentRow">
                    <div className="Left Row">名稱<div className="red">*</div></div>
                    <div className="Right"><input className = "BottomInput" value={name} onChange={(e) => setName(e.target.value)}></input></div>
                </div>  

                <div className="ContentRow">
                    <div className="Left">
                        <div className="LeftRight">已選課程</div>
                    </div>
                </div>

                <div className="ListContent" >
                    <DragDropContext
                          onDragEnd={(e) => onDragEnd(e)}
                    >
                        <Droppable droppableId="Select">
                            {(provided) => (
                                <div ref={provided.innerRef} {...provided.droppableProps}>
                                    <ul style={{listStyle :"none",paddingLeft:"5%"}} >
                                        <div className="ListButton" >
                                            <div  className="ListTitle" onClick={() =>addEvent()}> 
                                                <div className="ListIcon"><TripOriginOutlinedIcon /></div>
                                                <div>新增</div>  
                                            </div>
                                        </div>
                                        {selectTraining}
                                    </ul>
                                    {provided.placeholder}
                                </div>
                            )} 
                        </Droppable>
                    </DragDropContext>
                </div>
                <div className="buttonSubmitArea ContentRow"><button className="buttonSubmit" type="submit" onClick={handleSubmit} >儲存</button></div>
                <div className="buttonSubmitArea ContentRow"><button className="buttonSubmit" type="submit" onClick={handleSubmitAndStoreTemplate} >儲存並存為模板</button></div>
                <div className="ContentRow Center"><input type= "checkbox" value={isPublic} onChange={(value) => setIsPublic(value)}/><label>分享此模板</label></div>
            </div>
        </>
    );

}

export default AddTimeTablePage;