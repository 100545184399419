import { BrowserRouter, Routes, Route } from "react-router-dom";
import MainPage from "./MainPage/MainPage";
import LoginPage from "./LoginPage/LoginPage";
import SignUpPage from './SignUpPage/SignUpPage';
import LoginAccountPage from './LoginPage/LoginAccountPage';
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/signup" element={<SignUpPage />}/>
        <Route path="/login" element={<LoginAccountPage/>}/>
        <Route path="/main" element={<MainPage />} />
        <Route path="/*" element={<LoginPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
