import React from 'react';
import {CircularProgress} from "@mui/material";

function twoCircularProgress(props){
    const value = props.value;
    const text = props.text;
    return(
        <>
            <div style={{ position: "relative", display: "inline-flex" }}>
            {/* 大圓 */}
                    <CircularProgress
                        variant="determinate"
                        value={100}
                        thickness={2}
                        size={200}
                        style={{ color: "lightgray", position: "absolute" }}
                    />
                    <CircularProgress
                        variant="determinate"
                        value={value}
                        thickness={2}
                        size={200}
                    />

                    {/* 小圓 */}
                    <div
                        style={{
                        position: "absolute",
                        top: "50%", // 垂直居中
                        left: "50%", // 水平居中
                        transform: "translate(-50%, -50%)", // 平移，使小圓完全居中
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                        }}
                    >
                        <CircularProgress
                        variant="determinate"
                        value={100}
                        thickness={2}
                        size={160}
                        style={{ color: "lightgray", position: "absolute" }}
                        />
                        <CircularProgress
                        variant="determinate"
                        value={value}
                        thickness={2}
                        size={160}
                        />
                    </div>
                    <div
                        style={{
                        position: "absolute",
                        top: "50%", // 垂直居中
                        left: "50%", // 水平居中
                        transform: "translate(-50%, -50%)", // 平移，使文字完全居中
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "black", // 設定文字顏色
                        fontSize: "20px", // 設定文字大小
                        textAlign: "center",
                        fontFamily: "ABeeZee"
                        }}
                    >
                        {/* 自訂的文字 */}
                        {text}
                        <br />
                        {/* 進度百分比 */}
                        {`${Math.round(value)}%`}
                    </div>
                </div>
        </>
    );
}

export default twoCircularProgress