// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ContestContent{
    display: flex;
    flex-direction: column;
}

.myProfile{
    width: 90%;
    border-radius: 10px;
    border: 1px solid #ECECEC;
    background: #FAFAFA;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.25);
    padding: 20px;
    margin-top: 10px;
}



.spaceRround{
    justify-content: space-around;
}

.TextCenter{
    text-align: center;
}

.topProfile{
    margin-bottom: 10px;
    align-items: center;
}

.textGray{
    color:  #979797;
}

.textMargin{
    margin: 5px;
}

.CheckScore{
    display: flex;
    background-color: #002DE3;
    color: #ffffff;
    border-radius: 30px ;
    padding: 10px 15px;
    align-items: center;
    justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/MainPage/SubPage/ContestPage/ContestPage.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,UAAU;IACV,mBAAmB;IACnB,yBAAyB;IACzB,mBAAmB;IACnB,+CAA+C;IAC/C,aAAa;IACb,gBAAgB;AACpB;;;;AAIA;IACI,6BAA6B;AACjC;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,aAAa;IACb,yBAAyB;IACzB,cAAc;IACd,oBAAoB;IACpB,kBAAkB;IAClB,mBAAmB;IACnB,uBAAuB;AAC3B","sourcesContent":[".ContestContent{\r\n    display: flex;\r\n    flex-direction: column;\r\n}\r\n\r\n.myProfile{\r\n    width: 90%;\r\n    border-radius: 10px;\r\n    border: 1px solid #ECECEC;\r\n    background: #FAFAFA;\r\n    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.25);\r\n    padding: 20px;\r\n    margin-top: 10px;\r\n}\r\n\r\n\r\n\r\n.spaceRround{\r\n    justify-content: space-around;\r\n}\r\n\r\n.TextCenter{\r\n    text-align: center;\r\n}\r\n\r\n.topProfile{\r\n    margin-bottom: 10px;\r\n    align-items: center;\r\n}\r\n\r\n.textGray{\r\n    color:  #979797;\r\n}\r\n\r\n.textMargin{\r\n    margin: 5px;\r\n}\r\n\r\n.CheckScore{\r\n    display: flex;\r\n    background-color: #002DE3;\r\n    color: #ffffff;\r\n    border-radius: 30px ;\r\n    padding: 10px 15px;\r\n    align-items: center;\r\n    justify-content: center;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
