// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.courseBlock{
    padding-left: 46px ; /*16 + 30*/
    padding-right: 15px;
    display: flex;
    flex-direction: column;
}

.courseContent{
    display: flex;
    flex-direction: row;
    margin-top: 7px;
    margin-bottom: 7px;
}

.blockInput{
    margin-right: 20px;
}

.blockTitle{
    min-width: 50px;
}


.blockRight{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.addBlock{
    color: #000000;
    min-width: 100px;
    max-width: 200px;
    width: 100%;
    background-color:  #F8F9FA;
    border: #000000 solid 1px;
    border-radius: 15px ;
    min-height: 30px;
}

.addCourse{
    border-top: 1px solid rgba(0, 0, 0, 0.25);
}

.blockInput input{
    width: 100%;
    border-radius: 30px;
    border: 0.5px solid  #ECECEC;
}

.blockInput select{
    width: 100%;
    border-radius: 30px;
    border: 0.5px solid  #ECECEC;
}

.blockInput{
    width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/MainPage/components/CourseBlock/CourseBlock.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB,EAAE,UAAU;IAC/B,mBAAmB;IACnB,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,eAAe;AACnB;;;AAGA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;AAClC;;AAEA;IACI,cAAc;IACd,gBAAgB;IAChB,gBAAgB;IAChB,WAAW;IACX,0BAA0B;IAC1B,yBAAyB;IACzB,oBAAoB;IACpB,gBAAgB;AACpB;;AAEA;IACI,yCAAyC;AAC7C;;AAEA;IACI,WAAW;IACX,mBAAmB;IACnB,4BAA4B;AAChC;;AAEA;IACI,WAAW;IACX,mBAAmB;IACnB,4BAA4B;AAChC;;AAEA;IACI,WAAW;AACf","sourcesContent":[".courseBlock{\r\n    padding-left: 46px ; /*16 + 30*/\r\n    padding-right: 15px;\r\n    display: flex;\r\n    flex-direction: column;\r\n}\r\n\r\n.courseContent{\r\n    display: flex;\r\n    flex-direction: row;\r\n    margin-top: 7px;\r\n    margin-bottom: 7px;\r\n}\r\n\r\n.blockInput{\r\n    margin-right: 20px;\r\n}\r\n\r\n.blockTitle{\r\n    min-width: 50px;\r\n}\r\n\r\n\r\n.blockRight{\r\n    display: flex;\r\n    flex-direction: row;\r\n    justify-content: space-between;\r\n}\r\n\r\n.addBlock{\r\n    color: #000000;\r\n    min-width: 100px;\r\n    max-width: 200px;\r\n    width: 100%;\r\n    background-color:  #F8F9FA;\r\n    border: #000000 solid 1px;\r\n    border-radius: 15px ;\r\n    min-height: 30px;\r\n}\r\n\r\n.addCourse{\r\n    border-top: 1px solid rgba(0, 0, 0, 0.25);\r\n}\r\n\r\n.blockInput input{\r\n    width: 100%;\r\n    border-radius: 30px;\r\n    border: 0.5px solid  #ECECEC;\r\n}\r\n\r\n.blockInput select{\r\n    width: 100%;\r\n    border-radius: 30px;\r\n    border: 0.5px solid  #ECECEC;\r\n}\r\n\r\n.blockInput{\r\n    width: 100%;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
