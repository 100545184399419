// import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import Select from "react-select"; // 引入 react-select
import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom';

import Addpicture from "../images/AddPicture.svg";
import apiTools from "../apiTools";
import "./SignUp.css"
function SignUpPage(){
    const [isPicture,setisPicture] = React.useState(false);
    const [Identity,setIdentity] = React.useState('');
    const [Picture,setPicture] = React.useState("");
    const [Name,setName] = React.useState();
    const [ID,setID] = React.useState();
    const [Password,setPassword] = React.useState();

    const identityDict = {"游泳選手":"student","游泳教練":"coach"}; 

    const handleChange = (event) =>{
        setIdentity(event.value);
    };

    let  history = useNavigate();


    function SignUpSubmit(){
        var json = {"name": Name, 
                    "account": ID,
                    "password": Password,
                    "identity": identityDict[Identity],
                    "photo": Picture,
                };

        apiTools.signup(JSON.stringify(json))
            .then(result => {
                if (result.ok){
                    history("/");
                }else{
                    alert("帳號已存在");
                }
            });

    }
    

    const handleAddPictureChange = async (event) => {
        if (!isPicture) {
            setisPicture(!isPicture);
        }
        const file = event.target.files[0];
        if (file) {
            try {
                compressImage(file, 100 * 1024, (compressedBlob) => {
                    const compressedFile = new File([compressedBlob], file.name, { type: compressedBlob.type });
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        const base64String = reader.result;
                        setPicture(base64String);
                    };
                    reader.readAsDataURL(compressedFile);
                });
            } catch (error) {
                console.error('圖片壓縮失敗:', error);
            }
        }
    };

    function compressImage(sourceFile, maxSizeInBytes, callback) {
        var reader = new FileReader();
        reader.onload = function(event) {
            var image = new Image();
            image.src = event.target.result;
            image.onload = function() {
                var canvas = document.createElement('canvas');
                var ctx = canvas.getContext('2d');
                var width = image.width;
                var height = image.height;
    
                // 计算压缩比例
                var ratio = 1;
                if (width * height > maxSizeInBytes) {
                    ratio = Math.sqrt(maxSizeInBytes / (width * height));
                }
    
                // 设置 canvas 大小，并绘制图像
                canvas.width = width * ratio;
                canvas.height = height * ratio;
                ctx.drawImage(image, 0, 0, width, height, 0, 0, canvas.width, canvas.height);
    
                // 将 canvas 转换为 Blob，回调返回 Blob 对象
                canvas.toBlob(function(blob) {
                    callback(blob);
                }, 'image/jpeg', 0.7); // 第三个参数是图像质量，可以根据需要调整
            };
        };
        reader.readAsDataURL(sourceFile);
    }
    

    return(
        <>
            <div className="SignUp">
                <div className="AddPictureArea">
                    <label>
                        <input type="file" style={{display:"none"}} accept="image/png, image/jpeg" onChange={handleAddPictureChange}/>
                            {!isPicture && <img className="SignUpPicture" src={Addpicture} alt={"新增照片"}></img>}
                            {isPicture &&  <img className="SignUpPicture" style={{borderRadius:"50%"}} src={Picture} alt={"大照片"}></img>}
                    </label>
                    
                </div>
                <div className="SignUpContent">
                    <div className= "SignUpInput"><input placeholder="暱稱Name" onChange={(event)=>{setName(event.target.value)}}></input></div>
                    <div className= "SignUpInput"><input placeholder="帳號ID" onChange={(event) =>{setID(event.target.value)}}></input></div>
                    <div className= "SignUpInput"><input placeholder="密碼Passwords" type="password" onChange={(event) =>{setPassword(event.target.value)}}></input></div>
                    <div className=" SignUpInput identity">
                        <Select
                            onChange={handleChange}
                            options={[
                                {value:"游泳選手",label:"游泳選手"},
                                {value:"游泳教練",label:"游泳教練"},

                            ]}
                        />
                    </div>
                </div>
                
                <div className="SignUpSubmitArea"><button className="SignUpSubmit" onClick={SignUpSubmit}>確定儲存</button></div>
            </div>

        </>
    );

}

export default SignUpPage