// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ConnectInput{
    border: none;
    border-bottom: 1px solid #000;
    margin-right: 10px;
}

.SubmitButton{
    background-color: #002DE3;
    color: white;
    border: none;
    border-radius: 20px;
    font-size: 20px;
    width: -moz-fit-content;
    width: fit-content;
    padding: 3px  7px;
    margin-top: 5px;
}


`, "",{"version":3,"sources":["webpack://./src/MainPage/SubPage/CoachConnectPage/CoachConnectPage.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,6BAA6B;IAC7B,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,mBAAmB;IACnB,eAAe;IACf,uBAAkB;IAAlB,kBAAkB;IAClB,iBAAiB;IACjB,eAAe;AACnB","sourcesContent":[".ConnectInput{\r\n    border: none;\r\n    border-bottom: 1px solid #000;\r\n    margin-right: 10px;\r\n}\r\n\r\n.SubmitButton{\r\n    background-color: #002DE3;\r\n    color: white;\r\n    border: none;\r\n    border-radius: 20px;\r\n    font-size: 20px;\r\n    width: fit-content;\r\n    padding: 3px  7px;\r\n    margin-top: 5px;\r\n}\r\n\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
