// Sidebar.js
import React, {useEffect} from "react";
import { useDispatch } from 'react-redux';
import { setSubPage } from "../../../App/activeSubPage";
import BedtimeOutlinedIcon from '@mui/icons-material/BedtimeOutlined';
import ListOutlinedIcon from '@mui/icons-material/ListOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import PlayCircleOutlineRoundedIcon from '@mui/icons-material/PlayCircleOutlineRounded';
import LogoutIcon from '@mui/icons-material/Logout';

import profile from '../../../images/user.png';


import './Sidebar.css'; // 引入CSS樣式
import apiTools from '../../../apiTools';

function Sidebar(props) {
  const dispatch = useDispatch();
  const [decodeJWT, setDecodeJWT] = React.useState({});
  const [photo, setPhoto] = React.useState("");
  const src = photo === "" ? profile : photo;
  const idenitity = {"student":"游泳選手","coach":"游泳教練"}

  useEffect(() =>{
      apiTools.decodeJWT().then((value)=>{
          setDecodeJWT(value);
      });
      apiTools.getPhoto().then((photo) =>{
        setPhoto(photo);
      })
  },[]);

  async function logout(){
    await apiTools.resetJWT();
    window.location.href ="/";
}



  return (
    <div className={`sidebar ${props.isOpen ? 'open' : ''} sidebarContent`}>
      {/* 個人資料 */}
      <div style={{display : 'flex',flexDirection : 'row',padding :"5px 6px 10px 6px"}}>
          <div className='personLeft'><img alt={"大頭照"} style={{borderRadius:"50%",width:"80px", height : "80px"}} src = {src}></img></div>
          <div className='personRight'>
            <div style={{fontSize:"24px"}}>{decodeJWT['name']}</div>
            <div style={{color:"#",fontSize:"16"}} >{decodeJWT['account']}</div>
            <div className = 'auto-width-div' style={{fontSize:"16px"}}>{idenitity[decodeJWT['identity']]}</div>
          </div>
      </div>
      <hr/>
      {/* 路徑的選擇 */}
      <nav>
        <button onClick={()=>{dispatch(setSubPage('trainingTemplate'));props.setSidebarOpen();}}><ListOutlinedIcon/><div>菜單模板</div></button>
        <button onClick={()=>{dispatch(setSubPage('analytics'));props.setSidebarOpen();}}><PlayCircleOutlineRoundedIcon/><div>訓練影片</div></button>
        <button onClick={()=>{dispatch(setSubPage('survey'));props.setSidebarOpen();}}><BedtimeOutlinedIcon/><div>睡眠紀錄</div></button>
        <button onClick={()=>{dispatch(setSubPage('setting'));props.setSidebarOpen();}}><SettingsOutlinedIcon/><div>設定</div></button>
        <button onClick={logout}><LogoutIcon/><div>登出</div></button>
      </nav>
      <hr/>
    </div>
  );
};

export default Sidebar;