import React, { useState, useRef } from 'react';
import './Drawer.css'; // 如果需要自定义样式，可以在此文件中编写CSS

const Drawer = ({ isOpen, onClose, children }) => {
  const [isOpenInternal, setIsOpenInternal] = useState(isOpen);
  const drawerRef = useRef(null);

  // 当点击背景遮罩层或关闭按钮时关闭Drawer
  const handleClose = () => {
    setIsOpenInternal(false);
    onClose();
  };

  // 处理点击背景遮罩层的事件
  const handleOverlayClick = (e) => {
    if (e.target === drawerRef.current) {
      handleClose();
    }
  };

  // 设置Drawer的样式
  const drawerClass = isOpenInternal ? 'drawer open' : 'drawer';

  return (
    <div className={drawerClass} ref={drawerRef} onClick={handleOverlayClick}>
      <div className="drawer-content">
        {children}
      </div>
    </div>
  );
};

export default Drawer;
