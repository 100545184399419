import React,{useEffect} from 'react';
import Slider from '@mui/material/Slider';
import { useDispatch } from 'react-redux';
import { setBottomPage } from '../../../App/activeBottomPage';
import TripOriginOutlinedIcon from '@mui/icons-material/TripOriginOutlined';

import EmojiEmotionsOutlinedIcon from '@mui/icons-material/EmojiEmotionsOutlined';
import SentimentNeutralOutlinedIcon from '@mui/icons-material/SentimentNeutralOutlined';
import MoodBadOutlinedIcon from '@mui/icons-material/MoodBadOutlined';

import apiTools from "../../../apiTools";
import DetailTrainBlock from '../../components/DetailTrainBlock/DetailTrainBlock';

import HelpIcon from '@mui/icons-material/Help';
import {ReactComponent as Description} from "../../../images/description.svg";

import "../BottomPage.css"
import "./DetailTrainPage.css"

function DetailTrainPage(props){
    const ID = props.dailyID;
    const dispatch = useDispatch();
    const [decodeJWT, setDecodeJWT] = React.useState({});

    useEffect(() =>{
        apiTools.decodeJWT().then((value)=>{
            setDecodeJWT(value);
        });
    },[]);

    const [date, setDate] = React.useState();
    const [name, setName] = React.useState();
    const [templateFeedback, setTemplateFeedback] = React.useState();
    const [studentFeedback, setStudentFeedback] = React.useState();
    const [coachFeedback, setCoachFeedback] = React.useState();
    const [happiness, setHappiness] = React.useState();
    const [fatigueLevel, setFatigueLevel] = React.useState(0);
    const [training, setTraining] = React.useState();
    const [raceScore, setRaceScore] = React.useState({});
    const [helpOpen, setHelpOpen] = React.useState(false);
    const [currentListSubPage, setCurrentListSubPage] = React.useState(null);
    const [preListSubPage, setPreListSubPage] = React.useState(null);

    const blockRef = React.useRef({});
    const blockTitle = React.useRef({});

    const tableTimeEventColor = {"主課程":"#E8EEE9",
                                "熱身":"#F5EAEA",
                                "緩和":"#E5E9F5",
                                "技術動作":"#F8EFCE"};
    
    const tableTimeIconColor = {"主課程":"#3B9048",
                                "熱身":"#F25B5B",
                                "緩和":"#1544EC",
                                "技術動作":"#FFC805"};

    const getColor = (value) => {
        if (value >= 0 && value <= 3) {
          return '#007bff'; // 蓝色
        } else if (value >= 4 && value <= 6) {
          return '#28a745'; // 绿色
        } else if (value >= 7 && value <= 8) {
          return '#ffc107'; // 黄色
        } else {
          return '#dc3545'; // 红色
        }
      };

    const emotionColor  = {
        big_smile: '#FFD700', // 金色
        smile: '#ADFF2F', // 绿黄色
        bad: '#D3D3D3', // 亮灰色
      };
    const setInit = () =>{
        dispatch(setBottomPage(""));
    }

    const closeHelp = () =>{
        setHelpOpen(false);
    }
    function handleClickListButton(args){
        setCurrentListSubPage((currentListSubPage) => {
            // 在这里可以执行一些额外的处理
            setPreListSubPage(currentListSubPage);
            // 返回新的状态值
            return args === currentListSubPage ? null : args;
        });
    }

    function handleChange(trainingID, value){
        setRaceScore((prev) => {
            var updata = {...prev};
            updata[trainingID] = value[trainingID];
            return updata;
        });
    }

    function addTrainingEvent(data){
        const trainingID =data.id;
        blockRef.current[trainingID] = {
            "IsOpen":React.createRef(),
        };

        blockTitle.current[trainingID] = "";

        blockTitle.current[trainingID] = data.training_block.map((item) =>
            `${item.iteration}*${item.distance}m ${item.swimming_style} ${item.action ? item.action:""} ${item.time_minute}:${item.time_second} ${item.equipment ? item.equipment:""}`            
        ).join('+'); 

        const event = (
            <React.Fragment key={trainingID}>
              <div className="ListButton" style={{backgroundColor:tableTimeEventColor[data.train_type]}}>
                <div className="TrainingListTitle" onClick={() => handleClickListButton(trainingID)}>
                  <div className="TrainingLeft">
                    <div className="ListIcon">
                      <TripOriginOutlinedIcon sx={{color:tableTimeIconColor[data.train_type]}}/>
                    </div>                
                    <div>{blockTitle.current[trainingID]}</div> {/* 趟数*距离 泳姿 動作 时间 器材 */}
                  </div>
                </div>
                <div ref={blockRef.current[trainingID].IsOpen} className="ListClose">
                    <DetailTrainBlock data={data} handleChange={handleChange}/>
                </div>
              </div>
            </React.Fragment>
          );

        return event
    }

    async function handleSubmit(){
        const trainingData = () =>{
            const dicID = Object.keys(raceScore);
            const dicData = dicID.map((item, index) =>{
                const dicDataID = Object.keys(raceScore[item]);
                const dicDataScore = dicDataID.map((dataItem, dataIndex) => {
                    const score =raceScore[item][dataItem].map((item) =>{
                        const scoreMinute = item[0];
                        const scoreSecond = item[1];
                        const scoreMs = item[2];
                        return(`${scoreMinute}:${scoreSecond}:${scoreMs}`);
                    });
                    return({
                        "id": dataItem,
                        "iteration_record": score
                    });
                })
                return({
                    "id":item,
                    "training_block": dicDataScore
                });
            });
            return dicData;
        };
        var body = {
            "date": date,
            "schedule_name": name,
            "template_feedback": templateFeedback,
            "student_feedback": studentFeedback,
            "coach_feedback": coachFeedback,
            "happiness": happiness,
            "fatigue_level": fatigueLevel,
            "training": trainingData()
        };
        const response = await apiTools.getResponseByPut("event/updateTrainingSchedule",body,ID);
        if (response.ok){
            setInit();
        }else{
            alert("請確定網路");            
        }    
    }

    useEffect(() => {
        const listItem = blockRef.current[currentListSubPage];
        if (listItem) {
            if (listItem.IsOpen && listItem.IsOpen.current) {
                listItem.IsOpen.current.className = "ListOpen";
              }
        }
    },[currentListSubPage]);

    useEffect(() => {
        const listItem = blockRef.current[preListSubPage];
        if (listItem) {
            if (listItem.IsOpen && listItem.IsOpen.current) {
                listItem.IsOpen.current.className = "ListClose";
              }
        }
    },[preListSubPage]);
    
    
    useEffect(() =>{
        const event = async () =>{
            const response = await (await apiTools.getResponseByGet("/event/getTrainingEventById",{"id":ID})).json();
            setDate(response.date);
            setName(response.schedule_name);
            setTemplateFeedback(response.template_feedback);
            setStudentFeedback(response.student_feedback);
            setCoachFeedback(response.coach_feedback);
            setHappiness(response.happiness)
            if (response.fatigue_level !== null){
                setFatigueLevel(parseInt(response.fatigue_level));
            }
            const trainingBlock = response.training.map((item) =>{
                return addTrainingEvent(item);
            });

            setTraining(trainingBlock);            
        };
        event();
    },[]);

    const handleHappiness = (value)=>{
        if (decodeJWT['identity'] === 'student'){
            setHappiness(value);
        }
    }
    
    return (
        <>
            <div className='bottomSheetTamplate'>
                <div className="ContentRow">
                    <input type="text" className="PageTitle" value={name} onChange={(e) => setName(e.target.value)}/>
                </div>
                <div className="ContentRow">
                    <div className="Left">紀錄</div>
                </div>
                <div className="ListContent">
                    <ul style={{listStyle :"none",paddingLeft:"0%"}}>
                        {training}
                    </ul>
                </div>
                <div className="ContentRow">
                    <div className="Left">選手回饋</div>
                </div>
                <textarea placeholder='請輸入' className="bottomTextArea"  rows="5" value={studentFeedback} onChange={(e) => setStudentFeedback(e.target.value)} disabled={decodeJWT['identity'] !== 'student'} />
                { decodeJWT['identity'] === 'student' &&
                <>
                 <div className="ContentRow">
                    <div className="Left">紀錄</div>
                    <div className="Right">
                        <div className='RadioButton' onClick={() => handleHappiness("big_smile")}><EmojiEmotionsOutlinedIcon sx={{color : happiness === "big_smile"?emotionColor[happiness]:""}}/></div>
                        <div className='RadioButton' onClick={() => handleHappiness("smile")}><SentimentNeutralOutlinedIcon sx={{color : happiness === "smile"?emotionColor[happiness]:""}} /></div>
                        <div className='RadioButton' onClick={() => handleHappiness("bad")}><MoodBadOutlinedIcon sx={{color : happiness === "bad"?emotionColor[happiness]:""}} /></div>
                    </div>
                </div>                    
                <textarea className="bottomTextArea" placeholder='請輸入'  rows="5" value={templateFeedback} onChange={(e) => setTemplateFeedback(e.target.value)} />
                </>   
                }
                <div className="ContentRow">
                    自覺疲勞程度
                    <HelpIcon sx={{marginLeft:"3px"}} onClick={()=>setHelpOpen(true)}/>
                </div>
                
                <div className='ContentRow'>
                    <Slider sx={{width:"90%",color:getColor(fatigueLevel),marginRight:"5px"}} min={0} max={10} step={1} value={fatigueLevel} onChange={(e) => setFatigueLevel(e.target.value)} disabled={decodeJWT['identity'] !== 'student'}/>              
                    <div>{fatigueLevel}</div>
                </div>
                <div className="ContentRow">
                    <div className="Left">教練回饋</div>
                </div>
                <textarea placeholder='請輸入' className="bottomTextArea"  rows="5" value={coachFeedback} onChange={(e) => setCoachFeedback(e.target.value)} disabled={decodeJWT['identity'] !== 'coach'} />
                
                <div className="buttonSubmitArea ContentRow"><button className="buttonSubmit" type="submit" onClick={handleSubmit} >儲存</button></div>
            </div>
            {helpOpen &&
                <>
                    <div className="overlay" onClick={()=> setHelpOpen(false)}>
                        <div className="tooltip" onClick={(e) => e.stopPropagation()}>
                            <Description/>
                            <div style={{margin:"10px 0px"}}>根據今日訓練的疲勞狀態填寫作為課表調整的參考</div>
                            <button className='buttonSubmit' onClick={() => setHelpOpen(false)}>關閉</button>
                        </div>
                    </div>
                </>
            }
        </>

    )
}

export default DetailTrainPage