import React, {useEffect} from "react";
import "./SettingPage.css";
import { useDispatch } from 'react-redux';
import { setSubPage } from "../../../App/activeSubPage";
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import SportsIcon from '@mui/icons-material/Sports';
import HubOutlinedIcon from '@mui/icons-material/HubOutlined';
import LanguageIcon from '@mui/icons-material/Language';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import GroupsIcon from '@mui/icons-material/Groups';

import profile from '../../../images/user.png';
import apiTools from '../../../apiTools';


function SettingPage(props){
    const dispatch = useDispatch();

    const [decodeJWT, setDecodeJWT] = React.useState({});
    const [photo, setPhoto] = React.useState("");
    const src = photo === "" ? profile : photo;
    const idenitity = {"student":"游泳選手","coach":"游泳教練"};  


    useEffect(() =>{
        apiTools.decodeJWT().then((value)=>{
            setDecodeJWT(value);
        });
        apiTools.getPhoto().then((value) => {
            setPhoto(value);
        })
    },[]);

    return(
        <>
            <div className="setting">
                <nav className="settingNav">
                    <div className="settingNavContent" style={{margin:"20px 5px"}}>
                        <div style={{marginRight:"30px"}}>
                            <img alt={"大頭照"} style={{borderRadius:"50%",width:"80px", height : "80px"}} src = {src}/>
                        </div>
                        <div>
                            <div className="settingProfile" style={{fontSize:"25px",color:"black"}}>{decodeJWT['name']}</div>
                            <div className="settingProfile" style={{fontSize:"15px",color:"gray"}}>{decodeJWT['account']}</div>
                            <div className="settingProfile" style={{fontSize:"15px",color:"gray"}}>{idenitity[decodeJWT['identity']]}</div>
                        </div>
                    </div>
                    <button>
                        <div className="settingNavContent">
                            <div className="settingNavContentIcon"><PersonOutlineOutlinedIcon /></div>
                            <div>帳號設定</div>
                        </div>
                    </button>
                    <button>
                        <div className="settingNavContent" onClick={()=>{dispatch(setSubPage("personalSetting"))}}>
                            <div className="settingNavContentIcon"><ArticleOutlinedIcon/></div>
                            <div>基本資料</div>
                        </div>
                    </button>

                    {
                    decodeJWT['identity'] === "coach" && 
                    <button onClick={()=>{dispatch(setSubPage("coachConnect"))}}>
                        <div className="settingNavContent">
                            <div className="settingNavContentIcon"><GroupsIcon/></div>
                            <div>選手管理</div>
                        </div>
                    </button>
                    }
                    
                    <button>
                        <div className="settingNavContent">
                            <div className="settingNavContentIcon"><HubOutlinedIcon/></div>
                            <div>裝置連接</div>
                        </div>
                    </button>
                    <button>
                        <div className="settingNavContent">
                            <div className="settingNavContentIcon"><LanguageIcon/></div>
                            <div>語言</div>
                        </div>
                    </button>
                    <hr/>
                    <button>
                        <div className="settingNavContent">
                            <div className="settingNavContentIcon"><InfoOutlinedIcon/></div>
                            <div>關於</div>
                        </div>
                    </button>
                </nav>
            </div>
        </>
    );
}

export default SettingPage;