import React from "react";
import { useNavigate  } from "react-router-dom";
import LoginImage from '../images/LoginImage.png';
import './LoginPage.css';
import apiTools from "../apiTools";

function LoginPage(){
    let  history = useNavigate ();
    function signUp(){
        history('/signup');
    }
    async function SignIn(){
        if(await apiTools.checkJWT() ){
            history('/main');
        }else{
            history('/login');

        }
        
    }
    return(
        <>
            <div className="Login">
                <div className="LoginContent">
                    <div className="LoginContent LoginImage"><img src={LoginImage} alt="只是圖片"/></div>
                </div>
                <div className="LoginConetent LoginButton">
                    <div className="LoginContent signIn"><button onClick={SignIn}>登入</button></div>
                    <div className="LoginContent LoginSignUp"><button onClick={signUp}>創建帳號</button></div>
                    <div className="LoginContent terms"><button>使用條款</button></div>
                </div> 
            </div>
        </>
    );
}

export default LoginPage;