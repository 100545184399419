// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SignUp{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-top: 20vh;
    margin-bottom: 10vh;
    height: 70vh;
}

.SignUpInput{
    width: 100%;
    height: 30px;
    text-align: center;
    margin-top: 15px ;
    margin-bottom: 15px;
}

.SignUpInput>input{
    width: 85%;
    border-radius: 15px;
    height: 30px;
    border: none;
    background-color: #F7F7FC;
}


.identity{
    width: 90%;
    text-align: left  ;
}

.SignUpContent{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100% ;
}

.AddPicture{
    border: none;
    background-color: transparent;
}

.AddPictureArea{
    top: 40;
    width: 100%;
    text-align: center;
}

.SignUpSubmitArea{
    justify-content: center;
    width: 100%;
    text-align: center;
}

.SignUpSubmit{
    background-Color:#002DE3;
    border-radius:  30px;
    color: #FFFFFF;
    width: 90%;
    font-size: 20px;
    height: 60px;
}

.SignUpPicture{
    width: 100px;
    height: 100px;
}`, "",{"version":3,"sources":["webpack://./src/SignUpPage/SignUp.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,8BAA8B;IAC9B,gBAAgB;IAChB,mBAAmB;IACnB,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,iBAAiB;IACjB,mBAAmB;AACvB;;AAEA;IACI,UAAU;IACV,mBAAmB;IACnB,YAAY;IACZ,YAAY;IACZ,yBAAyB;AAC7B;;;AAGA;IACI,UAAU;IACV,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,6BAA6B;AACjC;;AAEA;IACI,OAAO;IACP,WAAW;IACX,kBAAkB;AACtB;;AAEA;IACI,uBAAuB;IACvB,WAAW;IACX,kBAAkB;AACtB;;AAEA;IACI,wBAAwB;IACxB,oBAAoB;IACpB,cAAc;IACd,UAAU;IACV,eAAe;IACf,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,aAAa;AACjB","sourcesContent":[".SignUp{\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    justify-content: space-between;\r\n    margin-top: 20vh;\r\n    margin-bottom: 10vh;\r\n    height: 70vh;\r\n}\r\n\r\n.SignUpInput{\r\n    width: 100%;\r\n    height: 30px;\r\n    text-align: center;\r\n    margin-top: 15px ;\r\n    margin-bottom: 15px;\r\n}\r\n\r\n.SignUpInput>input{\r\n    width: 85%;\r\n    border-radius: 15px;\r\n    height: 30px;\r\n    border: none;\r\n    background-color: #F7F7FC;\r\n}\r\n\r\n\r\n.identity{\r\n    width: 90%;\r\n    text-align: left  ;\r\n}\r\n\r\n.SignUpContent{\r\n    display: flex;\r\n    flex-direction: column;\r\n    justify-content: center;\r\n    align-items: center;\r\n    width: 100% ;\r\n}\r\n\r\n.AddPicture{\r\n    border: none;\r\n    background-color: transparent;\r\n}\r\n\r\n.AddPictureArea{\r\n    top: 40;\r\n    width: 100%;\r\n    text-align: center;\r\n}\r\n\r\n.SignUpSubmitArea{\r\n    justify-content: center;\r\n    width: 100%;\r\n    text-align: center;\r\n}\r\n\r\n.SignUpSubmit{\r\n    background-Color:#002DE3;\r\n    border-radius:  30px;\r\n    color: #FFFFFF;\r\n    width: 90%;\r\n    font-size: 20px;\r\n    height: 60px;\r\n}\r\n\r\n.SignUpPicture{\r\n    width: 100px;\r\n    height: 100px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
