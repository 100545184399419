// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.buttonSubmitArea{
  justify-content: center;
}

.buttonSubmit{
  background-Color:#002DE3;
  border-radius:  30px;
  color: #FFFFFF;
  width: 70%;
  font-size: 20px;
  height: 60px;
}

.css-13cymwt-control{
  border-radius: 15px !important;
  border: 1px solid #000 !important;
  background-color: #F8F9FA !important;
  align-items: center;
}

.classificationSelect{
  display: flex;
  justify-content: left !important;
  align-items: center;
}


.datePicker{
  justify-content: space-between;
}

`, "",{"version":3,"sources":["webpack://./src/MainPage/SubPage/AddLifeEventPage/AddLifeEvent.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;AACzB;;AAEA;EACE,wBAAwB;EACxB,oBAAoB;EACpB,cAAc;EACd,UAAU;EACV,eAAe;EACf,YAAY;AACd;;AAEA;EACE,8BAA8B;EAC9B,iCAAiC;EACjC,oCAAoC;EACpC,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,gCAAgC;EAChC,mBAAmB;AACrB;;;AAGA;EACE,8BAA8B;AAChC","sourcesContent":[".buttonSubmitArea{\r\n  justify-content: center;\r\n}\r\n\r\n.buttonSubmit{\r\n  background-Color:#002DE3;\r\n  border-radius:  30px;\r\n  color: #FFFFFF;\r\n  width: 70%;\r\n  font-size: 20px;\r\n  height: 60px;\r\n}\r\n\r\n.css-13cymwt-control{\r\n  border-radius: 15px !important;\r\n  border: 1px solid #000 !important;\r\n  background-color: #F8F9FA !important;\r\n  align-items: center;\r\n}\r\n\r\n.classificationSelect{\r\n  display: flex;\r\n  justify-content: left !important;\r\n  align-items: center;\r\n}\r\n\r\n\r\n.datePicker{\r\n  justify-content: space-between;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
