import React, { useEffect } from "react";

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

import GroupDetail from "./GroupDetail/GroupDetail";

import "./ConnectGroupCard.css";

import apiTools from "../../../apiTools";

function ConnectGroupCard(props){
    const data = props.data;
    const name = data.name;
    const id = props.id;
    const reload = props.reload;

    const [open, setOpen] = React.useState(false);
    const [decodeJWT, setDecodeJWT] = React.useState({});
    const [student, setStudent] = React.useState("");

    const handleOpen = () =>{
        setOpen(true);
    }

    const handleClose = () =>{
        setOpen(false);
    }

    useEffect(()=>{
        const getData = async () =>{
            var dic = {"id":decodeJWT["id"]};
            var response = await (await apiTools.getResponseByGet("/user/getFriendById",dic)).json();
            // 从响应数据中获取学生名字
            const studentNames = response.map(item => {
                // 如果 item.student_id 在 data.student_id 中存在，则返回对应的名字
                if (data.student_id.includes(item.student_id)) {
                    return item.name;
                }
                return null; // 如果找不到，返回 null
            }).filter(name => name !== null); // 过滤掉为 null 的项

            // 将学生名字用空格连接，并设置到 state 中
            setStudent(studentNames.join(" "));
        }
        getData();
    },[decodeJWT,reload]);

    useEffect(() =>{
        apiTools.decodeJWT().then((value)=>{
            setDecodeJWT(value);
        });
    },[]);


    return(
        <>
            <div className="ConnectCard">
                <div className="ConnectCardLeft">
                    <div style={{fontSize: "20px"}}>{name}</div>
                    <div>{student}</div>
                </div>
                <div className="ConnectButton ConnectCardRight">
                    <button onClick={handleOpen}>管理</button>
                </div>
                <Dialog open={open} onClose={handleClose}>
                    <DialogContent>
                        <GroupDetail data={props.data} id={id} reload={reload} handleReload={props.handleReload}/>
                    </DialogContent>
                </Dialog>
            </div>
        </>
    );
}

export default ConnectGroupCard;