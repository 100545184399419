// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.columsButton{
    display: flex  ;
    justify-items: center;
    align-items: center;
    flex-direction: column;
}

.hideButton{
    display: flex  ;
    justify-items: center;
    align-items: center;
    flex-direction: column;
    height: 0;
    visibility: hidden;
}

.addopen{
    visibility: visible;
    height: auto;
    display: flex;
    background-color: #F4F4F4;
    border-radius: 30px;
    overflow: visible;
}   

.hideButton button{
    color: #222B45 !important;

}

.hideButton div{
    color: #222B45 !important;
    overflow: visible;
}
`, "",{"version":3,"sources":["webpack://./src/MainPage/components/BottomNavBar/BottomNavBar.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,qBAAqB;IACrB,mBAAmB;IACnB,sBAAsB;AAC1B;;AAEA;IACI,eAAe;IACf,qBAAqB;IACrB,mBAAmB;IACnB,sBAAsB;IACtB,SAAS;IACT,kBAAkB;AACtB;;AAEA;IACI,mBAAmB;IACnB,YAAY;IACZ,aAAa;IACb,yBAAyB;IACzB,mBAAmB;IACnB,iBAAiB;AACrB;;AAEA;IACI,yBAAyB;;AAE7B;;AAEA;IACI,yBAAyB;IACzB,iBAAiB;AACrB","sourcesContent":[".columsButton{\r\n    display: flex  ;\r\n    justify-items: center;\r\n    align-items: center;\r\n    flex-direction: column;\r\n}\r\n\r\n.hideButton{\r\n    display: flex  ;\r\n    justify-items: center;\r\n    align-items: center;\r\n    flex-direction: column;\r\n    height: 0;\r\n    visibility: hidden;\r\n}\r\n\r\n.addopen{\r\n    visibility: visible;\r\n    height: auto;\r\n    display: flex;\r\n    background-color: #F4F4F4;\r\n    border-radius: 30px;\r\n    overflow: visible;\r\n}   \r\n\r\n.hideButton button{\r\n    color: #222B45 !important;\r\n\r\n}\r\n\r\n.hideButton div{\r\n    color: #222B45 !important;\r\n    overflow: visible;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
