import React, { useEffect } from "react";
import { useDispatch } from 'react-redux';
import { setSubPage } from "../../../App/activeSubPage";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import "./PersonalSettingPage.css";

import apiTools from "../../../apiTools";

function PersonalSettingPage(props){
    const dispatch = useDispatch();

    const [date, setDate] = React.useState();
    const [height, setHeight] = React.useState("");
    const [weight, setWeight] = React.useState("");
    const [pool, setPool] = React.useState("");

    const [decodeJWT, setDecodeJWT] = React.useState({});

    useEffect(() => {
        apiTools.decodeJWT().then((value) => {
            setDecodeJWT(value);
        });
    }, []);

    var moment = require('moment');

    async function handleSubmit() {
        const dic = {
            "id": decodeJWT["id"],
            "birthday": moment(date).format("YYYY-MM-DD"),
            "height": height,
            "weight": weight,
            "pool_length": pool
        };
        try {
            const response = await apiTools.getResponseByPut("user/updateUserProfile", dic, "");
            if (response.ok) {
                dispatch(setSubPage("calendar"));
            } else {
                console.error("Error updating profile:", response.statusText);
            }
        } catch (error) {
            console.error("Error updating profile:", error);
        }
    }

    useEffect(() => {
        const getData = async () => {
            try {
                const response = await apiTools.getResponseByGet("user/getUserProfile", {"id": decodeJWT["id"]});
                if (response.ok) {
                    const data = await response.json();
                    setDate(data.birthday !== null ? new Date(data.birthday) : "");
                    setHeight(data.height !== null ? data.height : "");
                    setWeight(data.weight !== null ? data.weight : "");
                    setPool(data.pool_length !== null ? data.pool_length : "");
                } else {
                    console.error("Error fetching profile:", response.statusText);
                }
            } catch (error) {
                console.error("Error fetching profile:", error);
            }
        };
        if (decodeJWT["id"]) {
            getData();
        }
    }, [decodeJWT]);

    return (
        <>
            <div className="Column">
                <div className="Row personalContent">
                    <div style={{width: "80px"}}>生日</div>
                    <DatePicker
                        placeholderText="YYYY/MM/DD"
                        className="personalInput"
                        selected={date}
                        onChange={(date) => setDate(date)}
                        dateFormat={"yyyy-MM-dd"}
                    />
                    <div style={{width: "70px"}}></div>
                </div>
                <div className="Row personalContent">
                    <div style={{width: "80px"}}>身高</div>
                    <input
                        className="personalInput"
                        type="number"
                        placeholder="請輸入身高"
                        value={height}
                        onChange={(e) => setHeight(e.target.value)}
                    />
                    <div style={{width: "70px"}}>公分</div>
                </div>
                <div className="Row personalContent">
                    <div style={{width: "80px"}}>體重</div>
                    <input
                        className="personalInput"
                        type="number"
                        placeholder="請輸入體重"
                        value={weight}
                        onChange={(e) => setWeight(e.target.value)}
                    />
                    <div style={{width: "70px"}}>公斤</div>
                </div>
                <div className="Row personalContent">
                    <div style={{width: "80px"}}>泳池長度</div>
                    <input
                        className="personalInput"
                        type="number"
                        placeholder="請輸入距離"
                        value={pool}
                        onChange={(e) => setPool(e.target.value)}
                    />
                    <div style={{width: "70px"}}>公尺</div>
                </div>
            </div>
            <div className="personalButton">
                <button className="buttonSubmit" type="submit" onClick={handleSubmit}>
                    確定儲存
                </button>
            </div>
        </>
    );
}

export default PersonalSettingPage;
