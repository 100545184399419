import React, { useEffect, useState } from 'react';
import './CalendarNav.css';

function CalendarNav({ dateClick, select = new Date(), events = [], handleDateRange }) {
    const [currentDate, setCurrentDate] = useState(new Date(select));
    const [selectDate, setSelectDate] = useState(new Date(select));
    const [days, setDays] = useState([]);

    useEffect(() => {
        const newDays = renderWeekDays();
        setDays(newDays);
        if (handleDateRange) {
            handleDateRange(newDays[0], newDays[newDays.length - 1]);
        }
    }, [currentDate]);

    const startOfWeek = (date) => {
        const diff = date.getDate() - date.getDay();
        return new Date(date.setDate(diff));
    };

    const renderWeekDays = () => {
        const start = startOfWeek(new Date(currentDate));
        return Array.from({ length: 7 }, (_, i) => new Date(start.getFullYear(), start.getMonth(), start.getDate() + i));
    };

    const handleDayClick = (day) => {
        setSelectDate(day);
        if (dateClick) {
            dateClick(day);
        }
    };

    const findEventsForDate = (date) => {
        return events.filter(event => new Date(event.date).toLocaleDateString() === date.toLocaleDateString());
    };

    const weekDays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

    return (
        <div className="calendar-container">
            <div className="nav-container">
                <button className="button" onClick={() => setCurrentDate(new Date(currentDate.setDate(currentDate.getDate() - 7)))}>{"<"}</button>
                <div className="date-display">{`${days[0]?.toLocaleDateString()} - ${days[6]?.toLocaleDateString()}`}</div>
                <button className="button" onClick={() => setCurrentDate(new Date(currentDate.setDate(currentDate.getDate() + 7)))}>{">"}</button>
            </div>
            <table className="table">
                <thead>
                    <tr>
                        {weekDays.map((dayName, index) => (
                            <th key={index} className="table-header">{dayName}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        {days.map(day => (
                            <td 
                                key={day.toISOString()} 
                                className={selectDate.toLocaleDateString() === day.toLocaleDateString() ? 'table-cell selected-day' : 'table-cell'}
                                onClick={() => handleDayClick(day)}
                            >
                                {day.getDate()}
                            </td>
                        ))}
                    </tr>
                    {events.length > 0 && (
                        <tr>
                            {days.map(day => (
                                <td key={day.toISOString()} className="table-cell">
                                    <div className="events-container">
                                        {findEventsForDate(day).map(event => (
                                            <div key={event.id} className="event-dot" style={{ backgroundColor: event.color }}></div>
                                        ))}
                                    </div>
                                </td>
                            ))}
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
}

export default CalendarNav;
