import { ScoreOutlined } from "@mui/icons-material";
import React, { useEffect } from "react";

function DetailRaceBlock(props){
    const item = props.data;
    const [score, setScore] = React.useState(item.score);
    const [rank, setRank] = React.useState(item.rank);
    // const [raceTime, setRaceTime] = React.useState(item.race_time);

    const handleScoreChange = (index, value) => {
        setScore(prevScore => {
            const newScore = [...prevScore];
            newScore[index] = value;
            return newScore;
        });

    };

    const handleRankChange = (value) => {
        setRank(value);
    };

    // const handleRaceTimeChange = (index, value) => {
    //     setRaceTime(prevRaceTime => {
    //         const newRaceTime = [...prevRaceTime];
    //         newRaceTime[index] = value;
    //         return newRaceTime;
    //     });
    // };

    useEffect(()=>{
        const newItem  = item;
        newItem.score = score;
        newItem.rank = rank;
        // newItem.race_time =raceTime;
        props.handleInputChange(props.index,newItem);
    },[score,rank]);
// 將資料回傳

    return(
        <React.Fragment key={item.id}>
            <div className='ContentRow'>
                <div className="Left">
                    <div className="LeftRight">成績</div>
                </div>
                <div className="Right">
                    <input type="number" className="timeInput" value={score[0]} onChange={(e) => handleScoreChange(0, e.target.value)}/>:
                    <input type="number" className="timeInput" value={score[1]} onChange={(e) => handleScoreChange(1, e.target.value)}/> .
                    <input type="number" className="timeInput" value={score[2]} onChange={(e) => handleScoreChange(2, e.target.value)}/>
                </div>
            </div>
            <div className='ContentRow'>
                <div className="Left">
                    <div className="LeftRight">名次</div>
                </div>
                <div className="Right">
                    <input type="number" className="rank timeInput" value={rank} onChange={(e) => handleRankChange(e.target.value)} />
                </div>
            </div>
            {/* <div className="ContentRow">
                <div className="Left">
                    <div className="LeftRight">比賽時間</div>
                </div>
                <div className="Right score">
                    <input type="number" className="timeInput" value={raceTime[0]} onChange={(e) => handleRaceTimeChange(0, e.target.value)} />
                    <div>:</div>
                    <input type="number" className="timeInput" value={raceTime[1]} onChange={(e) => handleRaceTimeChange(1, e.target.value)}/>
                </div>
            </div> */}
        </React.Fragment>
    );
}

export default DetailRaceBlock;
