import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {setSubPage} from "../../../App/activeSubPage";
import Slider from '@mui/material/Slider';
import apiTools from "../../../apiTools";
import "./SurveyContentPage.css";
import HelpIcon from '@mui/icons-material/Help';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import moment from "moment";

const PSSContent =
[
    {
        title:"1. 一些無法預期的事情發生而感到心煩意亂",
        options:["從不","偶爾","有時","常常","總是"],
        mode: ['radio'],
        score: [0,1,2,3,4]
    },{
        title:"2. 感覺無法控制自己生活中重要的事情",
        options:["從不","偶爾","有時","常常","總是"],
        mode: ['radio'],
        score: [0,1,2,3,4]
    },{
        title:"3. 感到緊張不安和壓力",
        options:["從不","偶爾","有時","常常","總是"],
        mode: ['radio'],
        score: [0,1,2,3,4]

    },{
        title:"4. 成功地處理惱人的生活麻煩",
        options:["從不","偶爾","有時","常常","總是"],
        mode: ['radio'],
        score: [4,3,2,1,0]
    },{
        title:"5. 感到自己是有效地處理生活中所發生的重要改變",
        options:["從不","偶爾","有時","常常","總是"],
        mode: ['radio'],
        score: [4,3,2,1,0]
    },{
        title:"6. 對於有能力處理自己私人的問題感到很有信心",
        options:["從不","偶爾","有時","常常","總是"],
        mode: ['radio'],
        score: [4,3,2,1,0]
    },{
        title:"7. 感到事情順心如意",
        options:["從不","偶爾","有時","常常","總是"],
        mode: ['radio'],
        score: [4,3,2,1,0]
    },{
        title:"8. 發現自己無法處理所有自己必須做的事情",
        options:["從不","偶爾","有時","常常","總是"],
        mode: ['radio'],
        score: [0,1,2,3,4]
    },{
        title:"9. 有辦法控制生活中惱人的事情",
        options:["從不","偶爾","有時","常常","總是"],
        mode: ['radio'],
        score: [4,3,2,1,0]
    },{
        title:"10. 常覺得自己是駕馭事情的主人",
        options:["從不","偶爾","有時","常常","總是"],
        mode: ['radio'],
        score: [4,3,2,1,0]
    },{
        title:"11.常生氣，因為很多事情的發生是超出自己所能控制的",
        options:["從不","偶爾","有時","常常","總是"],
        mode: ['radio'],
        score: [0,1,2,3,4]
    },{
        title:"12. 經常想到有些事情是自己必須完成的",
        options:["從不","偶爾","有時","常常","總是"],
        mode: ['radio'],
        score: [0,1,2,3,4]
    },{
        title:"13. 常能掌握時間安排方式",
        options:["從不","偶爾","有時","常常","總是"],
        mode: ['radio'],
        score: [4,3,2,1,0]
    },{
        title:"14. 常感到困難的事情堆積如山，而自己無法克服它們",
        options:["從不","偶爾","有時","常常","總是"],
        mode: ['radio'],
        score: [0,1,2,3,4]
    }
];

const STOPBangContent =
[
    {
        title:"1. 你是否打呼大聲？",
        options:["是","否"],       
        mode: ['radio'],
        score: [1,0],
        Additional:"聲音大到可以從關閉的門外聽到，或枕邊人會因你晚上打呼而用手肘頂你",
    },{
        title:"2. 你是否在白天經常覺得疲勞、疲累或想睡覺（例如開車時睡著）？",
        options:["是","否"],       
        mode: ['radio'],
        score: [1,0],
    },{
        title:"3. 是否有任何人觀察到你在睡覺時呼吸停止或嗆到/喘氣？",
        options:["是","否"],       
        mode: ['radio'],
        score: [1,0],
    },{
        title:"4. 你是否有高血壓，或正在接受高血壓治療",
        options:["是","否"],       
        mode: ['radio'],
        score: [1,0],
    },{
        title:"5. 身高體重指數是否超過 35 公斤/平方公尺？",
        options:["是","否"],       
        mode: ['radio'],
        score: [1,0],
    },{
        title:"6. 年紀超過 50 歲？",
        options:["是","否"],       
        mode: ['radio'],
        score: [1,0],
    },{
        title:"7. 頸圍尺寸是大號？（從喉結部位測量一圈）",
        options:["是","否"],       
        mode: ['radio'],
        score: [1,0],
        Additional:"針對男性，你的襯衫領口是否為 17 吋/43 公分或更大號的？ <br/>\
        針對女性，你的襯衫領口是否為 16 吋/41 公分或更大號的？",
    },{
        title:"8. 性別 – 男性？",
        options:["是","否"],       
        mode: ['radio'],
        score: [1,0],
    },
];

const PSQIContent =
[
    {
        title:"1. 過去一個月以來，您通常幾點上床睡覺？",
        quantifier:["時","分"],       
        mode: ['input'],
    },{
        title:"2. 過去一個月以來，您通常上床多久才能入睡？",
        quantifier:["分鐘"],       
        mode: ['input'],
    },{
        title:"3. 過去一個月以來，您早上通常何時起床？ ",
        quantifier:["時","分"],       
        mode: ['input'],
    },{
        title:"4. 過去一個月以來，您每晚實際真正睡著的時間有多久？",
        quantifier:["時","分"],       
        mode: ['input'],
    },{
        title:"5. 無法在 30 分鐘內入睡",
        options:["從未發生","每星期少於一次","每星期有一、兩次","每星期三次以上"],       
        mode: ['radio'],
        score: [0,1,2,3]
    },{
        title:"6. 在半夜或清晨便清醒",
        options:["從未發生","每星期少於一次","每星期有一、兩次","每星期三次以上"],       
        mode: ['radio'],
        score: [0,1,2,3]
    },{
        title:"7. 必須起來上廁所",
        options:["從未發生","每星期少於一次","每星期有一、兩次","每星期三次以上"],       
        mode: ['radio'],
        score: [0,1,2,3]
    },{
        title:"8. 覺得呼吸不順暢",
        options:["從未發生","每星期少於一次","每星期有一、兩次","每星期三次以上"],       
        mode: ['radio'],
        score: [0,1,2,3]
    },{
        title:"9. 大聲打鼾或咳嗽",
        options:["從未發生","每星期少於一次","每星期有一、兩次","每星期三次以上"],       
        mode: ['radio'],
        score: [0,1,2,3]
    },{
        title:"10. 會覺得冷",
        options:["從未發生","每星期少於一次","每星期有一、兩次","每星期三次以上"],       
        mode: ['radio'],
        score: [0,1,2,3]
    },{
        title:"11. 會覺得躁熱",
        options:["從未發生","每星期少於一次","每星期有一、兩次","每星期三次以上"],       
        mode: ['radio'],
        score: [0,1,2,3]
    },{
        title:"12. 做惡夢",
        options:["從未發生","每星期少於一次","每星期有一、兩次","每星期三次以上"],       
        mode: ['radio'],
        score: [0,1,2,3]
    },{
        title:"13. 出現身體的疼痛",
        options:["從未發生","每星期少於一次","每星期有一、兩次","每星期三次以上"],       
        mode: ['radio'],
        score: [0,1,2,3]
    },{
        title:"14. 其他困擾，請試述",
        options:["從未發生","每星期少於一次","每星期有一、兩次","每星期三次以上"],   
        quantifier:[""],
        mode: ['input',"radio"],
        score: [0,1,2,3]
    },{
        title:"15. 過去一個月以來，您有幾次需要吃藥才能入睡？",
        options:["從未發生","每星期少於一次","每星期有一、兩次","每星期三次以上"],       
        mode: ['radio'],
        score: [0,1,2,3]
    },{
        title:"16. 過去一個月以來，您是否曾在用餐、開車(坐車)或社交活動中打瞌睡難以保持清醒？",
        options:["從未發生","每星期少於一次","每星期有一、兩次","每星期三次以上"],       
        mode: ['radio'],
        score: [0,1,2,3]
    },{
        title:"17. 過去一個月以來，你有感到無心完成該做的事嗎？",
        options:["從未發生","每星期少於一次","每星期有一、兩次","每星期三次以上"],       
        mode: ['radio'],
        score: [0,1,2,3]
    },{
        title:"18. 總體而言，過去一個月，您覺得自己的睡眠品質如何？",
        options:["很好","還不錯","差了點","很差"],       
        mode: ['radio'],
        score: [0,1,2,3]
    },{
        title:"19. 您有睡眠伴侶或室友嗎？(有請繼續作答)",
        options:["有，同床","有，同房不同床 ","有，不同房","沒有"],       
        mode: ['radio'],
        score: [0,1,2,3],
        Additional:"有請繼續作答，請床伴進行填寫",
    },{
        title:"20. 大聲打鼾",
        options:["從未發生","每星期少於一次","每星期有一、兩次","每星期三次以上"],       
        mode: ['radio'],
        score: [0,1,2,3],
        condition_index: 18,
        condition_value: [0,1,2],
    },{
        title:"21. 入睡中出現一陣子呼吸中止的現象",
        options:["從未發生","每星期少於一次","每星期有一、兩次","每星期三次以上"],       
        mode: ['radio'],
        score: [0,1,2,3],
        condition_index: 18,
        condition_value: [0,1,2],
    },{
        title:"22. 入睡中出現腳部抽動或顫動的現象",
        options:["從未發生","每星期少於一次","每星期有一、兩次","每星期三次以上"],       
        mode: ['radio'],
        score: [0,1,2,3],
        condition_index: 18,
        condition_value: [0,1,2],
    },{
        title:"23. 夜間起來出現意識混亂或人、時、地分不清的現象",
        options:["從未發生","每星期少於一次","每星期有一、兩次","每星期三次以上"],       
        mode: ['radio'],
        score: [0,1,2,3],
        condition_index: 18,
        condition_value: [0,1,2],
    },{
        title:"24. 其他入睡中的躁動與不安的情形請試述",
        options:["從未發生","每星期少於一次","每星期有一、兩次","每星期三次以上"], 
        quantifier:[""],      
        mode: ['input',"radio"],
        score: [0,1,2,3],
        condition_index: 18,
        condition_value: [0,1,2],
    },
];

const SleepLogNgihtContent =
[
    {
        title:"1. 今天是否有運動? (若有，請列出運動開始的時間）(以 24 小時填寫)",
        mode: ['input'],
        quantifier:["時","分"],       
    },    {
        title:"2. 請列出運動結束的時間 (以 24 小時填寫)",
        mode: ['input'],
        quantifier:["時","分"],       
    },    {
        title:"3. 是否飲用咖啡因飲品?",
        options:["是","否"],       
        mode: ['radio'],
        score: [0,1]
    },    {
        title:"4. 是否飲用含酒精飲品?",
        options:["是","否"],       
        mode: ['radio'],
        score: [0,1]
    },    {
        title:"5. 白天嗜睡程度有幾分?(0-10分，0分不嗜睡，10分非常嗜睡)",
        range:[0,10],
        mode: ['slider'],
    },    {
        title:"6. 是否服用藥物?(若有，請填寫藥名)",
        mode: ['input'],
        quantifier:[""],       
    },
];

const SleepLogMoriningContent =
[
    {
        title:"1. 昨晚就寢的時間 (躺上床熄燈的時間）(以 24 小時填寫)",
        options:[],       
        mode: ['input'],
        quantifier:["時","分"],       
        score: [0,1,2,3,4]
    },    {
        title:"2. 今天起床的時間 (以 24 小時填寫)",
        options:[],       
        mode: ['input'],
        quantifier:["時","分"],       
        score: [0,1,2,3,4]
    },    {
        title:"3. 起床時清醒的程度如何？(1-7分)",
        range:[1,7],
        mode: ['slider'],
        Additional:
        "7.覺得很有活力、很警覺、很清醒。<br/>\
        6.活動的程度雖然沒有在巔峰，但仍保持在很好的水準，能集中注意力。<br/>\
        5.醒著，很放鬆，有反映、但並不完全警覺。<br/>\
        4.有一點模糊，想躺下來。<br/>\
        3.意識模糊，開始覺得無法集中注意，難以保持清醒。<br/>\
        2.想睡，想躺下來，感覺意識不清。<br/>\
        1.幾乎在作夢，不能保持清醒，似乎下一秒就會睡著了。",
    },    {
        title:"4. 昨晚睡眠品質有幾分?(0-10分，0分幾乎沒睡，10分最好)",
        range:[0,10],
        mode: ['slider'],
    },
];

const CreateRadio = ({response = {}, data, index, score, handleChange, mode }) => {
    const radio = data.map((item, radioIndex) => {
      return (
        <div className="SurveyRadioButton" key={radioIndex}>
          <input
            type="radio"
            id={`${index}-${radioIndex}-radio`}
            name={`radio-group-${index}`}
            value={score[radioIndex]}
            className="custom-radio"
            onChange={() => handleChange(index, {"radio":score[radioIndex]}, mode)}
            checked={response.radio === score[radioIndex]}
          />
          <label htmlFor={`${index}-${radioIndex}-radio`}>{item}</label>
        </div>
      );
    });
    return <>{radio}</>;
};

const CreateInput = ({response, data, index, handleChange, mode }) => {
    const input = data.map((item, inputIndex) => {
      return (
        <div className="SurveyInput" key={inputIndex}>
            <input 
                value={response !== undefined && response["input"] !== undefined? response["input"][inputIndex]: ""}
                id={`${index}-${inputIndex}-input`}
                onChange={(e) => handleChange(index, {"input": {[inputIndex]: e.target.value}}, mode)}
            />
            {item != "" && <div>{item}</div>}
        </div>
      );
    });
    return (
        <div className="SurveyInputCollect">
            {input}
        </div>
    )
};

const CreateSlider = ({response, data, index, handleChange, mode, nowLevel }) =>{
      return (
          <div className="SurveyInputCollect">
                <Slider 
                    sx={{width:"90%",marginRight:"5px"}} 
                    value={response === undefined ? data[0]:response["slider"]} 
                    min={data[0]} 
                    max={data[1]} 
                    step={1} 
                    onChange={(e) => handleChange(index, {"slider": e.target.value}, mode)}
                />              
                <div>{nowLevel}</div>
          </div>
      )
}

const CreateAdditional = ({data}) =>{
    const [open, setOpen] = React.useState(false);

    const handleClose = () =>{
        setOpen(false);
    }
    return(
        <>
            <HelpIcon sx={{marginLeft:"3px"}} onClick={()=>setOpen(true)}/>
            <Dialog 
                open={open} 
                onClose={handleClose}
            >
                <DialogTitle>解釋</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <span dangerouslySetInnerHTML={{ __html: data }} />    
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>完成</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

const CreateContent = ({data, responses, setResponses, handleSubmit, Content}) => {


    const handleChange = (questionIndex, selectedOption, mode) => {
        // 创建新的回答数组
        const newResponses = [...responses];
        // 获取当前问题的现有回答
        const currentResponse = newResponses[questionIndex];

        // 检查现有回答是否存在
        if (!currentResponse) {
            // 如果不存在，则直接将新的选项设置为回答
            newResponses[questionIndex] = { ...selectedOption };
        } else {
            // 如果存在，则根据输入模式进行更新
            let updatedResponse;
            if (mode === "input") {
                // 如果是 input 模式，则将新的输入选项合并到 input 对象中
                updatedResponse = {
                    ...currentResponse,
                    input: { ...currentResponse.input, ...selectedOption.input }
                };
            } else {
                // 如果不是 input 模式，则直接合并选项
                updatedResponse = { ...currentResponse, ...selectedOption };
            }
            // 更新回答数组
            newResponses[questionIndex] = updatedResponse;
        }
        // 使用更新后的回答数组来设置新的回答状态
        setResponses(newResponses);
    };

    useEffect(() => {
        if (data !== null && data.length > 0) {
            setResponses(data);
        }
    }, [data, setResponses]);

    const isEdit = (data === null);
    
    const shouldShowQuestion = (item) => {
        if (item.condition_index === undefined) {
            return true;
        } else {
            const conditionIndex = item.condition_index;
            const conditionValue = item.condition_value;
            return responses[conditionIndex] !== undefined && conditionValue.includes(responses[conditionIndex].radio);
        }
    };
    
    const nowLevelFunction = (index) =>{
        if (responses[index] !== undefined && Object.keys(responses[index]).includes("slider")){
            return responses[index]["slider"]
        }else{
            return 0
        }
    };

    return(
        <form className="SurveyFrom">
            {Content.map((item,index)=>(
                shouldShowQuestion(item) && (
                <>
                        <fieldset key={index}>
                            <legend className="formTitle">
                                {item.title}
                                {item.Additional !== undefined && <CreateAdditional data={item.Additional}/>}
                            </legend>
                            
                            <div className="SurveyCollect">
                                {item.mode.includes("input")  && <CreateInput response={responses[index]}  data = {item.quantifier} index={index} handleChange={handleChange} mode={"input"}/>}
                                {item.mode.includes("radio")  && <CreateRadio response={responses[index]} data = {item.options} index={index} score={item.score} handleChange={handleChange} mode={"radio"}/>}
                                {item.mode.includes("slider")  && <CreateSlider response={responses[index]} data = {item.range} index={index} handleChange={handleChange} mode={"slider"} nowLevel={nowLevelFunction(index)}/>}
                            </div>
                        </fieldset>
                    </>
                )
            ))}
            <div className="Center">
                <button type="submit" className="buttonSubmit" onClick={handleSubmit}>{isEdit?"提交":"儲存"}</button>
            </div>
        </form>

    )
}

const PSQI = ({data, responses, setResponses, handleSubmit}) => {
    return(
        <>
            <CreateContent data={data} responses={responses} setResponses={setResponses} handleSubmit={handleSubmit} Content={PSQIContent}/>
        </>
    );
}

const PSS = ({data, responses, setResponses, handleSubmit}) => {
    return(
        <>
            <h4>請回想最近一個月來，發生下列各狀況的頻率。</h4>
            <CreateContent data={data} responses={responses} setResponses={setResponses} handleSubmit={handleSubmit} Content={PSSContent}/>
        </>
    );

}

const STOPBang = ({data, responses, setResponses, handleSubmit}) => {
    return(
        <>
            <CreateContent data={data} responses={responses} setResponses={setResponses} handleSubmit={handleSubmit} Content={STOPBangContent}/>
        </>
    );

}

const SleepLogNight = ({data, responses, setResponses, handleSubmit}) => {
    return(
        <>
            <CreateContent data={data} responses={responses} setResponses={setResponses} handleSubmit={handleSubmit} Content={SleepLogNgihtContent}/>   
        </>
    );

}

const SleepLogMorning = ({data, responses, setResponses, handleSubmit}) => {
    return(
        <>
            <CreateContent data={data} responses={responses} setResponses={setResponses} handleSubmit={handleSubmit} Content={SleepLogMoriningContent}/>  
        </>
    );
    
}

function SurveyContentPage(){
    const dispatch = useDispatch();
    const Data = useSelector(state => state.collectState.surveyData);
    const surveyID = Data.id;
    const type = Data.type;

    const [responses, setResponses] = React.useState([]);
    const [surveyData, setSurveyData] = React.useState(null);
    const [decodeJWT, setDecodeJWT] = React.useState({});

    useEffect(()=>{
        apiTools.decodeJWT().then((result)=>{
            setDecodeJWT(result);
        })
    },[]);

    const handleSubmit = async (e) => {
        // 用於阻止事件的默認行為
        e.preventDefault();
        // 在这里处理表单提交逻辑，例如发送数据到服务器
        const caluResult = () =>{
            const caluSTOP = () => {
                let yesCount = responses.filter(x => x.radio === 1).length;

                // 根據「是」的數量判斷風險
                let riskLevel = "";
                if (yesCount >= 0 && yesCount <= 2) {
                    riskLevel = "低度風險";
                } else if (yesCount >= 3 && yesCount <= 4) {
                    riskLevel = "中度風險";
                } else if (yesCount >= 5 && yesCount <= 8) {
                    riskLevel = "高度風險";
                }
            
                // 進一步判斷其他條件
                if (responses.slice(0, 4).filter(answer => answer.radio === 1).length >= 2) {
                    if (responses[7].radio === 1 || (responses[4].radio === 1) || (responses[6].radio === 1)) {
                        riskLevel = "高度風險";
                    }
                }
            
                return {"result":riskLevel, "score": yesCount};
            }

            const caluPSS = () => {
                let result = 0;
                responses.map((item)=>{
                    result += item.radio;
                })
                if (result <= 28){
                    return {"result":"壓力屬正常範圍","score":result};
                } else if (result <= 42){
                    return {"result":"壓力偏大，需注意","score":result};
                }else{
                    return {"result":"壓力太大，需尋求資源協助","score":result};
                }
            }

            const caluPSQI = () => {
                const factor1 = () =>{
                    const temp = parseInt(responses[3]['input'][0]);
                    if (temp >= 7){
                        return 0;
                    } else if (temp === 6){
                        return 1;
                    } else if (temp === 5){
                        return 2;
                    } else {
                        return 3;
                    }
                }

                const factor2 = () =>{
                    var temp = 0;
                    for (var i = 5; i<= 13;i++){
                        if (responses[i] !== undefined && responses[i]['radio']){
                            temp += responses[i]['radio']
                        }
                    }
                    if (temp === 0){
                        return 0;
                    } else if (temp <= 9){
                        return 1;
                    } else if (temp <= 18){
                        return 2;
                    } else {
                        return 3;
                    }
                }

                const factor3 = () =>{
                    const ta = responses[4]['radio'];

                    const tb = () => {
                        const time = parseInt(responses[1]["input"][0]) * 60 + parseInt(responses[1]['input'][1]);
                        if (time <= 15){
                            return 0;
                        } else if (time <= 30){
                            return 1;
                        } else if (time <= 60){
                            return 2;
                        } else {
                            return 3;
                        }
                    };

                    const total = ta + tb();
                    if (total === 0) {
                        return 0;
                    } else if (total <= 2){
                        return 1;
                    } else if (total <= 4){
                        return 2;
                    } else {
                        return 3;
                    }
                }

                const factor4 = () =>{
                    const temp = responses[15]['radio'] + responses[16]['radio'];
                    if (temp === 0){
                        return 0;
                    } else if (temp <= 2){
                        return 1;
                    } else if (temp <= 4){
                        return 2;
                    }else {
                        return 3;
                    }
                }

                const factor5 = () =>{
                    const tma = parseInt(responses[0]["input"][0]) + parseInt(responses[1]["input"][0]) + parseInt(responses[2]["input"][0]);
                    const tmb = parseInt(responses[0]["input"][1]) + parseInt(responses[1]["input"][1]) + parseInt(responses[2]["input"][1]);
                    const tsa = parseInt(responses[3]["input"][0]);
                    const tsb = parseInt(responses[3]["input"][1]);
                    const realTime = (tmb * 60 + tsb )/ (tma *60 + tsa);
                    if (realTime >= 0.85){
                        return 0
                    } else if(realTime >= 0.75){
                        return 1
                    } else if(realTime >= 0.65){
                        return 2
                    }else {
                        return 3
                    }

                }

                const factor6 = () =>{
                    return responses[13]["radio"];
                }

                const factor7 = () =>{
                    return responses[14]["radio"];
                }

                const temp = factor1() + factor2() + factor3() + factor4() + factor5() + factor6() + factor7();
                if (temp <= 5){
                    return {"result":"睡眠品質良好","score":temp};
                }else {
                    return {"result":"睡眠品質不良","score":temp};
                }

            }

            switch(type){
                case "STOP_Bang":
                    return caluSTOP();
                case "PSS":
                    return caluPSS();
                case "PSQI":
                    return caluPSQI();
                case "Sleep_Log_Morning":
                    return {
                        "start": parseFloat(responses[0]["input"]["0"])+(parseFloat(responses[0]["input"]["1"])/60),
                        "end": parseFloat(responses[1]["input"]["0"])+(parseFloat(responses[1]["input"]["1"])/60),
                        "wide awake": responses[2]["slider"],
                        "quality":responses[3]["slider"],
                    };
                case "Sleep_Log_Night":
                    return {
                        "start": [parseFloat(responses[0]["input"]["0"])+(parseFloat(responses[0]["input"]["1"])/60)],
                        "end": [parseFloat(responses[1]["input"]["0"])+(parseFloat(responses[1]["input"]["1"])/60)],
                        "lethargy":responses[4]["slider"],
                    };
                default:
                    return ""
            }
        }
        const dic = {
            "user_id": decodeJWT["id"],
            "survey_type": type,
            "content": responses,
            "result": caluResult(),
            fill_date: moment(Data.date).format("YYYY-MM-DD HH:mm:ss"),
        }
        var response;
        if (surveyData === null){
            response= await apiTools.postData("survey/addSurvey",dic);
        } else {
            console.log(surveyID);
            console.log(dic);
            response = await apiTools.getResponseByPut("survey/updateSurvey",dic,surveyID);
        }
        if (response){
            dispatch(setSubPage("survey"));
        }else{
            alert("請填寫選項");
        }
    };


    useEffect(()=>{
        if (surveyID !== ""){
            const getData = async () =>{
                const dic = {"id":surveyID};
                const response = await (await apiTools.getResponseByGet("/survey/getSurveyById",dic) ).json();
                setSurveyData(response[0].content);
            };
            getData();
        }
    },[]);

    if (type === "Sleep_Log_Morning"){
        if (responses == []){ setResponses(Array(SleepLogMoriningContent.length).fill(null));}
        return <SleepLogMorning responses={responses} setResponses={setResponses} handleSubmit={handleSubmit} data={surveyData}/>
    }else if(type === "Sleep_Log_Night"){
        if (responses == []){ setResponses(Array(SleepLogNgihtContent.length).fill(null));}
        return <SleepLogNight responses={responses} setResponses={setResponses} handleSubmit={handleSubmit} data={surveyData}/>
    }else if(type === "STOP_Bang"){
        if (responses == []){ setResponses(Array(STOPBangContent.length).fill(null));}
        return <STOPBang responses={responses} setResponses={setResponses} handleSubmit={handleSubmit} data={surveyData}/>
    }else if(type === "PSS"){
        if (responses == []){ setResponses(Array(PSSContent.length).fill(null));}
        return <PSS responses={responses} setResponses={setResponses} handleSubmit={handleSubmit} data={surveyData}/>
    }else if(type === "PSQI"){
        if (responses == []){ setResponses(Array(PSQIContent.length).fill(null));}
        return <PSQI responses={responses} setResponses={setResponses} handleSubmit={handleSubmit} data={surveyData}/>
    }
}

export default SurveyContentPage;
