import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setBottomPage, setBottomSheetOpen } from '../App/activeBottomPage';

import Drawer from './components/Drawer/Drawer';
// 各頁面引入
import Sidebar from './components/Sidebar/Sidebar';
import CalendarPage from './SubPage/CalendarPage/CalendarPage';
import AnalyticsPage from './SubPage/AnalyticsPage/AnalyticsPage';
import TrainingTemplatePage from './SubPage/TrainingTemplatePage/TrainingTemplatePage';
import ContestPage from './SubPage/ContestPage/ContestPage';
import BottomNavBar from './components/BottomNavBar/BottomNavBar';
import AddTimeTablePage from './SubPage/AddTimeTablePage/AddTimeTablePage';
import AddLifeEventPage from './SubPage/AddLifeEventPage/AddLifeEventPage';
import AddRaceEventPage from './SubPage/AddRaceEventPage/AddRaceEventPage';
import DetailTrainPage from './SubPage/DetailTrainPage/DetailTrainPage';
import DetailLivePage from './SubPage/DetailLivePage/DetailLivePage';
import DetailRacePage from './SubPage/DetailRacePage/DetailRacePage';
import TrainingDetailPage from "./SubPage/TrainingDetailPage/TrainingDetailPage";
import DetailContestPage from "./SubPage/DetailContestPage/DetailContestPage";
import SettingPage from "./SubPage/SettingPage/SettingPage";
import CoachConnectPage from "./SubPage/CoachConnectPage/CoachConnectPage";
import PersonalSettingPage from './SubPage/PersonalSettingPage/PersonalSettingPage';
import SurveyContentPage from './SubPage/SurveyContentPage/SurveyContentPage';
// icon引入
import MenuIcon from '@mui/icons-material/Menu';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import apiTools from '../apiTools';
import SurveyPage from './SubPage/SurveyPage/SurveyPage';
function MainPage() {

    const activeSubPage = useSelector(state => state.activeSubPage.subPage);  // Access the current subPage state 
    const bottomPage = useSelector(state => state.activeBottomPage.bottomPage);
    const isBottomSheetOpen = useSelector(state => state.activeBottomPage.open);
   
    // 表示初始化設定
    // 前面為設定的參數，後面為要變動參數時所用的函式
    const [isJWTWait, setIsJWTWait] = React.useState(false);
    const [shouldRender, setShouldRender] = React.useState(true);
    const [isSidebarOpen, setSidebarOpen] = React.useState(false);
    const [selectData,setSelectData] = React.useState({});
    const [dailyID,setDailyID] = React.useState();
    const [findText, setFindText] = React.useState();
    const [trainingBlockID, setTrainingBlockID] = React.useState();
    const [trainingBlockData, setTrainingBlockData] = React.useState();

    const dispatch = useDispatch();

    const sideBarRef = React.useRef();
    const openSideBarRef = React.useRef();

    const setInit = () =>{
        dispatch(setBottomPage(""));
        dispatch(setBottomSheetOpen(false));
    }
    useEffect(() => {
        // 在这里进行JWT检查
        async function check(){
            const isJwtValid = await apiTools.checkJWT(); // 假设这是检查JWT是否有效的函数
            // 根据JWT的检查结果设置状态
            if (isJwtValid){
                setIsJWTWait(true);
            }else{
                window.location.href = "/";
            }
        }
        check();
    }, []);
    
    useEffect(() => {
        const body = document.body;
        if (isBottomSheetOpen) {
            body.style.overflow = 'hidden'; // 禁止背景滾動
        } else {
            body.style.overflow = 'auto'; // 啟用背景滾動
        }   
    }, [isBottomSheetOpen]);

    useEffect(() => {
        const handleClickOutside = (event) => {
        // 檢查點擊事件的目標是否不是div元素或它的子元素且目前sidebar為開啟的
        if (sideBarRef.current && !sideBarRef.current.contains(event.target) && isSidebarOpen) {
            handleSidebarToggle();
            }
        };

    // 添加點擊事件監聽器
        document.addEventListener('click', handleClickOutside);

    // 清理函數，用於在組件卸載前移除點擊事件監聽器
        return () => {
        document.removeEventListener('click', handleClickOutside);
        };
    });

    useEffect(() => {
        const handleClickInside = (event) => {
        // 檢查點擊事件的目標是否是button元素或它的子元素且目前sidebar為關閉的
        if (openSideBarRef.current && openSideBarRef.current.contains(event.target) && !isSidebarOpen) {
            handleSidebarToggle();
            }
        };

    // 添加點擊事件監聽器
        document.addEventListener('click', handleClickInside);

    // 清理函數，用於在組件卸載前移除點擊事件監聽器
        return () => {
        document.removeEventListener('click', handleClickInside);
        };
    });

    useEffect(() => {
        switch (bottomPage) {
            case 'addTimeTable':       
                dispatch(setBottomSheetOpen(true));
                break;
            case 'addLifeEvent':
                dispatch(setBottomSheetOpen(true));
                break;
            case 'addRaceEvent':
                dispatch(setBottomSheetOpen(true));
                break;
            case 'DetailTrainPage':
                dispatch(setBottomSheetOpen(true));
                break;
            case 'DetailLivePage':
                dispatch(setBottomSheetOpen(true));
                break;
            case 'DetailRacePage':
                dispatch(setBottomSheetOpen(true));
                break;
            default:           
                dispatch(setBottomSheetOpen(false));
                break;
        }
    }, [bottomPage]);

    useEffect(() => {
        setShouldRender(true);
    }, [shouldRender]);

    function handleSidebarToggle() {
        setSidebarOpen(!isSidebarOpen);
    }


    function renderBottomPage(){
        switch (bottomPage) {
            case 'addTimeTable':
                return <AddTimeTablePage  setShouldRender={setShouldRender} />;
            case 'addLifeEvent':
                return <AddLifeEventPage  setShouldRender={setShouldRender}/>;
            case 'addRaceEvent':
                return <AddRaceEventPage  setShouldRender={setShouldRender}/>;
            case 'DetailTrainPage':
                return <DetailTrainPage  selectData={selectData} dailyID = {dailyID}/>;
            case 'DetailLivePage':
                return <DetailLivePage  selectData={selectData} dailyID = {dailyID}/>;
            case 'DetailRacePage':
                return <DetailRacePage  selectData={selectData} dailyID = {dailyID}/>; 
            default:
                return null;
        }

    }

    function renderSubPage() {
        switch (activeSubPage) {
            case 'calendar':
                return <CalendarPage setSelectData = {setSelectData} setDailyID = {setDailyID} shouldRender={shouldRender} setShouldRender={setShouldRender}/>;
            case 'analytics':
                return <AnalyticsPage />;
            case 'contest':
                return <ContestPage  />;
            case 'trainingTemplate':
                return <TrainingTemplatePage   setTrainingBlockID={setTrainingBlockID} setTrainingBlockData={setTrainingBlockData} findText={findText}/>;
            case 'trainingDetail':
                return <TrainingDetailPage  blockID={trainingBlockID} trainingBlockData={trainingBlockData}/>;
            case 'detailContest':
                return <DetailContestPage  />;
            case 'setting':
                return <SettingPage />
            case  'coachConnect':
                return <CoachConnectPage data={findText}  />
            case 'personalSetting':
                return <PersonalSettingPage />
            case 'survey':
                return <SurveyPage/>
            case 'surveycontent':
                return <SurveyContentPage/>
            default:
                return null;
        }
    }

    function subPageTitle(){
        switch (activeSubPage) {
            case "setting":
                return "設定";
            case 'personalSetting':
                return "基本資料設定";
            default:
                return "";
        }
    }

    function visibleFind(){
        if (activeSubPage === 'trainingTemplate'){
            return true
        }else if (activeSubPage === "score"){
            return true
        }else if (activeSubPage === "coachConnect"){
            return true
        }
        return false
    }
    
    return (
        <> 
            {isJWTWait && <>
            <div  className='topToolBar'>
                 {isSidebarOpen && <div className='blackBackground'></div>}
                <div className='topToolBarNoHiden' >
                    <div className='menuButton'>
                        <button ref = {openSideBarRef}>
                            <MenuIcon sx={{marginRight:"5px"}}/>
                            <div className='subPageTitle'>{subPageTitle()}</div>
                        </button>
                    </div>
                {
                visibleFind() && 
                <>
                    <div className='findText' style={{  visibility: "visible"}}>
                        <input value={findText} onChange={(e) => { setFindText(e.target.value); }}/>
                    </div>
                    <div className='findButton' style={{ visibility: "visible"}}>
                        <button><SearchRoundedIcon sx={{ color: "white" }} />
                        </button>
                    </div>
                </>
                }    
                </div>
                <div ref = {sideBarRef} >
                    {<Sidebar isOpen= {isSidebarOpen} setSidebarOpen={setSidebarOpen}/>}
                </div>     
            </div>

            {isBottomSheetOpen && 
                <Drawer isOpen={isBottomSheetOpen} onClose={() => setInit()}>
                    {renderBottomPage()}
                </Drawer>
            }
            
            <div className='content'>{renderSubPage()}</div>
            <div className='bottomContainer'><BottomNavBar/></div>
            </>}
        </>
    );
}

export default MainPage;