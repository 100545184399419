import React, { useEffect } from 'react';
import CalendarNav from "../../components/CalendarNav/CalendarNav";

import Content from './Content';
import './CalendarPage.css';
import apiTools from '../../../apiTools';

import profile from '../../../images/user.png';



function CalendarPage(props){
    const moment = require('moment');

    const [selectedDate, setSelectedDate] = React.useState(new Date());
    const [startDate, setStartDate] = React.useState(new Date());
    const [endDate, setEndDate] = React.useState(new Date());
    const [selectedStudent, setSelectedStudent] = React.useState();
    const [clipsEvents, setClipsEvents] = React.useState([]);
    const [studentData, setStudentData] = React.useState();
    

    const setSelectData = (data) => {props.setSelectData(data)};
    const setDailyID = (ID) => {props.setDailyID(ID)};

    const [decodeJWT, setDecodeJWT] = React.useState({});

    useEffect(() =>{
        apiTools.decodeJWT().then((value)=>{
            setDecodeJWT(value);
        });
    },[]);

    const colors ={
      "比賽":"#FBDDD1",
      "考試":"#FCE3CB",
      "非規律進食":"#FFEFBF",
      "非規律睡眠":"#C4DC9C",
      "生理不適":"#89C879",
      "心理壓力":"#A9E4EF",
      "天氣":"#B9D2EE",
      "生理期":"#C8BFED",
      "非規律練習":"#ACA399",
      "其他":"#979797"
    };

    const handleDateRange = (start, end) =>{
      setStartDate(start);
      setEndDate(end);
    }

    const handleSelect = (args) =>{
      setSelectedDate(args);
    };

    function setContent(){
      return <Content selectedDate ={selectedDate} setSelectData={setSelectData} setDailyID = {setDailyID} selecetedStudent={selectedStudent} setShouldRender={props.setShouldRender}/>
    };

    function handleSelectStudent(ID){
      setSelectedStudent(ID); 
    }

    useEffect(() => {
      const getData = async () =>{
        var dic = {"id":decodeJWT["id"]};
        var response = await (await apiTools.getResponseByGet("/user/getFriendById",dic)).json();
        setStudentData(response.map((item) =>{
          const src = item.photo === "" ? profile : item.photo;
          return(
            <>
              <div className="Student" onClick={() => handleSelectStudent(item.student_id)}>
                <div className= "StudentIcon">
                  <img 
                    className={selectedStudent == item.student_id? "Select": ""} 
                    src={src} 
                    alt="" 
                    srcSet="" 
                  />
                </div>
                <div className="studentName">{item.name}</div>
              </div>
            </>
        )}));
      }

      getData();

    },[decodeJWT,props.shouldRender,selectedStudent]);

    useEffect(()=>{
      const events = async() =>{
        try{
          var dic = {
            "start_date": moment(startDate).format("YYYY-MM-DD"),
            "end_date": moment(endDate).format("YYYY-MM-DD"),
            "student_id":decodeJWT['identity'] === 'coach'? selectedStudent: decodeJWT["id"]
          };
          const response = await(await apiTools.getResponseByGet("/event/getALLEventByDate",dic)).json();
          // 將Events切成日期內都有點      
          const clipEvent = (events) => {
            return events.map((event) => {
                const ans = [];
                let start = moment(event.start_date,"YYYY-MM-DD");
                let end = moment(event.end_date,"YYYY-MM-DD");
                var color = colors[event.type];
                for (let currentDate = start; currentDate.isSameOrBefore(end); currentDate.add(1, 'days')) {
                    ans.push({
                        date: currentDate.format(),
                        color: color,
                    });
                };
                return ans
            });
          };
          const clip = [];
          var result = clipEvent(response.live_event)[0];
          if (  result !== undefined){
           result.map((event)=>{clip.push(event)});
          }
          result = clipEvent(response.race_event)[0];
          if (  result !== undefined){
            result.map((event)=>{clip.push(event)});
           }
          setClipsEvents(clip);

        }catch (error) {
          console.error('Error fetching data:', error);
        }
      };
      events();
    },[startDate, endDate,selectedStudent,decodeJWT,props.shouldRender]);


    return (
      <>
        {props.shouldRender && 
          <>
            <div>
              <CalendarNav
                dateClick={handleSelect}
                events={clipsEvents}
                handleDateRange = {handleDateRange}
              />
            </div>       
            {decodeJWT['identity'] === 'coach'  && 
              <div className='StudentMenu'>
                {studentData}
              </div> 
            }     
            {setContent()}
          </>
        }
      </> 
    );
}

export default CalendarPage