import React from 'react';
import './TimelineEvent.css';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { setSubPage } from '../../../App/activeSubPage';
import { setSurveyData } from '../../../App/collectState';

function TimelineEvent({ event, date }) {
  const dispatch = useDispatch();
  const today = moment(new Date()).format('YYYY-MM-DD');
  const surveyDay = moment(event.Date).format('YYYY-MM-DD');

  // Function to handle setting the ID and type for the survey data
  const handleSetID = (id, type) => {
    dispatch(setSurveyData({ id, type, date }));
    dispatch(setSubPage('surveycontent'));
  };

  const isEdit = (event.id === '');
  const canEdit = (!isEdit || today === surveyDay);

  let result = '';
  let eventColor = '';
  if (event.id !== "") {
    switch (event.type) {
      case 'STOP_Bang':
        result = `分析結果 ${event.score.result}`;
        if (event.score.result === '低度風險') {
          eventColor = 'green';
        } else if (event.score.result === '中度風險') {
          eventColor = 'orange';
        } else {
          eventColor = 'red';
        }
        break;
      case 'PSS':
        result = `分析結果 ${event.score.result} (總分 ${event.score.score} 分)`;
        eventColor = parseInt(event.score.score) > 28 ? 'red' : 'green';
        break;
      case 'PSQI':
        result = `分析結果 ${event.score.result} (總分 ${event.score.score} 分)`;
        eventColor = parseInt(event.score.score) > 5 ? 'red' : 'green';
        break;
      case 'Sleep_Log_Morning':
        result = '已完成填寫';
        eventColor = 'green';
        break;
      case 'Sleep_Log_Night':
        result = '已完成填寫';
        eventColor = 'green';
        break;
      default:
        eventColor = '';
        break;
    }
  } else {
    result = '未完成填寫';
    eventColor = 'red';
  }

  return (
    // Conditionally apply handleSetID function on click
    <div onClick={canEdit ? () => handleSetID(event.id, event.type) : null} className='timeline'>
      <div>
        <div className='timeline_circle'></div>
        <div className='timeline_line'></div>
      </div>
      <div className="timeline_event">
        <div>
          <h3 className="timeline_header">{event.title}</h3>
          <p className={`timeline_body ${isEdit ? '' : 'black bold'}`}>{event.description}</p>
          <p className={`timeline_body ${isEdit ? '' : 'black bold'} ${eventColor ? eventColor : ''}`}>{result}</p>
        </div>
        <div>
          <div className="timeline_date">{event.Date}</div>
        </div>
      </div>
    </div>
  );
}

function Timeline({ events, date }) {
  return (
    <div className="timeline-container">
      {events.map((event, index) => (
        <TimelineEvent key={index} event={event} date={date} />
      ))}
    </div>
  );
}

export default Timeline;
