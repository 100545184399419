import React from "react";
import profile from '../../../images/user.png';

import "./ConnectCard.css";
import apiTools from "../../../apiTools";
function ConnectCard(props){
    const data = props.data;
    const src = data.photo === "" ? profile : data.photo;

    const deleteConnect = async () =>{
        const dic = {"link_id":data.id};
        var response = await apiTools.getResponseByDelete("/user/delFriendById",dic);
        props.handleReload();
    }

    return(
        <>
            <div className="ConnectCard">
                <div>
                    <img alt={"大頭照"} style={{borderRadius:"50%",width:"80px", height : "80px"}} src = {src}/>
                </div>
                <div>
                    <div>{data.name}</div>
                    <div>{data.account}</div>
                    <div>代表單位 XXXX</div>
                </div>
                <div className="ConnectButton">
                    <button onClick={deleteConnect}>移除選手</button>
                </div>
            </div>
        </>
    );
}

export default ConnectCard;