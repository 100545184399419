import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setBottomPage } from '../../../App/activeBottomPage';
import TripOriginOutlinedIcon from '@mui/icons-material/TripOriginOutlined';
import apiTools from '../../../apiTools';

import DetailRaceBlock from '../../components/DetailRaceBlock/DetailRaceBlock';

import moment from "moment";


function DetailRacePage(props){
    
const dispatch = useDispatch();
    const ID = props.dailyID;
    const [name, setName] = React.useState();
    const [startDate, setStartDate] = React.useState();
    const [endData, setEndDate] = React.useState();
    const [memo, setMemo] = React.useState();
    const [raceObject, setRaceObject] = React.useState([]);
    const [ListSubPage, setListSubPage] = React.useState();
    const [raceObjectData, setRaceObjectData] = React.useState([]);

    const raceObjectRef = React.useRef({});

    const [currentListSubPage, setCurrentListSubPage] = React.useState(null);
    const [preListSubPage, setPreListSubPage] = React.useState(null);

    const [decodeJWT, setDecodeJWT] = React.useState({});
    const setInit = () =>{
        dispatch(setBottomPage(""));
    }
    useEffect(() =>{
        apiTools.decodeJWT().then((value)=>{
            setDecodeJWT(value);
        });
    },[]);
    
    async function handleSubmitButton(){
        const body = {
            "user_id":decodeJWT["id"],
            "start_date":startDate,
            "end_date":endData,
            "name":name,
            "memo":memo,
            "race_object":raceObjectData.map((item)=>{
                return({
                    "object_name":item.object_name,
                    "date":item.date,
                    // "race_time": item.race_time ? item.race_time.join(":") : "",
                    "score": item.score ? item.score.join(":") : "", 
                    "rank":item.rank
                });
            })
        };
        const response = await apiTools.getResponseByPut("event/updateRaceEvent",body,ID);
        if (response.ok){
            setInit();
        }else{
            alert("請確定網路");            
        }
    }

    function handleClickListButton(args){
        setCurrentListSubPage((currentListSubPage) => {
            // 在这里可以执行一些额外的处理
            setPreListSubPage(currentListSubPage);
            // 返回新的状态值
            return args === currentListSubPage ? null : args;
          });
    }

    function handleInputChange(index, data){
        setRaceObjectData((prev) => {
            const newData = [...prev];
            newData[index] = data;
            return newData;
        });
    }

    useEffect(() => {
        const listItem = raceObjectRef.current[currentListSubPage];
        if (listItem) {
            if (listItem.current) {
                listItem.current.className = "ListOpen";
              }
        }
    },[currentListSubPage]);

    useEffect(() => {
        const listItem = raceObjectRef.current[preListSubPage];
        if (listItem) {
            if (listItem.current) {
                listItem.current.className = "ListClose";
              }
        }
    },[preListSubPage]);

    useEffect(()=>{
        const event = async () =>{
            const response = await (await apiTools.getResponseByGet("/event/getRaceEventById",{"id":ID})).json();
            setName(response.name);
            setStartDate(moment(response.start_date).format("YYYY.MM.DD"));
            setEndDate(moment(response.end_date).format("YYYY.MM.DD"));
            setMemo(response.memo);
            // 將資料給格式化
            const raceObjects = response.race_object.map((item) => {
                const score = ["00", "00", "00"];

                if (item.score) {
                    const [hours, minutesSeconds] = item.score.split(":");
                    const [minutes, seconds] = minutesSeconds.split(".");

                    score[0] = hours;
                    score[1] = minutes || "00";
                    score[2] = seconds || "00";
                }

                // const raceTime = item.race_time.split(":");
                return {
                    id: item.id,
                    score: score,
                    // race_time: raceTime,
                    rank: item.rank,
                    object_name: item.object_name,
                    date : item.date
                };
            });
            setRaceObjectData(raceObjects);

            const race = raceObjects.map((item, index) => {
                raceObjectRef.current[item.id] = React.createRef();
                return (
                    <React.Fragment key={item.id}>
                        <div className="ListButton race_object">
                            <div className="ListTitle" onClick={() => { handleClickListButton(item.id) }}>
                                <div className="ListIcon"><TripOriginOutlinedIcon /></div>
                                <div>{item.object_name}</div>
                            </div>
                            <div ref={raceObjectRef.current[item.id]} className="ListClose">
                                <DetailRaceBlock data={item} handleInputChange={handleInputChange} index={index}/>
                            </div>
                        </div>
                    </React.Fragment>
                );
            });
            
            setRaceObject(race)
        };
        event();
    },[]);


    return (
        <>
             <div className='bottomSheetTamplate'>
                <div className="ContentRow">
                    <input type="text" className="PageTitle" value={name} onChange={(e) => setName(e.target.value)}/>
                </div>
                <div className='ContentRow'>
                    <div className='Left'>日期</div>
                    <div className='Right'>{startDate} - {endData}</div>
                </div>

                <div className="ContentRow">
                    <div className="Left">
                        <div className="LeftRight">比賽項目</div>
                    </div>
                </div>

                <div className="ListContent">
                    <ul style={{listStyle :"none",paddingLeft:"5%"}}>
                        {raceObject}
                    </ul>
                </div>

                <div className="buttonSubmitArea ContentRow"><button className="buttonSubmit" type="submit" onClick={handleSubmitButton} >儲存</button></div>
            </div>
        </>

    )
}

export default DetailRacePage