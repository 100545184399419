import React, { useEffect } from "react";
import ConnectCard  from "../../components/ConnectCard/ConnectCard";
import ConnectGroupCard from "../../components/ConnectGroupCard/ConnectGroupCard";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

import "./CoachConnectPage.css";

import apiTools from '../../../apiTools';

function CoachConnectPage(props){
    const [decodeJWT, setDecodeJWT] = React.useState({});

    const [studentOpen, setStudentOpen] = React.useState(false);
    const [groupOpen, setGroupOpen] = React.useState(false);

    const [userID, setUserID] = React.useState();
    const [groupName, setGroupName] = React.useState("");

    const [connectCard, setConnectCard] = React.useState();
    const [groupCard, setGroupCard] = React.useState();

    const [reload, setReload] = React.useState(false);

    const handleReload = () =>{
        setReload(!reload);
    }

    const handleStudentOpen = () =>{
        setStudentOpen(true);
    }

    const handleStudentColse = () => {
        setStudentOpen(false);
    }
    
    const handleGroupOpen = () =>{
        setGroupOpen(true);
    }

    const handleGroupColse = () => {
        setGroupOpen(false);
    }

    async function handleAddStudent(){
        var dic = {
            "coach_id":decodeJWT["id"],
            "student_account":userID
        };
        console.log(dic);
        var response = await apiTools.postData("/user/addFriend",dic);
        if(! response){
            alert("查無此人!!");
        }            
        handleReload();
        setUserID("");
        handleStudentColse();

    }

    async function handleAddGroup(){
        var dic = {
            "name":groupName,
            "coach_id":decodeJWT["id"],
            "student_id": [],
        };

        var response = await apiTools.postData("/user/createGroup",dic);

        if (!response){
            alert("新增失敗");
        } 
        handleReload();
        setGroupName("");
        handleGroupColse();
    }
   
    useEffect(()=>{
        const getData = async () =>{
            var dic = {"id":decodeJWT["id"]};
            var response = await (await apiTools.getResponseByGet("/user/getFriendById",dic)).json();
            setConnectCard(response.map((item) =>{
                return(<>
                    <ConnectCard data={item} handleReload={handleReload}/>
                </>);
            }));
        }
        getData();
    },[decodeJWT,reload]);

    useEffect(() => {
        const getData = async () => {
            var dic = {"coach_id": decodeJWT["id"]};
            var response = await( (await apiTools.getResponseByGet("user/getGroupByCoachId",dic)).json());
            console.log(response);
            setGroupCard(response.map((item) => {
                return(
                    <>
                        <ConnectGroupCard data={item} reload={reload} handleReload={handleReload}/>
                    </>
                );
            }));
        };
        getData();
    },[decodeJWT, reload]);

    useEffect(() =>{
        apiTools.decodeJWT().then((value)=>{
            setDecodeJWT(value);
        });
    },[]);

    

    return(
        <>
        <div>
            <div >
                <div>團隊管理</div>

                <button className="SubmitButton" onClick={handleGroupOpen}>新增群組</button>

                <Dialog 
                    open={groupOpen} 
                    onClose={handleGroupColse}
                >
                    <DialogTitle>新增群組</DialogTitle>
                    <DialogContent>
                        <DialogContentText>輸入群組名稱</DialogContentText>
                        <input className="ConnectInput" value={groupName} onChange={(e) => setGroupName(e.target.value)}/>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleGroupColse}>取消</Button>
                        <Button onClick={handleAddGroup}>新增</Button>
                    </DialogActions>
                </Dialog>
                <div style={{marginTop:"20px"}}>
                    <div className="Column">
                        {groupCard}
                    </div>
                </div>
            </div>
            <div style={{marginTop: "10px"}}>
                <div>選手管理</div>
                <button className="SubmitButton" onClick={handleStudentOpen}>新增選手</button>

                <Dialog 
                    open={studentOpen} 
                    onClose={handleStudentColse}
                >
                    <DialogTitle>新增選手</DialogTitle>
                    <DialogContent>
                        <DialogContentText>輸入選手ID</DialogContentText>
                        <input className="ConnectInput" value={userID} onChange={(e) => setUserID(e.target.value)}/>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleStudentColse}>取消</Button>
                        <Button onClick={handleAddStudent}>新增</Button>
                    </DialogActions>
                </Dialog>

                <div style={{marginTop:"20px"}}>
                    <div className="Column">
                        {connectCard}
                    </div>
                </div>
            </div>



            
        </div>
            


        </>
    );
}

export default CoachConnectPage;