import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setBottomPage } from '../../../App/activeBottomPage';
import Switch from '@mui/material/Switch';
import TripOriginOutlinedIcon from '@mui/icons-material/TripOriginOutlined';

import apiTools from '../../../apiTools';

import "../BottomPage.css"


function DetailLivePage(props){
    
    const dispatch = useDispatch(); 
    const ID = props.dailyID;
    const [name, setName] = React.useState();
    const [startDate, setStartDate] = React.useState();
    const [endDate, setEndDate] = React.useState();
    const [content, setContent] = React.useState();
    const [type, setType] = React.useState();
    const [isShare, setIsShare] = React.useState(false);
    const [userID, setUserID] = React.useState();
    const lifeEventName = {
        "考試":"#FCE3CB",
        "非規律進食":"#FFEFBF",
        "非規律睡眠":"#C4DC9C",
        "生理不適":"#ADCDA5",
        "心理壓力":"#A9E4EF",
        "天氣":"#B9D2EE",
        "生理期":"#C8BFED",
        "非規律練習":"#BFB6AC"
    };
    
    const setInit = () =>{
        dispatch(setBottomPage(""));
    }
    const handleSwitchChange = () => {
        setIsShare((prevSwitchState) => !prevSwitchState);
    };

    function handleSubmitOnClick(){
        const putData = async () =>{
            const moment = require('moment');
            const dic ={
                "user_id": userID,
                "start_date": moment(startDate).format("YYYY.MM.DD"),
                "end_date": moment(endDate).format("YYYY.MM.DD"),
                "type": type,
                "name": name,
                "content": content,
                "isShare": isShare
            }
            var response = apiTools.getResponseByPut("/event/updateLiveEvent",dic,ID);
            return (await response).ok;
        }
        if (putData()){
            setInit();
        };

    }

    useEffect(()=>{
        const event = async () =>{
            const moment = require('moment');
            const response = await (await apiTools.getResponseByGet("/event/getLiveEventById",{"id":ID})).json();
            setStartDate(moment(response.start_date).format("YYYY.MM.DD"));
            setEndDate(moment(response.end_date).format("YYYY.MM.DD"));
            setName(response.name);
            setType(response.type);
            setContent(response.content);
            setIsShare(response.isShare);
            setUserID(response.user_id);
        };
        event();
    },[]);

    return (
        <>
            <div className='bottomSheetTamplate'>
            <div className="ContentRow">
                    <input type="text" className="PageTitle" value={name} onChange={(e) => setName(e.target.value)}/>
                </div>
                <div className='ContentRow'>
                    <div className='Left'>日期</div>
                    <div className='Right'>{startDate} - {endDate}</div>
                </div>
                <div className='ContentRow'>
                    <div className='Left'>分類</div>
                    <div className='Right'> <TripOriginOutlinedIcon sx={{color:lifeEventName[type]}}/>{type}</div>
                </div>
                <div className='ContentRow'>
                    <div className='Left'>內容</div>
                    <div className='Right'>{content}</div>
                </div>
                <div className='ContentRow'>
                    <div className='Left'>分享給教練</div>
                    <div className='Right'><Switch checked={isShare}  onChange={handleSwitchChange}/></div>
                </div>
                <div className="buttonSubmitArea ContentRow"><button className="buttonSubmit" type="submit" onClick={handleSubmitOnClick}>儲存</button></div>
            </div>
        </>

    )
}

export default DetailLivePage