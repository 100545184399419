import React, { useEffect } from "react";
import { useDispatch } from 'react-redux';
import { setBottomPage } from "../../../App/activeBottomPage";
import DatePicker from "react-datepicker";
import TripOriginOutlinedIcon from '@mui/icons-material/TripOriginOutlined';
import Select from "react-select"; // 引入 react-select
import ClearIcon from '@mui/icons-material/Clear';

import "react-datepicker/dist/react-datepicker.css";


import "./AddRaceEventPage.css"
import "../BottomPage.css"
import moment from "moment";
import apiTools from "../../../apiTools";


function AddRaceEventPage(props){
    const dispatch = useDispatch();
    const [decodeJWT, setDecodeJWT] = React.useState({});
    const [startDate, setStartDate] = React.useState();
    const [endDate, setEndDate] = React.useState();
    const [currentListSubPage, setCurrentListSubPage] = React.useState(null);
    const [preListSubPage, setPreListSubPage] = React.useState(null);
    const [raceEvent, setRaceEvent] = React.useState([]);
    const [studentList, setStudentList] = React.useState([]);
    const [raceID, setRaceID] = React.useState(1);
    const [selectStudent, setSelectStudent] = React.useState();
    const [raceData, setRaceData] = React.useState([]);
    const name = React.useRef(null);
    const memo = React.useRef(null);

    const template = {
        "id" : "",
        "name": "",
        "date": Date.now()
    }
    const setInit = () =>{
        dispatch(setBottomPage(""));
    }
    async function handleSubmit(){
        try{
            if (startDate === null || endDate === null || name.current.value === "" ){
                alert("請確定格式");
                return false;
            }
            const race_object = () =>{
                return raceData.map((item)=>{
                    var moment = require("moment");
                    return {
                        "object_name":item.name,
                        "date":moment(item.date).format("YYYY-MM-DD"),
                    };
                });
            };
            
            var moment = require('moment');
            console.log(decodeJWT['identity']);
            var dic ={
                "user_id":decodeJWT['identity'] === 'coach' ? selectStudent :decodeJWT["id"],
                "start_date": moment(startDate).format("YYYY-MM-DD"),
                "end_date": moment(endDate).format("YYYY-MM-DD"),
                "name": name.current.value,
                "memo": memo.current.value,
                "race_object": race_object(),
            };
    
            console.log(dic);
            var response = await apiTools.postData("/event/addRaceEvent",dic);
            if (! response){
                alert("請確定格式");
            }
            props.setShouldRender(false);
            setInit();
        }catch(error){
            alert("請確定格式");
            console.log(error)
        }
        
    }

    function handleSelectStudent(event){
        const studentID = event.value;
        setSelectStudent(studentID);
    }

    function handleClickListButton(args){
        setCurrentListSubPage((currentListSubPage) => {
            // 在这里可以执行一些额外的处理
            setPreListSubPage(currentListSubPage);
            // 返回新的状态值
            return args === currentListSubPage ? null : args;
          });
    }
    
    function handleChange(index,value){
        setRaceData((prev)=>{
            var newData = [...prev];
            newData[index].name = value;
            return newData;
        })
    }

    function removeRaceEvent(index) {
        setRaceData((prev) => {
            const newData = [...prev];
            newData.splice(index, 1); // 删除指定索引的元素
            return newData;
        });
    }

    function handleChangeRaceDate(index, date){
        setRaceData((prev) =>{
            var newData = [...prev];
            newData[index].date = date;
            return newData;
        })
    }

    useEffect(()=>{console.log(raceData)},[raceData]);

    useEffect(()=>{
        setRaceEvent(
            raceData.map((item, index)=>{
                const ID = item.id;
                const event = (
                    <React.Fragment key={ID}>
                      <div className="ListButton race_object">
                        <div  className="ListTitle" onClick={() => {handleClickListButton(ID)}}>
                           <div className="ListIcon"><TripOriginOutlinedIcon /></div>                
                           <div >{item.name}</div>
                           <div className="ListIcon" onClick={()=>removeRaceEvent(index)}><ClearIcon/></div>
                        </div>
                        <div id={ID} className="ListClose">
                          <div className="ContentRow">
                              <div className="Left">
                                  <div className="LeftRight" >項目名稱</div>
                              </div>
                              <div className="Right">
                                  <input id="Name" onChange={(e)=>{handleChange(index,e.target.value)}} ></input>
                              </div>
                          </div>
                          <div className="ContentRow">
                              <div className="Left">
                                  <div className="LeftRight">日期</div>
                              </div>
                              <div className="Right">
                                  <DatePicker className="date" selected={item.date} onChange={(date) => { handleChangeRaceDate(index, date) }} dateFormat={"yyyy-MM-dd"} />
                              </div>
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                );
                return event;
            })
        );
    },[raceData]);


    function addRaceEvent() {
        setRaceData((prev) => {
            const newData = [...prev]; // 创建新数组以确保更新
            const thisTemplate = { ...template }; // 创建模板的副本以防止原始模板更改
            thisTemplate.id = "data" + raceID;
            thisTemplate.name = "比賽項目" + raceID;
            newData.push(thisTemplate); // 将修改后的模板添加到数组中
            setRaceID((prev) => prev + 1); // 更新 raceID
            return newData;
        });
    }

    useEffect(() => {
        const listItem = document.getElementById(currentListSubPage);
        if (listItem) {
            listItem.className = "ListOpen";
        }
    },[currentListSubPage]);

    useEffect(() => {
        const listItem = document.getElementById(preListSubPage);
        if (listItem) {
            listItem.className = "ListClose";
        }
    },[preListSubPage]);

    useEffect(()=>{
        const getData = async () =>{
            var dic = {"id": decodeJWT["id"]}
            const response = await (await apiTools.getResponseByGet("/user/getFriendById",dic)).json();
            setStudentList(response.map((item)=>{
                return({
                    value: item.student_id,
                    label:(
                        <div>{item.name}</div>
                    )
                });
            }));
        }
        getData();    
    },[decodeJWT]);

    useEffect(() =>{
        apiTools.decodeJWT().then((value)=>{
            setDecodeJWT(value);
        });
    },[]);


    return (
        <>
            <div className="bottomSheetTamplate">
                <h1 style={{textAlign:"center"}}>新增比賽事件</h1>
                
                <div className="date ContentRow">
                    <div className="Left">
                        <div className="LeftRight Row">日期<div className="red">*</div></div>
                    </div>
                    <div className="Right datePicker">
                        <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} dateFormat={"yyyy-MM-dd"} />
                        <div style={{width : "50px",textAlign:"center"}}> - </div>
                        <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} dateFormat={"yyyy-MM-dd"}/>
                    </div>
                </div>
                {decodeJWT['identity'] === 'coach'  && 
                    <div className="ContentRow">
                        <div className="Left Row">成員 <div className="red">*</div></div>
                        <div className="Right">
                            <Select 
                                className="RightSelect"
                                options={studentList}
                                onChange={(e) => {handleSelectStudent(e)}}
                            />
                        </div>
                    </div>
                } 
                
                <div className="LifeEventName ContentRow">
                    <div className="Left">
                        <div className="LeftRight Row">名稱 <div className="red">*</div></div>
                    </div>
                    <div className="Right"><input ref={name} className="BottomInput" ></input></div>
                </div>

                <div className="ContentRow">
                    <div className="Left">
                        <div className="LeftRight">賽事名稱</div>
                    </div>
                </div>

                <div className="ListContent">
                    <ul style={{listStyle :"none",paddingLeft:"5%"}}>
                        <div className="ListButton" >
                            <div  className="ListTitle" onClick={() =>{addRaceEvent()}}> 
                                <div className="ListIcon"><TripOriginOutlinedIcon /></div>
                                <div>新增賽事項目</div>  
                            </div>
                        </div>
                        {raceEvent}
                    </ul>
                </div>

                <div className="LifeEventContent ContentRow">
                    <div className="Left">
                        <div className="LeftRight">備註</div>
                    </div>
                </div>
                <div className="LifeEventSubmit" style={{padding: '10px 7% 15px 14%',textAlign:"center"}}>
                    <div>
                        <textarea ref={memo} className="bottomTextArea"name="memo" rows="15" />
                    </div>
                </div >
                <div className="buttonSubmitArea ContentRow"><button className="buttonSubmit" type="submit" onClick={handleSubmit} >儲存</button></div>
            </div>
        </>
    );


}

export default AddRaceEventPage