import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    dailyID: "",
    surveyData: "",
    trainingBlockID: "",
    trainingBlockData: "",
    shouldRender: false,
    findText:"",
};

const collectState = createSlice({
    name: "collectID",
    initialState,
    reducers:{
        setSurveyData : (state, action) =>{
            state.surveyData = action.payload;
        }
    },
});

export const { setSurveyData } = collectState.actions;

export default collectState.reducer;