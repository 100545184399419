import React, { useEffect } from "react";
import { useDispatch } from 'react-redux';
import { setSubPage } from "../../../App/activeSubPage";
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import TripOriginOutlinedIcon from '@mui/icons-material/TripOriginOutlined';
import {ReactComponent as TagIcon} from "../../../images/Tag.svg";
import {ReactComponent as MIcon} from "../../../images/CourseIcon/M.svg";
import {ReactComponent as WIcon} from "../../../images/CourseIcon/W.svg";
import {ReactComponent as CIcon} from "../../../images/CourseIcon/C.svg";
import {ReactComponent as DIcon} from "../../../images/CourseIcon/D.svg";

import profile from '../../../images/user.png';

import apiTools from "../../../apiTools";
import './TrainingDetail.css';

function TrainingDetailPage(props){
    const data = props.trainingBlockData;
    const dispatch = useDispatch();

    const tableTimeEventColor = {"主課程":"#E8EEE9",
                                "熱身":"#F5EAEA",
                                "緩和":"#E5E9F5",
                                "技術動作":"#F8EFCE"};
    
    const tableTimeIcon = {
        "主課程":<MIcon/>,
        "熱身": <WIcon/>,
        "緩和":<CIcon/>,
        "技術動作":<DIcon/>
    };

    const [isMark,setMark] = React.useState(data.subscribe);
    const [trainingDetailData, setTrainingDetailDate] = React.useState();

    useEffect(()=>{
        const getData = async () =>{
            const dic ={"id":props.blockID};
            const response = await( await apiTools.getResponseByGet("/event/getTrainTemplateById",dic)).json();
            const template = response.template;
            setTrainingDetailDate(template.training.map((item) =>{
                var title = 
                    item.training_block.map((item) =>
                        `${item.iteration}*${item.distance} m ${item.swimming_style} ${item.action} ${item.time_minute.toString().padStart(2, '0')}:${item.time_second.toString().padStart(2, '0')} ${item.equipment}`
                    ).join(' ');
                if (item.times > 1){
                    title = `${item.times} * ( ${title} )`;
                }
                title = title.replace("null ","");
                return(
                <>
                    <div className="trainingButton" style={{backgroundColor:tableTimeEventColor[item.train_type]}}>
                        <div className="TrainingListTitle">
                            <div className="TrainingLeft">
                                <div className="ListIcon">
                                    {tableTimeIcon[item.train_type]}
                                </div>                
                                <div>{title}</div> {/* 趟数*距离 泳姿 时间 器材 */}
                            </div>
                        </div>
                    </div>
                </>
                );
            }));
        }
        getData();
    },[]);

    function handleReturnBotton(){
        dispatch(setSubPage("trainingTemplate"));
    }

    return(
        <>
            <div className="TrainingDetailContent">
                <div className="TrainingDetailTitle">
                    <div className="TrainingDetailLeft">
                        <div className="TrainingDetailReturnButton" onClick={handleReturnBotton}><ChevronLeftRoundedIcon/></div>
                        <div>{data.name}</div>
                    </div>
                    <div className="TrainingDetailRight" >
                        <div className="Owner">
                            <img src={profile} alt="" srcset="" />
                            <div>{data.creater_name}</div>
                        </div>
                        <TagIcon fill={isMark? "blue":"none"}/>
                    </div>
                </div>
                <div className="Column">
                    {trainingDetailData}
                </div>
            </div>
        </>
    );
}

export default TrainingDetailPage