import React, { useEffect, useRef } from 'react';
import { Page, Text, View, Document, StyleSheet, Svg, Line, Rect, G } from '@react-pdf/renderer';
import moment from 'moment';
import styles  from './GeneratingPDFCSS';
import apiTools from '../../../apiTools';


const template = [
    {
        "id": "",
        "title": "晨間睡眠日誌",
        "description": "追蹤每日睡眠品質",
        "score": "",
        "type": "Sleep_Log_Morning"
    },
    {
        "id": "",
        "title": "晚間睡眠日誌",
        "description": "追蹤每日睡眠品質",
        "score": "",
        "type": "Sleep_Log_Night"
    },
    {
        "id": "",
        "title": "STOP-Bang問卷",
        "description": "阻塞型睡眠呼吸中止風險評估",
        "score": "",
        "type": "STOP_Bang"
    },
    {
        "id": "",
        "title": "匹茲堡睡眠品質評量表",
        "description": "睡眠品質的評估檢測",
        "score": "",
        "type": "PSQI"
    },
    {
        "id": "",
        "title": "壓力知覺量表",
        "description": "評價個人生活情境的壓力程度",
        "score": "",
        "type": "PSS"
    }
];

const GeneratingPDF = ({ data = template, date, user_id, profile = {}, startDate = new Date(), endDate = new Date() }) => {
    const STOPBang = data[2];
    const PSS = data[4];
    const PSQI =data[3];

    const [PSSData, setPSSData] = React.useState(null);
    const [PSQIData, setPSQIData] = React.useState(null);
    const [PSSMax, setPSSMax] = React.useState();
    const [PSQIMax, setPSQIMax] = React.useState();

    useEffect(() => {
        const getData = async () => {
          const dic = {
            "user_id": user_id,
            "startDate": moment(startDate).format("YYYY-MM-DD"),
            "endDate": moment(endDate).format("YYYY-MM-DD"),
          };
          const response = await apiTools.getResponseByGet("/survey/getYearSurvey", dic);
          if (response.ok) {
            const data = await response.json();
            var psqiMax = 0;
            var pssMax = 0;
            const psqiData = data["PSQI"].map(item => {
                if (psqiMax < item.result.score){
                    psqiMax = item.result.score
                }
                return({
                    date: moment(item.fill_date).format('YYYY-MM-DD'),
                    score: item.result.score,
                });
            });
            const pssData = data["PSS"].map(item => {
                if (pssMax < item.result.score){
                    pssMax = item.result.score
                }
                return({
                    date: moment(item.fill_date).format('YYYY-MM-DD'),
                    score: item.result.score,
                });
            });
      
            if (psqiData.length > 0) {
                setPSQIMax(psqiMax)
                setPSQIData(psqiData);
              }
        
            if (pssData.length > 0) {
                setPSSMax(pssMax)
                setPSSData(pssData);
            }
          } else {
            console.error("Error fetching survey data:", response.statusText);
          }
        };
        getData();
      }, [user_id, startDate, endDate]); 

    

      const barWidth = 30;
      const barSpacing = 10;
      const chartWidth = 530; // A4 width in points
      const chartHeight = 200;

    return (
        <>
        <Document>
            <Page size="A4" style={styles.page}>
                <View>
                    <Text style={[styles.title, styles.center]}>國立成功大學附設醫院睡眠醫學中心林政佑教授實驗室</Text>
                    <Text style={[styles.title, styles.center]}>居家型睡眠品質檢查報告</Text>
                </View>
                <Text style={[styles.header, styles.right]}>紀錄日期:{moment(date).format("YYYY-MM-DD")}</Text>
                <View>
                    <Text style={[styles.left, styles.bold]}>基本資料</Text>
                    <View style={styles.table}>
                        <View style={styles.tableRow}>
                            <View style={[styles.tableCol, styles.Row]}>
                                <Text style={styles.tableCell}>姓名：</Text>
                            </View>
                            <View style={[styles.tableCol, styles.Row]}>
                                <Text style={styles.tableCell}>年齡：</Text>
                                <Text style={[styles.tableCell, styles.right]}>歲</Text>
                            </View>
                            <View style={[styles.tableCol, styles.Row]}>
                                <Text style={styles.tableCell}>性別：</Text>
                            </View>
                            <View style={[styles.tableCol, styles.Row]}>
                                <Text style={styles.tableCell}>生日：</Text>
                                <Text style={[styles.tableCell, styles.right]}>{profile.birthday}</Text>
                            </View>
                        </View>
                        <View style={styles.tableRow}>
                            <View style={[styles.tableCol, styles.Row]}>
                                <Text style={styles.tableCell}>身高：</Text>
                                <Text style={[styles.tableCell, styles.right]}>{profile.height}</Text>
                                <Text style={[styles.tableCell, styles.right]}>公分</Text>
                            </View>
                            <View style={[styles.tableCol, styles.Row]}>
                                <Text style={styles.tableCell}>體重：</Text>
                                <Text style={[styles.tableCell, styles.right]}>{profile.weight}</Text>
                                <Text style={[styles.tableCell, styles.right]}>公斤</Text>
                            </View>
                            <View style={[styles.tableCol, styles.Row]}>
                                <Text style={styles.tableCell}>體質量指數：</Text>
                            </View>
                            <View style={[styles.tableCol, styles.Row]}>
                                <Text style={styles.tableCell}>頸圍：</Text>
                                <Text style={[styles.tableCell, styles.right]}>公分</Text>
                            </View>
                        </View>
                    </View>
                </View>
                <Text style={[styles.left, styles.bold]}>睡眠品質相關問卷</Text>
                <View style={styles.section}>
                    <View style={styles.Row}>
                        <View><Text style={styles.left}>{STOPBang.title}：</Text></View>
                        <View><Text style={[styles.right, styles.underline]}>{STOPBang.score === "" || STOPBang.score.result === undefined ? "     " : `${STOPBang.score.result}`}</Text></View>
                    </View>
                    <View style={styles.section}>
                        <Text style={[styles.small, styles.wrap]}>
                            此量表為填表人主觀評估其是否有睡眠呼吸中止症之症狀，量表中包含4個題目，會詢問填表
                            人是否有打鼾、疲倦、有人目擊到呼吸暫停、高血壓、超過35 kg/m2、超過50歲、頸圍超過
                            40公分及為男性。在這8題中，若有0-2題回答「是」，屬於低度風險；有3-4題回答「是」，
                            屬於中度風險；當有5-8題回答「是」，便屬於高度風險。另外身體質量指數這一題，對於東
                            方人而言，超過 35 kg/m2比較少見，所以有些研究會以 27 或 30 kg/m2替代。
                        </Text>
                    </View>

                    <View style={styles.Row}>
                        <View><Text style={styles.left}>{PSQI.title}：</Text></View>
                        <View><Text style={[styles.right, styles.underline]}>{PSQI.score === "" || PSQI.score.result === undefined ? "     " : `${PSQI.score.result} (總分 ${PSQI.score.score} 分)`}</Text></View>
                    </View>
                    <View style={styles.section}>
                        <Text style={[styles.small, styles.wrap]}>
                            匹茲堡睡眠品質量表是一個廣為臨床應用的睡眠品質與障礙的自填問卷量表。通常以過去一
                            個月為評量時間。共有 19 個問題，針對 7 種情境包括睡眠品質、睡眠延遲時間、睡眠持續
                            時間、睡眠效率、睡眠中斷、使用睡眠藥物及日間功能障礙等，並根據不同規則加總計分。
                            總分高於 5 分者，高度懷疑其睡眠品質不佳。
                        </Text>
                    </View>

                    <View style={styles.Row}>
                        <View><Text style={styles.left}>{PSS.title}：</Text></View>
                        <View><Text style={[styles.right, styles.underline]}>{PSS.score === "" || PSS.score.result === undefined ? "     " : `${PSS.score.result} (總分 ${PSS.score.score} 分)`}</Text></View>
                    </View>
                    <View style={styles.section}>
                        <Text style={[styles.small, styles.wrap]}>
                        壓力知覺量表通常以過去一個月為評量時間，此量表為填表人主觀評估感受或想到某一特定想
                        法的頻率，一共14題，每題分數範圍0-4分，總和分數越高表示壓力越大。0-28分，壓力無明
                        顯異常;29-42分，壓力稍大，需注意;43-56分，壓力太大，需尋求資源協助。
                        </Text>
                    </View>

                </View>
                <View style={styles.footer}>
                    <Text style={styles.footerText}>
                        若您任何時刻懷疑有睡眠障礙的問題時，歡迎隨時與我們聯繫，或至鄰近大型醫院睡眠特別門診，接受進一步評估。
                    </Text>
                    <Text style={[styles.footerText, styles.right]}>
                        國立成功大學附設醫院 睡眠醫學中心 林政佑教授實驗室
                    </Text>
                    <Text style={[styles.footerText, styles.right]}>
                        敬上
                    </Text>
                </View>
            </Page>

            <Page size="A4" style={styles.page}>
                    <View>
                        <Text>{`睡眠品質歷史紀錄:${moment(startDate).format("YYYY-MM-DD")}~${moment(endDate).format("YYYY-MM-DD")}`}</Text>
                        {PSQIData   &&
                        <View style={styles.section}>
                            <Text style={{marginBottom: 25}}>PSQI 分數</Text>
                            <Svg width={chartWidth} height={chartHeight + 150}>
                            <G transform="translate(0, 15)">
                                {/* X-axis */}
                                <Line x1="50" y1={chartHeight} x2={chartWidth - 50} y2={chartHeight} style={styles.axis} />
                                {/* Y-axis */}
                                <Line x1="50" y1={chartHeight} x2="50" y2="0" style={styles.axis} />
                                {/* Y-axis ticks and labels */}
                                {[...Array(PSQIMax + 1)].filter((_, i) => i % 5 === 0).map((_, i) => {
                                    const y = chartHeight - (i * 5 * chartHeight) / PSQIMax;
                                    return (
                                        <G key={i}>
                                            <Line x1="45" y1={y} x2="50" y2={y} style={styles.axis} />
                                            <Text x="40" y={y + 8} fontSize="15" textAnchor="end" fill="black">
                                                {i * 5}
                                            </Text>
                                        </G>
                                    );
                                })}
                                {/* Bars */}
                                <G transform={`translate(55, ${chartHeight})`}>
                                    {PSQIData.map((d, i) => (
                                        <G key={i}>
                                        <Rect
                                            x={(barWidth + barSpacing) * i}
                                            y={-d.score * (chartHeight / PSQIMax)}
                                            width={barWidth}
                                            height={d.score * (chartHeight / PSQIMax)}
                                            style={styles.bar}
                                        />
                                        <Text
                                            x={(barWidth + barSpacing) * i + barWidth / 2}
                                            y={-d.score * (chartHeight / PSQIMax)}
                                            fontSize="15"
                                            textAnchor="middle"
                                            fill="black"
                                        >
                                            {d.score}
                                        </Text>
                                    </G>
                                    ))}
                                </G>
                                <G transform={`translate(55, ${chartHeight})`}>
                                    {PSQIData.map((d, i) => (
                                        <Text
                                            key={i}
                                            x="0"
                                            y={-(barWidth + barSpacing) * i}
                                            width={barWidth}
                                            fontSize="15"
                                            textAnchor="start"
                                            fill="black"
                                            transform={`rotate(90, ${(barWidth + barSpacing) * i}, 0)`}
                                        >
                                            {d.date}
                                        </Text>
                                    ))}
                                </G>
                                </G>
                            </Svg>
                        </View>
                        }
                </View>
            </Page>
            
            <Page size="A4" style={styles.page}>
                <View>
                    {PSSData  && 
                    <View style={styles.section}>
                        <Text style={{marginBottom: 20}}>PSS 分數</Text>
                        <Svg width={chartWidth} height={chartHeight + 150}>
                        <G transform="translate(0, 15)">
                            {/* X-axis */}
                            <Line x1="50" y1={chartHeight} x2={chartWidth - 50} y2={chartHeight} style={styles.axis} />
                            {/* Y-axis */}
                            <Line x1="50" y1={chartHeight} x2="50" y2="0" style={styles.axis} />
                            {/* Y-axis ticks and labels */}
                            {[...Array(PSSMax + 1)].filter((_, i) => i % 5 === 0).map((_, i) => {
                                const y = chartHeight - (i * 5 * chartHeight) / PSSMax;
                                return (
                                    <G key={i}>
                                        <Line x1="45" y1={y} x2="50" y2={y} style={styles.axis} />
                                        <Text x="40" y={y + 8} fontSize="15" textAnchor="end" fill="black">
                                            {i * 5}
                                        </Text>
                                    </G>
                                );
                            })}
                            {/* Bars */}
                            <G transform={`translate(55, ${chartHeight})`}>
                                {PSSData.map((d, i) => (
                                    <G key={i}>
                                    <Rect
                                        x={(barWidth + barSpacing) * i}
                                        y={-d.score * (chartHeight / PSSMax)}
                                        width={barWidth}
                                        height={d.score * (chartHeight / PSSMax)}
                                        style={styles.bar}
                                    />
                                    <Text
                                        x={(barWidth + barSpacing) * i + barWidth / 2}
                                        y={-d.score * (chartHeight / PSSMax)}
                                        fontSize="15"
                                        textAnchor="middle"
                                        fill="black"
                                    >
                                        {d.score}
                                    </Text>
                                </G>
                                ))}
                            </G>
                            <G transform={`translate(55, ${chartHeight})`}>
                                {PSSData.map((d, i) => (
                                    <Text
                                        key={i}
                                        x="0"
                                        y={-(barWidth + barSpacing) * i}
                                        width={barWidth}
                                        fontSize="15"
                                        textAnchor="start"
                                        fill="black"
                                        transform={`rotate(90, ${(barWidth + barSpacing) * i}, 0)`}
                                    >
                                        {d.date}
                                    </Text>
                                ))}
                            </G>
                            </G>
                        </Svg>
                    </View>
                    }
                </View>
            </Page>
        </Document></>
    );
};

export default GeneratingPDF;
