// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.personalContent{
    font-size: large;
    justify-content: space-between;
    margin-top: 20px;
}

.personalContent>div:first-child{
    text-align: right;
}

.personalButton{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: fixed;
    bottom: 60px;
    margin-left: -30px;
}

.personalInput{
    background: #F3F3F3;
    border: 0.5px solid #ECECEC;
    border-radius: 30px;
    box-sizing: border-box;
}
`, "",{"version":3,"sources":["webpack://./src/MainPage/SubPage/PersonalSettingPage/PersonalSettingPage.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,8BAA8B;IAC9B,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,WAAW;IACX,eAAe;IACf,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,mBAAmB;IACnB,2BAA2B;IAC3B,mBAAmB;IACnB,sBAAsB;AAC1B","sourcesContent":[".personalContent{\r\n    font-size: large;\r\n    justify-content: space-between;\r\n    margin-top: 20px;\r\n}\r\n\r\n.personalContent>div:first-child{\r\n    text-align: right;\r\n}\r\n\r\n.personalButton{\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    width: 100%;\r\n    position: fixed;\r\n    bottom: 60px;\r\n    margin-left: -30px;\r\n}\r\n\r\n.personalInput{\r\n    background: #F3F3F3;\r\n    border: 0.5px solid #ECECEC;\r\n    border-radius: 30px;\r\n    box-sizing: border-box;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
