import React, { useEffect } from "react";

import { useDispatch } from 'react-redux';
import { setSubPage } from '../../../App/activeSubPage';

import {ReactComponent as TagIcon} from "../../../images/Tag.svg";
import profile from '../../../images/user.png';
import apiTools from "../../../apiTools";


function TrainingBlock(props){
    const [decodeJWT, setDecodeJWT] = React.useState({});
    const dispatch = useDispatch();

    useEffect(() =>{
        apiTools.decodeJWT().then((value)=>{
            setDecodeJWT(value);
        });
    },[]);
    
    var data = props.data;
    const [isMark, setIsMark] = React.useState(data.subscribe);
    const [iconStyle, setIconStyle] = React.useState();
    
    const subscribeTraining = async () =>{
        var response = await apiTools.postData(`event/subscribeTrainTemplate?user_id=${decodeJWT["id"]}&train_template_id=${data.train_template_id}`,{});
        if (response){
            data.subscribe = 1;
            setIsMark(data.subscribe);
        }

    };
    const unsubscribeTraining = async () =>{
        const dic = {
            "user_id": decodeJWT["id"],
            "train_template_id": data.train_template_id
        }
        var response = await apiTools.getResponseByDelete("event/unsubscribeTemplate",dic);
        if (response.status === 200){
            data.subscribe = 0;
            setIsMark(data.subscribe);
        }
    };

    useEffect(() => {
        var dic = { fill: data.subscribe === 1 ? "blue" : "", stroke: "none" };
        setIconStyle(dic);
    },[data.subscribe,isMark]);
    
    return(
    <>
        <div className="TrainingBlocks" >
            <div onClick={() => {             
                props.setTrainingBlockData(data);
                props.setTrainingBlockID(data.train_template_id);
                dispatch(setSubPage('trainingDetail'));
            }}>
                <div className="TrainingContentUp">
                    <div>{data.name}</div>
                    <div onClick={(e) =>{
                        e.stopPropagation();
                        data.subscribe === 0? subscribeTraining() : unsubscribeTraining();
                    }}>
                        <TagIcon style={iconStyle} />
                    </div>
                </div>
                <div className="TrainingContentDown">
                    <img src={profile} alt="" />
                    <div>{data.creater_name}</div>
                </div>
            </div>
        </div>
    </>
    );
}

export default TrainingBlock;