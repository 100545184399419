import React, { useEffect } from "react";

import { useDispatch } from 'react-redux';
import { setSubPage } from '../../../App/activeSubPage';

import "./ContestPage.css";
import apiTools from "../../../apiTools";
import profile from '../../../images/user.png';


function ContestPage(props){
    const dispatch = useDispatch();

    const isPicture = (localStorage.getItem("photo") === null || localStorage.getItem("photo") === "");
    const src = isPicture ? profile : localStorage.getItem("photo");

    const [decodeJWT, setDecodeJWT] = React.useState({});

    useEffect(() =>{
        apiTools.decodeJWT().then((value)=>{
            setDecodeJWT(value);
        });
    },[]);

    
    return(
    <>
        <div className="ContestContent">
            <div className="myProfile TextCenter">
                <div className="Row spaceRround topProfile">
                    <div><img alt={"大頭照"} style={{borderRadius:"50%",width:"40px", height : "40px"}} src = {src}></img></div>
                    <div>{decodeJWT.name}</div>
                    <div className="CheckScore" onClick={()=>{ dispatch(setSubPage("detailContest"))}} >查看我的成績</div>
                </div>
                <div className="Row spaceRround">
                    <div >
                        <div>
                            代表單位
                        </div>
                        <div>國立成功大學</div>
                    </div>
                    <div>
                        <div>專長</div>
                        <div>蝶式 自由式</div>
                    </div>
                    <div>
                        <div>最佳成績</div>
                        <div>XXXXXXXXX</div>
                    </div>

                </div>
            </div>
            <div className="myProfile">
                <div className="textMargin">Name</div>
                <div className="textMargin">專長/蝶式、自由式</div>
                <div className="textGray textMargin">現代表單位/Cali Contors</div>
            </div>
        </div>
    </>);

}

export default ContestPage;