import React, { useEffect } from "react";

function DetailTrainBlockScore(props){
    const data = props.data;
    const [score, setScore] = React.useState({});
    const [dataLoaded, setDataLoaded] = React.useState(false);

    function handleChange(blockID, index, TimeIndex, value) {
        // Ensure the values are within the specified ranges
        let newValue = parseInt(value, 10);

        if (TimeIndex === 0 || TimeIndex === 1) { // Min or Sec
            newValue = Math.max(0, Math.min(59, newValue));
        } else if (TimeIndex === 2) { // Ms
            newValue = Math.max(0, Math.min(99, newValue));
        }

        setScore((prev) => {
            const updatedScore = { ...prev };
            updatedScore[blockID] = updatedScore[blockID] || [];
            updatedScore[blockID][index] = updatedScore[blockID][index] || [];
            updatedScore[blockID][index][TimeIndex] = newValue;
            return updatedScore;
        });
    }
    useEffect(()=>{
        setScore((prev) => {
            var updata = prev;
            updata[data.id] = [];
            Array.from({length:data.iteration},(_,i) =>{
                var Mintue = "00";
                var Second = "00";
                var Ms = "00";
                if (data.iteration_record !== null && i < data.iteration_record.length){
                    const temp = data.iteration_record[i].split(":");
                    Mintue = temp[0] || "00";
                    Second = temp[1] || "00";
                    Ms = temp[2] || "00";
                }
                updata[data.id].push([Mintue, Second, Ms]);
            });
            return updata;
        });
        setDataLoaded(true);
    },[]);

    useEffect(()=>{
        props.handleChange(score);
    },[score]);

    return(
        <React.Fragment >
            <div>
                {`${data.iteration}*${data.distance}m ${data.swimming_style} ${data.action ? data.action:""} ${data.time_minute}:${data.time_second} ${data.equipment ? data.equipment:""}`}
            </div>
            <div>
                {`目標時間：${data.goal_minute}:${data.goal_second}`}
            </div>
            
            {data.comment !== "" && (
                <div>{`備註：${data.comment}`}</div>
            )}
            
            {!dataLoaded || Array.from({ length: data.iteration }, (_, i) => {
                return(
                    <div key={i} className="ContentRow">
                        <div>{i+1}</div>
                        <input 
                            type="number"
                            className="timeInput" 
                            value={score[data.id][i][0]}
                            onChange={(e) => handleChange(data.id,i,0,e.target.value)}
                        />
                        <div>'</div>
                        <input 
                            type="number"
                            className="timeInput" 
                            value={score[data.id][i][1]}
                            onChange={(e) => handleChange(data.id,i,1,e.target.value)}
                        />
                        <input 
                            type="number"
                            className="timeInput" 
                            value={score[data.id][i][2]}
                            onChange={(e) => handleChange(data.id,i,2,e.target.value)}
                        />
                    </div>
                    );
            })}
        </React.Fragment>
    );
}
export default DetailTrainBlockScore;