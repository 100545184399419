// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TrainingContentList{
    display: flex;
    flex-direction: column;
}

.TrainingBlocks{
    padding: 20px;
    margin: 15px;
    background-color: white;
    border-radius: 10px;
    border: 1px solid var(--Frame, #ECECEC);
    background: #FAFAFA;
    }

.TrainingContentUp{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.TrainingContentDown{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 30px;
    color: #979797;
}

.TrainingContentDown>img{
    height: 20px;
    width: 20px;
    margin: 0px 5px 0px 0px;
}`, "",{"version":3,"sources":["webpack://./src/MainPage/SubPage/TrainingTemplatePage/TrainingTemplate.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,YAAY;IACZ,uBAAuB;IACvB,mBAAmB;IACnB,uCAAuC;IACvC,mBAAmB;IACnB;;AAEJ;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,uBAAuB;AAC3B","sourcesContent":[".TrainingContentList{\r\n    display: flex;\r\n    flex-direction: column;\r\n}\r\n\r\n.TrainingBlocks{\r\n    padding: 20px;\r\n    margin: 15px;\r\n    background-color: white;\r\n    border-radius: 10px;\r\n    border: 1px solid var(--Frame, #ECECEC);\r\n    background: #FAFAFA;\r\n    }\r\n\r\n.TrainingContentUp{\r\n    display: flex;\r\n    flex-direction: row;\r\n    justify-content: space-between;\r\n    align-items: center;\r\n}\r\n\r\n.TrainingContentDown{\r\n    display: flex;\r\n    flex-direction: row;\r\n    align-items: center;\r\n    margin-top: 30px;\r\n    color: #979797;\r\n}\r\n\r\n.TrainingContentDown>img{\r\n    height: 20px;\r\n    width: 20px;\r\n    margin: 0px 5px 0px 0px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
