import React, { useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { setSubPage } from '../../../App/activeSubPage';
import { setBottomPage} from "../../../App/activeBottomPage";

import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import TimelineIcon from '@mui/icons-material/Timeline';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import GridViewIcon from '@mui/icons-material/GridView';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import ListOutlinedIcon from '@mui/icons-material/ListOutlined';
import ImportContactsOutlinedIcon from '@mui/icons-material/ImportContactsOutlined';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';

import '../../MainPage.css';
import './BottomNavBar.css';

function BottomNavBar() {
    // true表示打開
    const [isopenAdd,setopenAdd] = React.useState(false);
    const openAddRef = React.useRef();
    const openAddRef2 = React.useRef();

    const subPage = useSelector(state => state.activeSubPage.subPage)
    const dispatch = useDispatch();
    
    useEffect(() => {
        const handleClickOutside = (event) => {
        // 檢查點擊事件的目標是否不是div元素或它的子元素且目前add為開啟的
        if (openAddRef.current && !openAddRef.current.contains(event.target) && isopenAdd) {
            setAddCircleRoundedIcon();
            }
        };

    // 添加點擊事件監聽器
        document.addEventListener('click', handleClickOutside);

    // 清理函數，用於在組件卸載前移除點擊事件監聽器
        return () => {
        document.removeEventListener('click', handleClickOutside);
        };
    });

    useEffect(() => {
        const handleClickInside = (event) => {
        // 檢查點擊事件的目標是否是button元素或它的子元素且目前add為關閉的
        if (openAddRef2.current && openAddRef2.current.contains(event.target) && !isopenAdd) {
            setAddCircleRoundedIcon();
            }
        };

    // 添加點擊事件監聽器
        document.addEventListener('click', handleClickInside);

    // 清理函數，用於在組件卸載前移除點擊事件監聽器
        return () => {
        document.removeEventListener('click', handleClickInside);
        };
    });

    function setpage(){
        if(!isopenAdd){
            return <AddCircleRoundedIcon sx={{ color: '#002DE3', fontSize: 40 }} />
        }else{
            return <CancelRoundedIcon sx={{ color: '#002DE3', fontSize: 40 }} />
        }
    }
    function setOtherArea(){
        if(isopenAdd){
            setopenAdd(!isopenAdd);
        }
    }
    function setAddCircleRoundedIcon(){
        setopenAdd(!isopenAdd);
    }

    function changeSubPage(value){
        dispatch(setSubPage(value));
    }

    function changeBottomPage(value){
        dispatch(setBottomPage(value));
    }

    return (
        <nav className="bottom-nav-bar">
            <button 
            className={subPage === 'calendar' ? 'selected' : 'unselected'}
            onClick={()=>{changeSubPage('calendar');setOtherArea();}}>
                <CalendarMonthOutlinedIcon sx={{ fontSize: 25 }} />
            </button>
            <button 
            className={subPage === 'analytics' ? 'selected' : 'unselected'}
            onClick={()=>{changeSubPage('analytics');setOtherArea();}}>
                <TimelineIcon sx={{ fontSize: 25 }}/>
            </button>
            <div  className={`columsButton ${ isopenAdd ? 'addopen' : ''}`}>
                <div ref = {openAddRef} className={`hideButton ${ isopenAdd ? 'addopen' : ''}` }>
                    <button onClick={()=>{changeBottomPage('addTimeTable');setOtherArea();}}><ListOutlinedIcon sx={{  fontSize: 40}}/><div>新增課表</div></button>
                    <button onClick={()=>{changeBottomPage('addLifeEvent');setOtherArea();}}><ImportContactsOutlinedIcon sx={{ fontSize: 40 }}/><div>新增生活事件</div></button>
                    <button onClick={()=>{changeBottomPage('addRaceEvent');setOtherArea();}}><EmojiEventsIcon sx={{  fontSize: 40 }}/><div>新增比賽事件</div></button>
                </div>
                <button ref={openAddRef2}>{setpage()}</button>
            </div>
            
            <button 
            className={subPage === 'trainingTemplate' ? 'selected' : 'unselected'}
            onClick={()=>{changeSubPage('trainingTemplate');setOtherArea();}}>
                <GridViewIcon sx={{  fontSize: 25 }}/>
            </button>
            <button 
            className={subPage === 'contest' ? 'selected' : 'unselected'}
            onClick={()=>{changeSubPage('contest');setOtherArea();}}>
                <EmojiEventsIcon sx={{  fontSize: 25 }}/>
            </button>
        </nav>
    )
}

export default BottomNavBar