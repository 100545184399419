import React, { useEffect } from "react";

import TrainingBlock from "../../components/TrainingBlock/TrainingBlock";
import "./TrainingTemplate.css";

import apiTools from "../../../apiTools";


function TrainingTemplatePage(props){

    const [trainingList, setTrainingList] = React.useState();
    const [allData, setAllData] = React.useState([]) ;

    const [decodeJWT, setDecodeJWT] = React.useState({});

    useEffect(() =>{
        apiTools.decodeJWT().then((value)=>{
            setDecodeJWT(value);
        });
    },[]);

    useEffect(() => {
        const getData = async () =>{
            const dic = {"user_id": decodeJWT["id"]};
            const response = await (await apiTools.getResponseByGet("/event/getAllTrainTemplate",dic)).json();
            setAllData(response);
            setTrainingList(response.map((item) => {
                return (<TrainingBlock data={item} setTrainingBlockID={props.setTrainingBlockID} setTrainingBlockData={props.setTrainingBlockData}/>);
            }));
        };
       if (decodeJWT["id"] !== undefined){
            getData();
        }
    },[decodeJWT]);

    useEffect(()=>{
        const find = props.findText;
        var filteredResults = allData;

        if (props.findText !== "" && props.findText !== null){
            filteredResults = filteredResults.filter(product =>
                product.name.toLowerCase().includes(find.toLowerCase()) ||
                product.creater_name.toLowerCase().includes(find.toLowerCase())
            );
        }
        setTrainingList([]);
        setTrainingList(filteredResults.map((item) => {
            return (<TrainingBlock  data={item} setTrainingBlockID={props.setTrainingBlockID} setTrainingBlockData={props.setTrainingBlockData}/>);
        }));
        

    },[props.findText]);

    return(
        <>
            <div className="TrainingContentList">
                {trainingList}
            </div>
        </>
    );
}

export default TrainingTemplatePage
