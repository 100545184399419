import { configureStore } from '@reduxjs/toolkit';
import activeSubPage from './App/activeSubPage';
import activeBottomPage from './App/activeBottomPage';
import collectState from './App/collectState';
const store = configureStore({
  reducer: {
    // 這裡可以添加你的各個 reducers
    activeSubPage: activeSubPage,
    activeBottomPage: activeBottomPage,
    collectState: collectState,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
  devTools: process.env.NODE_ENV !== 'production',
});

export default store;
