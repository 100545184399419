// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.drawer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    transition: transform 0.3s ease-in-out;
    transform: translateY(100%);
    z-index: 6000;
  }
  
  .drawer.open {
    transform: translateY(0%);

  }
  
  .drawer-content {
    position: fixed;
    top: auto;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 70%;
    background-color: white;
    border-top-left-radius: 15px ;
    border-top-right-radius: 15px ;
    overflow: scroll;


  }
  
  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  
  `, "",{"version":3,"sources":["webpack://./src/MainPage/components/Drawer/Drawer.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,oCAAoC;IACpC,sCAAsC;IACtC,2BAA2B;IAC3B,aAAa;EACf;;EAEA;IACE,yBAAyB;;EAE3B;;EAEA;IACE,eAAe;IACf,SAAS;IACT,SAAS;IACT,OAAO;IACP,WAAW;IACX,WAAW;IACX,uBAAuB;IACvB,6BAA6B;IAC7B,8BAA8B;IAC9B,gBAAgB;;;EAGlB;;EAEA;IACE,kBAAkB;IAClB,SAAS;IACT,WAAW;EACb","sourcesContent":[".drawer {\r\n    position: fixed;\r\n    top: 0;\r\n    left: 0;\r\n    width: 100%;\r\n    height: 100%;\r\n    background-color: rgba(0, 0, 0, 0.5);\r\n    transition: transform 0.3s ease-in-out;\r\n    transform: translateY(100%);\r\n    z-index: 6000;\r\n  }\r\n  \r\n  .drawer.open {\r\n    transform: translateY(0%);\r\n\r\n  }\r\n  \r\n  .drawer-content {\r\n    position: fixed;\r\n    top: auto;\r\n    bottom: 0;\r\n    left: 0;\r\n    width: 100%;\r\n    height: 70%;\r\n    background-color: white;\r\n    border-top-left-radius: 15px ;\r\n    border-top-right-radius: 15px ;\r\n    overflow: scroll;\r\n\r\n\r\n  }\r\n  \r\n  .close-btn {\r\n    position: absolute;\r\n    top: 10px;\r\n    right: 10px;\r\n  }\r\n  \r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
