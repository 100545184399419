// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.returnButton{
    background-color: transparent;
    border: none;
    height: 40px;
    color: #000;
}

.returnButton>img{
    height: 30px;
}

.AnalyticsContent{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.AnalyticsTitleArea{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.AnalyticsTwoCircularProgress{
    text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/MainPage/SubPage/AnalyticsPage/Analytics.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;IAC7B,YAAY;IACZ,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,WAAW;AACf;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,6BAA6B;IAC7B,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":[".returnButton{\r\n    background-color: transparent;\r\n    border: none;\r\n    height: 40px;\r\n    color: #000;\r\n}\r\n\r\n.returnButton>img{\r\n    height: 30px;\r\n}\r\n\r\n.AnalyticsContent{\r\n    display: flex;\r\n    flex-direction: column;\r\n    width: 100%;\r\n}\r\n\r\n.AnalyticsTitleArea{\r\n    display: flex;\r\n    flex-direction: row;\r\n    justify-content: space-around;\r\n    align-items: center;\r\n}\r\n\r\n.AnalyticsTwoCircularProgress{\r\n    text-align: center;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
