// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OneWeek{
    height: 15vh; 
    overflow-y: hidden; 
}

.SurveySelected{
    color: white !important;
    background-color: #002DE3;
    border-radius: 30px;
}

.SurveyDayHeaderContent{
    color: #8F9BB3;
    padding: 5px;
}

.generateingPDF{
    background-Color:#002DE3;
    border-radius:  30px;
    color: #FFFFFF;
    width: 70%;
    font-size: 20px;
    height: 60px;  
    display: flex;
    align-items: center;  
    justify-content: center;
}

.dialogContent{
    display: flex;
    flex-direction: column;
}`, "",{"version":3,"sources":["webpack://./src/MainPage/SubPage/SurveyPage/SurveyPage.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,uBAAuB;IACvB,yBAAyB;IACzB,mBAAmB;AACvB;;AAEA;IACI,cAAc;IACd,YAAY;AAChB;;AAEA;IACI,wBAAwB;IACxB,oBAAoB;IACpB,cAAc;IACd,UAAU;IACV,eAAe;IACf,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,aAAa;IACb,sBAAsB;AAC1B","sourcesContent":[".OneWeek{\r\n    height: 15vh; \r\n    overflow-y: hidden; \r\n}\r\n\r\n.SurveySelected{\r\n    color: white !important;\r\n    background-color: #002DE3;\r\n    border-radius: 30px;\r\n}\r\n\r\n.SurveyDayHeaderContent{\r\n    color: #8F9BB3;\r\n    padding: 5px;\r\n}\r\n\r\n.generateingPDF{\r\n    background-Color:#002DE3;\r\n    border-radius:  30px;\r\n    color: #FFFFFF;\r\n    width: 70%;\r\n    font-size: 20px;\r\n    height: 60px;  \r\n    display: flex;\r\n    align-items: center;  \r\n    justify-content: center;\r\n}\r\n\r\n.dialogContent{\r\n    display: flex;\r\n    flex-direction: column;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
